import React, { useCallback, useEffect, useState } from 'react';
import { Icon, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { selectAttemptNavigationItems, setAttemptNavigationItems, setNavigationCurrentItem } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { FULL, INCREMENTAL } from 'constants/presentationTypes';
import { ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { waitForElementToExist } from 'utils';

import Button from 'components/common/Button';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const AttemptNavigation = ({ flushPendingAnswers, clearPendingAnswers, open, setOpen }) => {
  const classes = useStyles();
  const toast = useToast();
  const history = useHistory();

  const loggedIn = useSelector(selectUserLoggedIn);
  const navigationItems = useSelector(selectAttemptNavigationItems);
  const publicationPresentation = useSelector((state) => state.studentAttempt.testType);
  const publicationNavigation = useSelector((state) => state.studentAttempt.navigation);
  const currentBaseItem = useSelector((state) => state.studentAttempt.currentItem);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [moveToItemRequest] = useApi(api.moveToItem, !loggedIn);
  const [getAttemptNavigationItemsRequest] = useApi(api.getAttemptNavigationItems, !loggedIn);
  const { attemptId } = useParams();
  const dispatch = useDispatch();
  const iaveToggle = useFeature(toggles.iave);
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const [flushLoading, setFlushLoading] = useState(false);
  const [moveTo, setMoveTo] = useState(null);
  const [canMove, setCanMove] = useState(true);

  const fetchAttemptNavigationItems = useCallback(() => {
    getAttemptNavigationItemsRequest([attemptId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setAttemptNavigationItems(data.items));
        setLoading(false);
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
        setLoading(false);
      }
    });
  }, [getAttemptNavigationItemsRequest, attemptId, dispatch, toast]);

  useEffect(() => {
    setLoading(true);
    if (attemptId) {
      fetchAttemptNavigationItems();
    } else {
      dispatch(setAttemptNavigationItems([]));
    }
  }, [fetchAttemptNavigationItems, attemptId, dispatch]);

  const moveToItem = async (itemOrder, answerSectionId) => {
    let answerIds = null;

    if (publicationPresentation === FULL && answerSectionId) {
      const section = navigationItems?.find(item => item.answerSectionId === answerSectionId);
      answerIds = section?.exercises.map((exercise) => exercise.id);
    }

    clearPendingAnswers(answerIds);

    moveToItemRequest([attemptId], { itemOrder, answerSectionId }, ({ data }) => {
      setCanMove(true);
      setSaveLoading(false);

      if (data.status === 0) {
        dispatch(setNavigationCurrentItem(itemOrder, answerSectionId));
        return;
      }

      if (data.status === 2 || data.status === 3) {
        toast.warning(lang.test.finishTestEnded);
        history.push('/');
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
        setLoading(false);
      }
    });
  };

  const saveAndContinue = async (itemOrder, answerSectionId) => {
    setSaveLoading(true);

    setMoveTo({ itemOrder, answerSectionId });

    let valid = true;
    let answerIds = null;

    if (publicationPresentation === FULL && answerSectionId) {
      const section = navigationItems?.find(item => item.answerSectionId === answerSectionId);
      answerIds = section?.exercises.map((exercise) => exercise.id);
    }

    if (asyncAnswersToggle) {
      setFlushLoading(true);
      const flushResults = await flushPendingAnswers(true, answerIds);
      valid = flushResults.every((successful) => successful);
      setFlushLoading(false);
    }

    if (!valid) {
      setCanMove(false);
      setSaveLoading(false);
      return;
    }

    moveToItem(itemOrder, answerSectionId);
  };

  const onClickButton = async (id, sectionId) => {
    setOpen(false);
    if (sectionId) {
      const section = navigationItems.find(item => item.id === sectionId);
      const itemOrder = section.exercises.find(el => el.id === id).order;
      if (section.presentation === INCREMENTAL) {
        if (section.exercises[section.currentItem].id === id) {
          if (publicationPresentation === FULL) {
            const exercise = document.getElementById(id);
            exercise.scrollIntoView({ behavior: 'smooth', block: 'center' });
          } else {
            saveAndContinue(itemOrder, section.answerSectionId);
          }
        } else {
          saveAndContinue(itemOrder, section.answerSectionId);
          if (publicationPresentation === FULL) {
            const exercise = await waitForElementToExist(`[id="${id}"]`);
            exercise.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }
      } else {
        let exercise;
        if (publicationPresentation === FULL && (section.presentation === FULL || section.currentItem === currentBaseItem)) {
          exercise = document.getElementById(id);
        } else if (section.presentation === FULL && section.order === currentBaseItem) {
          exercise = document.getElementById(id);
        } else {
          saveAndContinue(section.order);
          exercise = await waitForElementToExist(`[id="${id}"]`);
        }
        exercise.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } else {
      if (publicationPresentation === FULL) {
        const exercise = document.getElementById(id);
        exercise.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        saveAndContinue(navigationItems.find(el => el.id === id).order);
      }
    }
  };

  const getButton = (order, exercise, section) => {
    let buttonContent;
    if (exercise.type === 'information') {
      buttonContent = <Icon icon="info" />;
    } else if (exercise.type === 'pause') {
      buttonContent = <Icon icon="pause" />;
    } else {
      buttonContent = order;
    }

    let disabledButton = false;
    if (publicationPresentation === FULL || publicationNavigation === 'nonLinear') {
      disabledButton = section && !(section.presentation === FULL || section.navigation === 'nonLinear') && exercise.order !== section.currentItem;
    } else {
      if (section) {
        if (section.order === currentBaseItem) {
          disabledButton = exercise.order !== section.currentItem && !(section.presentation === FULL || section.navigation === 'nonLinear');
        } else {
          disabledButton = true;
        }
      } else {
        disabledButton = exercise.order !== currentBaseItem;
      }
    }

    let currentItem;

    if (publicationPresentation !== FULL) {
      if (section) {
        currentItem = section.presentation !== FULL && exercise.order === section.currentItem && section.order === currentBaseItem;
      } else {
        currentItem = exercise.order === currentBaseItem;
      }
    }

    return (
      <Button
        className={cx(classes.button, {
          answered: exercise.answered,
          section: exercise.answerSectionId,
          info: exercise.type === 'information' || exercise.type === 'pause',
          currentItem: currentItem,
          currentSectionItem: publicationPresentation === FULL && section?.presentation === INCREMENTAL && exercise.order === section.currentItem,
        })}
        key={exercise.id}
        {...(exercise.type !== 'information') && { sibling: true }}
        onClick={() => onClickButton(exercise.id, exercise.publicationSectionId)}
        disabled={disabledButton}
        loading={flushLoading}
      >
        {buttonContent}
      </Button>
    );
  };

  const getItems = () => {
    if (!navigationItems) {
      return;
    }

    let itemCount = 0;
    let sectionCount = 0;
    const itemsToReturn = navigationItems.map((item) => {
      if (item.itemType === 'section') {
        sectionCount++;
        let sectionItemCount = 0;
        return (
          <div
            key={item.id + '_wrapper'}
            className={cx(classes.section, { currentItem: item.order === currentBaseItem })}
          >
            <div className={classes.sectionTitle}>
              {iaveToggle ? `${lang.navigation.section} ${sectionCount}` : item.name}
            </div>
            <div
              key={item.id + '_exercise_list'}
              className={classes.itemList}
            >
              {item.exercises.map(exercise => {
                exercise.type !== 'information' && exercise.type !== 'pause' && sectionItemCount++;
                return getButton(sectionItemCount, exercise, item);
              })}
            </div>
          </div>
        );
      }

      item.type !== 'information' && item.type !== 'pause' && itemCount++;
      return getButton(itemCount, item);
    });
    return (
      <div className={classes.itemList}>
        {itemsToReturn}
      </div>
    );
  };

  const continueWithoutSaving = () => {
    moveToItem(moveTo.itemOrder, moveTo.answerSectionId);
    fetchAttemptNavigationItems();
  };

  if (loading) {
    return (
      <Loading active={loading} />
    );
  }

  return (
    <div className={classes.navigationSidebar}>
      <div className={classes.navigationHeader}>
        {lang.navigation.title}
      </div>
      {!canMove ?
        <div className={classes.infoWrapper}>
          {lang.attempt.unsavedAnswers}
          <div className={classes.confirmButtons}>
            <Button
              onClick={continueWithoutSaving}
              disabled={loading}
              loading={loading}
              sibling
              red
            >
              {lang.attempt.continueWithoutSaving}
            </Button>
            <Button
              onClick={() => saveAndContinue(moveTo.itemOrder, moveTo.answerSectionId)}
              disabled={saveLoading}
              loading={saveLoading}
              sibling
            >
              {lang.attempt.saveAndContinue}
            </Button>
          </div>
        </div>
        :
        getItems()
      }
    </div>
  );
};

AttemptNavigation.propTypes = {
  flushPendingAnswers: PropTypes.func.isRequired,
  clearPendingAnswers: PropTypes.func.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default AttemptNavigation;
