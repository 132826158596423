import React, { useState } from 'react';
import { Wrapper } from '@intuitivo/outline';
import { Render } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectHasPersonalSpace, selectUserFeatureToggles, selectUserIsStudent, selectUserIsTeacher, selectUserSchools } from 'actions/userActions';
import { ALLOWED_HOSTS } from 'constants/allowedHosts';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import routes from 'routes';
import toggles from 'toggles';

import { ReactComponent as LogoIAVE } from '../../../../pages/Publication/logo_iave.module.svg';
import { ReactComponent as LogoPRR } from '../../../../pages/Publication/logo_prr.module.svg';
import Error from '../Error';
import Navbar from '../Navbar';
import Sidebar from '../sidebar/Sidebar';
import OnboardingButton from 'components/common/layout/OnboardingButton';
import Loading from 'components/common/Loading';
import Logo from 'components/common/Logo';

import useStyles from './styles';

const Layout = ({ children, navbar, sidebar }) => {
  const classes = useStyles();
  const appLoading = useSelector(state => state.page.appLoading);
  const pageLoading = useSelector(state => state.page.pageLoading);
  const error = useSelector(state => state.page.error);
  const layout = useSelector(state => state.page.layout);
  const authenticated = useSelector(state => state.page.authenticated);
  const isTeacher = useSelector(selectUserIsTeacher);
  const isStudent = useSelector(selectUserIsStudent);
  const iaveToggle = useFeature(toggles.iave);
  const maintenanceToggle = useFeature(toggles.maintenance);
  const teacherOnboarding = useFeature(toggles.teacherOnboarding);
  const hasPersonalSpace = useSelector(selectHasPersonalSpace);
  const userSchools = useSelector(selectUserSchools);
  const userFeatureToggles = useSelector(selectUserFeatureToggles);

  const [openSidebar, setOpenSidebar] = useState(false);

  if (iaveToggle && !window.electron && !window.reactNative && isStudent && !ALLOWED_HOSTS.includes(window.location.hostname)) {
    return (
      <div className={classes.appNotAvailableContainer}>
        <Logo
          medium
          className={classes.logo}
        />
        <div className={classes.iaveLogosContainer}>
          <LogoIAVE
            className={classes.iaveLogo}
          />
          <LogoPRR
            className={classes.prrLogo}
          />
        </div>
        <div className={classes.appNotAvailable}>
          {lang.appNotAvailable}
        </div>
      </div>
    );
  }

  const getPageContent = () => {
    if (error) {
      return (
        <Error code={error} />
      );
    }

    return children;
  };

  const getNavBar = () => {
    if (!appLoading && navbar && layout) {
      return (
        <Navbar open={openSidebar} setOpen={setOpenSidebar} showHamburger={authenticated} />
      );
    }
  };

  const getSideBar = () => {
    if (!appLoading && sidebar && layout && authenticated) {
      return (
        <Sidebar open={openSidebar} setOpen={setOpenSidebar} />
      );
    }
  };

  if (appLoading || !userFeatureToggles) {
    return (
      <Loading active={appLoading} fullPage />
    );
  }

  if (maintenanceToggle) {
    window.location = routes.auth.ref();
    return false;
  }

  if (!hasPersonalSpace && userSchools?.length === 0) {
    window.location = routes.limbo.ref();
    return false;
  }

  return (
    <div>
      {getNavBar()}
      {getSideBar()}
      <div className={cx(classes.main, { layoutWidth: !appLoading && layout && authenticated })}>
        <Loading active={pageLoading} fullPage />
        <Wrapper
          fluid
          className={cx(classes.layoutWrapper, {
            [classes.navbarSpacer]: !appLoading && navbar && layout,
            [classes.sidebarSpacer]: !appLoading && sidebar && layout && authenticated,
            [classes.loading]: pageLoading,
          })}
        >
          {getPageContent()}
        </Wrapper>
      </div>
      <Render when={teacherOnboarding && isTeacher}>
        <OnboardingButton />
      </Render>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  navbar: PropTypes.bool,
  sidebar: PropTypes.bool,
};

export default Layout;
