import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  navigationSidebar: {
    padding: '20.5px 28px',
  },
  navigationHeader: {
    fontSize: '18px',
    marginBottom: '24px',
    fontWeight: '600',
  },
  itemList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
    width: '100%',
  },
  section: {
    backgroundColor: `${theme.buttonBackground2}1A`,
    padding: '24px 28px',
    margin: '16px -28px',
    width: '100%',
    '&.currentItem': {
      backgroundColor: `${theme.buttonBackground2}40`,
    },
  },
  sectionTitle: {
    color: '#2d2d2dE6',
    fontSize: '16px',
    marginBottom: '16px',
  },
  button: {
    gridColumn: 'span 1',
    border: `1px solid ${theme.buttonBackground2}80 !important`,
    fontSize: '0.875rem',
    fontWeight: '600',
    width: '40px',
    height: '40px',
    '&.currentItem': {
      border: `2px solid ${theme.buttonBackground2} !important`,
    },
    backgroundColor: '#f5f5f5 !important',
    color: `${theme.buttonBackground2} !important`,
    '&:hover': {
      backgroundColor: `${theme.buttonBackground2}33 !important`,
    },
    '&.info': {
      backgroundColor: `${theme.buttonBackground2}33 !important`,
      '&:hover': {
        backgroundColor: `${theme.buttonBackground2} !important`,
        color: '#FFFFFF !important',
      },
    },
    '&.answered': {
      backgroundColor: `${theme.buttonBackground2} !important`,
      color: '#FFFFFF !important',
      '&.currentItem': {
        outline: '2px solid #FFF',
        outlineOffset: '-4px',
      },
      '&:hover': {
        backgroundColor: `${theme.buttonBackgroundHover2} !important`,
      },
    },
    '&.currentSectionItem': {
      borderWidth: '3px !important',
    },
  },
  confirmButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5em',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  [`@media (max-width: ${theme.sm}px)`]: {
    exerciseWrapper: {
      display: 'none',
      position: 'absolute',
      width: '250px !important',
      right: '-50%',
      top: '15%',
      zIndex: '103',
      '&.open': {
        display: 'block',
        right: '10px',
        position: 'fixed',
      },
    },
  },
}));
