import { createUseStyles } from 'react-jss';

export default createUseStyles({
  commentBox: {
    position: 'absolute',
    width: '34%',
    top: '0',
    right: 'calc(-34% - 40px)',
    backgroundColor: '#A8E0FF33',
    border: '3px solid #A8E0FF',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  commentBoxSection: {
    position: 'absolute',
    width: '34%',
    top: '0',
    right: 'calc(-34% - 75px)',
    backgroundColor: '#A8E0FF33',
    border: '3px solid #A8E0FF',
    borderRadius: '5px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  commentArea: {
    width: '100%',
    height: '100%',
    border: 'none',
    boxSizing: 'border-box',
    resize: 'none',
    '& .quill': {
      backgroundColor: 'unset !important',
    },
  },
});
