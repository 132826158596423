import React, { useEffect, useState } from 'react';
import { useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useApi from 'hooks/common/useApi';
import lang from 'lang';
import { getAnswer } from 'utils/attempts';

import ReviewableSection from '../ReviewableSection';
import StudentAttemptHeader from '../StudentAttemptHeader';
import ReviewableExercise from 'components/attempt/ReviewableExercise';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const AttemptReview = ({ attempt }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const history = useHistory();
  const classes = useStyles();
  const loggedIn = useSelector(selectUserLoggedIn);
  const [getReviewAnswersRequest] = useApi(api.getReviewAnswers, !loggedIn);

  const [items, setItems] = useState([]);
  const [criteriaTotals, setCriteriaTotals] = useState(null);

  useEffect(() => {
    if (attempt.id) {
      getReviewAnswersRequest([attempt.id], null, ({ data }) => {
        if (data.status === 0) {
          setItems(data.items);
          setCriteriaTotals(data.criteriaTotals);
          return;
        }

        toast.error(lang.oops);
        history.push('/');
      });
    }
  }, [getReviewAnswersRequest, attempt, dispatch, toast, history]);

  let exerciseCounter = 0;
  const reviewItems = items.map((item) => {
    if (item.itemType === 'section') {
      return (
        <ReviewableSection
          key={item.id}
          section={item}
          classificationType={attempt.classificationType}
        />
      );
    }

    if (item.itemType !== 'section' && ![INFORMATION, PAUSE].includes(item.type)) {
      exerciseCounter++;
    }

    item.answer = getAnswer(item);

    if (item.hasJustification) {
      item.justification = item.valueText ?? item.quillAnswer;
    }

    return (
      <div
        key={item.id}
        className={classes.attemptExercise}
      >
        <ReviewableExercise
          num={exerciseCounter}
          exercise={item}
          classificationType={attempt.classificationType}
        />
      </div>
    );
  });

  const attemptHeader = {
    ...attempt,
    criteriaTotals: criteriaTotals,
    student: {
      name: attempt.userName,
    },
  };

  const getGrade = () => {
    if (!items) {
      return lang.test.grades.noAttempt;
    }
    let grade = 0;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.itemType === 'section' && item.exercises) {
        for (let j = 0; j < item.exercises.length; j++) {
          grade += parseFloat(item.exercises[j].grade);
        }
      } else if (item.itemType === 'exercise') {
        grade += parseFloat(item.grade);
      }
    }

    const totalGrade = grade || grade === 0 ? grade.toFixed(2) : '??';
    const gradePercentage = grade || grade === 0 ? ((grade / attempt.maxGrade) * 100).toFixed(2) : '??';

    return `${gradePercentage || '??'}% (${totalGrade || '??'} / ${attempt.maxGrade})`;
  };

  return (
    <div>
      <Loading active={items.length === 0} />
      <StudentAttemptHeader
        attempt={attemptHeader}
        classificationType={attempt.classificationType}
        grade={getGrade()}
        isStudent
      />
      {reviewItems}
    </div>
  );
};

AttemptReview.propTypes = {
  attempt: PropTypes.object,
};

export default AttemptReview;
