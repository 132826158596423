import LocalizedStrings from 'react-localization';

const lang = new LocalizedStrings({
  en: {
    ok: 'Ok',
    add: 'Add',
    create: 'Create',
    publish: 'Publish',
    published: 'Published',
    submit: 'Submit',
    hide: 'Hide',
    preview: 'Preview',
    edit: 'Edit',
    cancel: 'Cancel',
    erase: 'Erase',
    'delete': 'Delete',
    remove: 'Remove',
    duplicate: 'Duplicate',
    discard: 'Discard',
    clear: 'Clear',
    saveChanges: 'Save changes',
    save: 'Save',
    copy: 'Copy',
    oops: 'Something went wrong. Please try again',
    waitSeconds: 'This process might take a few seconds',
    waitMinutes: 'This process might take a few minutes',
    waitTime: 'This process might take some time',
    date: 'Date',
    previous: 'Previous',
    next: 'Next',
    'export': 'Export',
    en: 'English',
    pt: 'Portuguese',
    esES: 'Spanish',
    ptBR: 'Portuguese (Brazil)',
    unauthorizedMessage: 'Looks like you\'re not yet qualified to be in this area... Maybe next year!',
    notFoundMessage: 'Oh no! It seems that you lost your way around our website... Maybe it\'s not that Intuitive.',
    errorMessage: 'Something wrong happened... We\'ll be looking into fixing it as soon as possible.',
    noPermissionSpace: 'You don\'t have the permissions to access this space.',
    home: 'Home',
    account: 'Account',
    signout: 'Sign out',
    finished: 'Finished',
    example: 'Example',
    'private': 'Private',
    confirm: 'Confirm',
    total: 'Total',
    optional: 'optional',
    workInProgress: 'We are working to improve this feature',
    enable: 'Enable',
    disable: 'Disable',
    noOptionsAvailable: 'No options available',
    close: 'Close',
    'continue': 'Continue',
    'in': 'in',
    goBack: 'Go back',
    name: 'Name',
    email: 'E-mail',
    shortcuts: 'Shortcuts',
    leave: 'Leave',
    send: 'Send',
    comingSoon: 'Coming soon!',
    comment: 'Comment',
    help: 'Help',
    feedback: 'Feedback',
    copied: 'Copied',
    noEmail: 'No email',
    selectAll: 'Select all',
    general: 'General',
    discardWarning: 'If you continue, all the additions will be lost.',
    notFound: 'Not found',
    copyOf: 'Copy of',
    otherOptions: 'Other options',
    reschedule: 'Reschedule',
    results: 'Results',
    'new': 'New',
    filterBy: 'Filter by',
    copies: 'Copies',
    orderBy: 'Order by',
    oldest: 'Oldest',
    newest: 'Newest',
    relevancy: 'Relevancy',
    showMore: 'Show more',
    showLess: 'Show less',
    settings: 'Settings',
    scrollToSides: 'Scroll to the sides',
    download: 'Download',
    fileTooLarge: 'The file can\'t be larger than 10Mb',
    fileTooLarge1Gb: 'The file can\'t be larger than 1Gb',
    drag: 'Drag',
    dropHere: 'Drop here',
    changeLang: 'Change language',
    logout: 'Log out',
    video: 'Video',
    audio: 'Audio',
    tutorial: 'Tutorials',
    continueWith: 'Continue with',
    or: 'Or',
    notYou: 'Not your account?',
    guest: 'Guest',
    select: 'Select',
    internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
    openFormatting: 'Open formatting',
    openMedia: 'Open media',
    appNotAvailable: 'The website is not available through browser anymore. Please use the application to access the exams.',
    generateDocument: 'Generate document',
    refresh: 'Refresh',
    seeMore: 'See more',
    deleteDisabled: 'You cannot delete other users\' content',
    andXMore: (x) => `And ${x} more...`,
    words: 'Words',
    contentSettings: {
      'public': 'Public',
      'private': 'Private',
    },
    common: {
      edit: 'Edit',
      cancel: 'Cancel',
      discard: 'Discard',
      finish: 'Finish',
      goBack: 'Go back',
      somethingWentWrong: 'Something went wrong. Please try again',
      internetWentWrong: 'Something went wrong. Please check your internet connection and try again',
      createdBy: 'Created by',
      publish: 'Publish',
      published: 'Published',
      name: 'Name',
      fontSize: {
        small: 'Small',
        normal: 'Normal',
        large: 'Large',
        huge: 'Huge',
      },
    },
    time: {
      day: 'day',
      days: 'days',
      hour: 'hour',
      hours: 'hours',
      minute: 'minute',
      minutes: 'minutes',
      second: 'second',
      seconds: 'seconds',
    },
    appKeywords: {
      classes: 'Classes',
      tests: 'Tests',
      test: 'Test',
      threads: 'Threads',
      admin: 'Administrator',
      lesson: 'Lesson',
      subject: 'Subject',
      'class': 'Class',
      exercises: 'Exercises',
      exercise: 'Exercise',
      correction: 'Correction',
      answer: 'Answer',
      grade: 'Grade',
      group: 'Group',
      groups: 'Groups',
      personalGroup: 'My content',
      allGroups: 'All groups',
      personalSpace: 'Personal space',
      students: 'Students',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (free trial)',
      premiumInfo: 'Experimental period for an indefinite duration. At the moment, you can use the platform and all its functionalities for free. Later, we will introduce different paid plans for teachers, but there will always be a free plan, despite some limitations in terms of functionalities.',
      rubrics: 'Rubrics',
      rubric: 'Rubric',
      criterion: 'Criteria',
      criteria: 'Criteria',
      classic: 'Classic',
      noClassification: 'No classification',
      worksheet: 'Worksheet',
      assessment: 'Assessment',
      assessments: 'Assessments',
      spaces: 'Spaces',
      space: 'Space',
      statistics: 'Statistics',
      teachers: 'Teachers',
      events: 'Events',
      exams: 'Exams',
      examGroupsMin: 'Exam groups',
    },
    tests: {
      title: 'Testes',
      teacher: {
        subtitle: 'All the tests created for the classes you lecture',
        exercise: 'item',
        exercises: 'items',
        resolution: 'resolution',
        resolutions: 'resolutions',
        publish: 'Publish',
        grades: 'Grades',
        stateUnpublished: 'Not published',
        stateUpcoming: 'Scheduled',
        stateOngoing: 'Ongoing',
        stateComplete: 'Completed',
        noTests: 'No tests have been created yet',
      },
      student: {
        subtitle: 'All the tests made by your teachers',
        noTests: 'There are currently no tests made available for you',
        opensIn: 'Opens in',
        openingClosesAt: 'Opening closes at',
        testEndsAt: 'Test ends at',
        testNotOpen: 'The test hasn\'t opened yet',
        testWindowClosed: 'The test\'s opening window closed. It is no longer possible to open the test',
        testComplete: 'You already completed the test. All your answers were saved',
        testCompleteTime: 'The test has already ended',
        testPasswordModal: 'Test\'s password',
        testWrongPassword: 'The password is wrong',
      },
    },
    test: {
      title: 'Test',
      duplicateTest: 'Duplicate test',
      question: 'Item',
      informationBlock: 'Information block',
      information: 'Information',
      'true': 'True',
      'false': 'False',
      textPlaceholder: 'Write here your answer',
      justificationLabel: 'Justification',
      justificationPlaceholder: 'Write here your justification',
      dragImageHint: 'Drag and drop images here, or click to select images',
      dropImageHint: 'Drop to add',
      addQuestion: 'Add content',
      selectExercises: 'Select exercises',
      addExercises: 'Add exercises',
      selectedExercises: 'selected exercises',
      successAddExercises: 'The exercises were added with success',
      successAddExercise: 'The exercise was added with success',
      successAddInformationBlock: 'The information block was added with success',
      successDuplicateTest: 'The assessment was duplicated with success',
      errorAddExists: 'It is not possible to add exercises that are already added to the test',
      selectExercisesFromOtherSpace: 'You can only use exercises created by you if you select other spaces!',
      selectRubricsFromOtherSpace: 'You can only use rubrics created by you if you select other spaces!',
      createNewExercise: 'Create a new exercise',
      addInformationBlock: 'Add information block',
      selectExerciseFromDb: 'Select exercise from database',
      thisTestIsInvalid: 'This test is invalid',
      youAlreadyCompleted: 'You already completed this test before',
      deleteTest: 'Delete assessment?',
      deleteTestInfo: 'Are you sure you want to delete this assessment?',
      deleteTestInfo2: 'This action is not reversible.',
      successDeleteTest: 'The assessment was successfully deleted',
      deleteErrorDoesNotExist: 'Delete could not be done because the assessment does not exist',
      timeRemaining: 'Time remaining',
      finishTest: 'Finish',
      finishHintConfirm: 'Are you sure you want to finish now?',
      finishHintIncomplete: 'There are still items to be answered. Are you sure you want to finish now?',
      finishTestSuccess: 'You have finished. Good job!',
      finishTestEnded: 'You already finished before',
      finishErrorNetwork: 'An error ocurred finishing. Please check your internet connection and try again',
      answerError: (num) => `An error ocurred saving the answer to item ${num}. Please try again.`,
      answerErrorNetwork: (num) => `An error ocurred saving the answer to item ${num}. Please check your internet connection and try again.`,
      answered: 'Answered',
      attemptWindow: 'Opening window',
      duration: 'Duration',
      stateScheduled: 'Scheduled',
      stateOngoing: 'Ongoing',
      stateFinished: 'Finished',
      moveUp: 'Move up',
      moveDown: 'Move down',
      errorMovePublished: 'The order could not be changed because the test is published. Unpublish if you wish to make changes',
      hintMoveNotPossible: 'That move is not possible',
      removeFromTest: 'Remove from test',
      removeExerciseFromTest: 'Remove exercise from the assessment',
      removeInformationBlockFromTest: 'Remove information block from the assessment',
      removeSectionFromTest: 'Remove section from the assessment',
      hintConfirmRemove: 'Are you sure you want to remove this exercise from the assessment?',
      hintConfirmRemoveInformationBlock: 'Are you sure you want to remove this information block from the assessment?',
      hintConfirmRemoveSection: 'Are you sure you want to remove this section from the assessment?',
      errorRemovePublished: 'The exercise could not be removed because the test is published. Unpublish if you wish to make changes',
      link: 'Link',
      copied: 'Copied',
      password: 'Password',
      compacted: 'Compact',
      presentationFull: 'Full',
      presentationIncremental: 'Incremental',
      navigationLinear: 'Linear',
      navigationNonLinear: 'Non-Linear',
      presentationFullTip: 'All items visible at the same time',
      presentationIncrementalTip: 'Only one item visible at a time',
      navigationLinearTip: 'Not being able to go back to update an answer',
      navigationNonLinearTip: 'Being able to go back to update an answer',
      header: {
        exportPhysical: 'Physical version',
      },
      testForm: {
        createTest: 'Create assessment',
        editTest: 'Edit test',
        testName: 'Assessment\'s name',
        errorNameRequired: 'It is required to set a name',
        errorSubjectRequired: 'It is required to choose a subject',
        errorGroupRequired: 'It is required to choose a group',
        errorNoExercises: 'It is required to add at least one exercise',
        successCreateTest: 'The test was created with success',
        successEditTest: 'The test was updated with success',
        classificationTypeLabel: 'Classification type',
        whoCanSee: 'Who can see this test?',
        privacyTypeLabel: 'Privacy type',
        classicClassificationTypeTip: 'The exercises are graded and a final grade is given',
        rubricClassificationTypeTip: 'The level of the students is evaluated through the defined criteria',
        noClassificationTypeTip: 'The exercises are not graded e there is no final grade',
        testTypeLabel: 'Assessment type',
        testTypeTip: 'Assessment with a time limit scheduled for a specific date',
        worksheetTypeTip: 'Assessment that stays available until the delivery date',
        'public': 'Public',
        publicTip: 'Visible on the "Explore" page',
        'private': 'Private',
        privateTip: 'Visible only to you or to the groups that you share the exercise with',
      },
      publishModal: {
        testFormat: 'Assessment format',
        navigationType: 'Navigation type',
        errorTypeRequired: 'It is required to choose a type of the test',
        testShuffleLabel: 'Shuffle the exercises\' order',
        shuffleNoneLabel: 'Don\'t shuffle anything',
        shuffleBaseLabel: 'Shuffle sections and exercises outside the sections, and keep order of exercises inside the sections',
        shuffleSectionsLabel: 'Keep order of sections and exercises outside the sections, and shuffle exercises inside the sections',
        shuffleAllLabel: 'Shuffle all the sections, exercises outside the sections, and exercises inside the sections',
        publishTest: 'Publish assessment',
        unpublishTest: 'Unpublish assessment',
        rescheduleTest: 'Reschedule assessment',
        editTest: 'Edit assessment',
        editPublication: 'Edit publication',
        successUnpublishTest: 'The test was unpublished with success',
        beforePublishing: 'Before publishing...',
        labelStartsAt: 'Starting date and time',
        labelEndsAt: 'Ending date and time',
        labelAttemptWindow: 'Opening window (in minutes)',
        labelDuration: 'Solving duration (in minutes)',
        labelPassword: 'Password',
        labelExitPin: 'Exit pin',
        hintExitPin: 'This field defines a test exit pin for students using the exam-taking app',
        labelTargets: 'Classes and students',
        hintAttemptWindow: 'This field defines how many minutes the students have to start the test',
        hintTargets: 'This field defines which classes and students can open the test',
        errorStartsAtRequired: 'It is required to set a starting date',
        errorStartsAtPast: 'The starting date must be in the future',
        errorEndsAtRequired: 'It is required to set a ending date',
        errorEndsAtBeforeStartsAt: 'The ending date must be after the starting date',
        errorAttemptWindowRequired: 'It is required to set an opening window',
        errorAttemptWindowBoundaries: 'This value must be between 1 and 4320 (3 days)',
        errorDurationRequired: 'It is required to set a duration',
        errorDurationBoundaries: 'This value must be between 1 and 1440 (1 day)',
        errorEditDuration: (duration) => `The duration must be greater than ${duration} minutes`,
        successPublishTest: 'The assessment was published with success',
        successRescheduleTest: 'The test was rescheduled with success',
        successEditPublication: 'The publication was updated with success',
        publishErrorDoesNotExist: 'The test could not be published because it does not exist',
        publishErrorNoLesson: 'The test could not be published because it must belong to a class',
        publishErrorNoExercises: 'The test could not be published because it does not have any exercises',
        publishErrorAlreadyPublished: 'The test could not be published because it is already published',
        republishErrorNotPublished: 'The test could not be rescheduled because it is not published',
        publishErrorAlreadyStarted: 'It was not possible to update the test\'s publish information because the test has started or starts in less than 1 minute',
        unpublishErrorDoesNotExist: 'The test could not be unpublished because it does not exist',
        unpublishErrorAlreadyUnpublished: 'the test could not be unpublished because it is already unpublished',
        publishPreHint: 'Before being possible to publish, this assessment should:',
        hintHaveExercises: 'Have at least 1 exercise',
        errorTargetsRequired: 'It is required to choose at least one class or one student',
        hintInformationBlocksShuffle: 'All the information blocks will remain in the same place, only the exercises will be shuffled. If you are using an information block to give context to following exercises please consider unchecking this option.',
        hintHasRubric: 'Have a rubric selected',
        hintAreWeightsCorrect: 'Have the total of the weights per exercise match 100%',
        hintSectionsHaveExercises: 'Have at least 1 exercise in each section',
        showRubricLabel: 'Show the rubric to students during the test',
        opensIn: 'Opens in:',
        endsIn: 'Ends in:',
        scheduledFor: 'Scheduled for',
        openedOn: 'Opened on',
        endedOn: 'Ended on',
        endsOn: 'Ends on',
        immediateFeedbackLabel: 'Immediate feedback: student can see the result and solutions immediately after the test ends',
        modelAnswerLabel: 'Model answer: student can see the model answer after the test ends',
        linearNavigation: 'Linear navigation: The student cannot go back to previous items',
        nonLinearNavigation: 'Non-linear navigation: The student can go back to previous items',
        createAttempts: 'Create attempts',
        successCreateAttempts: 'The attempts were created with success',
      },
      grades: {
        noAttempt: 'No submission',
        point: 'Point',
        points: 'Points',
        defineGrade: 'Define grade',
        assignGrade: 'Assign grade',
        maxValueExceeded: 'The grade value can\'t exceed the max value defined',
        notEmpty: 'The grade value can\'t be empty',
        noStudents: 'There are currently no students assigned to this assessment.',
        maxValueLabel: 'Maximum:',
        negativeValueError: 'The points assigned to an exercise can\'t be negative',
        testOf: 'Test of',
        generateGrades: 'Generate grades',
        successUpdatePoints: 'The exercise points were updated with success!',
        successUpdatePointsWarn: 'The exercise points were updated with success! Don\'t forget to generate the grades again',
        pointsTooHigh: 'The points assigned to the exercise are too high!',
        studentName: 'Student name',
        noAnswersYet: 'No student has attempted the assessment yet to generate grades',
        submission: 'Submission',
        successUpdateGrade: 'The grade was updated with success!',
        submissionLink: 'Submission link',
        publishCorrections: 'Publish corrections',
        correctionsPublished: 'Corrections published',
        exportResolutions: 'Export student resolutions',
        addComment: 'Add comment',
        successUpdateFeedback: 'The comment was updated with success!',
        unavailable: 'This will become available once the test finishes',
        unavailableStudent: 'This will become available once the student finishes the test',
        publishCorrectionsInformation: 'An e-mail will be sent to each student notifying them that their assessment was corrected. The e-mail will contain a link where they can see the attempt and feedback.',
        successUpdateCriteriaGrade: 'The criteria grade was successfully updated',
        criteriaGradeNotEmptyError: 'The criteria grade can\'t be empty',
        criteriaGradeNegativeValueError: 'The criteria grade can\'t have a negative value',
        criteriaGradeTooHighError: 'The criteria grade can\'t be higher than 5',
        exportAttemptsCSV: 'Export resolutions CSV',
        exportGrades: 'Export grades Excel',
        deleteAttemptUnavailable: 'This student has not yet started an attempt.',
        deleteAttemptConfirm: (name) => `Are you sure you want to delete ${name} attempt?`,
        deleteAttemptConfirmSubtitle: 'This action is not reversible.',
        successDeleteAttempt: 'The attempt was deleted successfully.',
        studentSearch: 'Search for students',
        noStudentsFound: 'No students found',
        showModelAnswer: 'Model answer: student can see the model answer when reviewing the assessment',
        notFinished: 'Not finished',
        attemptDates: 'Started at - Ended at',
        annotate: 'Annotate',
        saveAnnotation: 'Save annotations',
        comment: 'Comment',
        saveComment: 'Save',
        commentBoxPlaceholder: 'Add a comment...',
      },
      stats: {
        classAverage: 'Average',
        classMedian: 'Mean',
        averageInfo: 'The average value is calculated by adding all test results and dividing by the number of tests',
        medianInfo: 'The median is the middle value obtained when all test results are ordered from lowest to highest',
        positive: 'Positive',
        negative: 'Negative',
        highest: 'Highest grade',
        lowest: 'Lowest grade',
        question: 'Question',
        sortOrder: 'Sort: ',
        lowToHigh: 'Ascending',
        highToLow: 'Descending',
        avrgGrade: 'Average grade',
        avrgNormalized: 'Average normalized grade',
        avrgNormalizedInfo: 'Order questions by average normalized grade in ascending or descending order',
        avrgQuestionInfo: 'The average grade obtained in this question',
        premiumStats: 'Get a premium membership to view more advanced statistics',
        bestQuestion: 'Question with the best answers',
        worstQuestion: 'Question with the worst answers',
        bestQuestionShort: 'Best question',
        worstQuestionShort: 'Worst question',
        noAnswer: 'No answer',
        distributionChart: 'Number of results',
        choices: 'Multiple choice',
        ascSort: 'Click to sort ascending',
        descSort: 'Click to sort descending',
        cancelSort: 'Click to cancel sorting',
        fastestTime: 'Fastest time',
        slowestTime: 'Slowest time',
        averageTime: 'Average time',
        inProgress: 'In progress...',
        studentName: 'Student name',
        studentGrade: 'Grade',
        studentRank: 'Rank',
        previewAnswer: 'Preview exercise',
        studentCount: 'Number of students',
        gradeCount: 'Number of grades',
        gradeCountTip: 'In the represented intervals, such as 0% to 10%, the lower limit (0%) is included in the bar and the upper limit is not, except for the last interval, 90% to 100%, which includes both. ',
      },
      physicalVersion: {
        physicalVersion: 'Physical Version',
        exportTest: 'Export assessment',
        generalSettings: 'General settings',
        versionsLabel: 'Number of versions',
        versionsPlaceholder: 'Insert the number of versions',
        localeLabel: 'Language',
        shuffleExercisesLabel: 'Shuffle exercise\'s order',
        shuffleChoicesLabel: 'Shuffle choice\'s order from multiple choice exercises',
        noExercises: 'The test needs at least one exercise to be exported',
        notEnoughExercises: 'There is not enough exercises to satisfy the requested versions.',
        headerSettings: 'Header settings',
        studentInput: 'Student fills',
        testName: 'Assessment name',
        testNamePlaceholder: 'Insert here the assessment name',
        testNameEmptyError: 'The assessment name can\'t be empty',
        displayVersion: 'Display the assessment version',
        schoolName: 'School name',
        schoolNamePlaceholder: 'Insert here the school name',
        schoolNameEmptyError: 'The school name can\'t be empty',
        schoolLogo: 'School logo',
        schoolLogoPlaceholder: 'Drag or click here to insert an image',
        schoolLogoEmptyError: 'You must insert an image for the school logo',
        subject: 'Subject',
        subjectPlaceholder: 'Insert here the subject name',
        subjectEmptyError: 'The subject name can\'t be empty',
        testDuration: 'Assessment duration (in minutes)',
        testDurationPlaceholder: 'Insert here the assessment duration',
        testDurationEmptyError: 'The test duration can\'t be empty',
        schoolYear: 'School year',
        schoolYearPlaceholder: 'Insert here the school year',
        schoolYearEmptyError: 'The school year can\'t be empty',
        'class': 'Class',
        classPlaceholder: 'Insert here the class',
        classEmptyError: 'The class can\'t be empty',
        date: 'Assessment date',
        datePlaceholder: 'Insert here the assessment date',
        dateEmptyError: 'The assessment date can\'t be empty',
        exercisesSettings: 'Exercises settings',
        includeZone: 'Include answer zone',
        askForName: 'Student\'s name',
        askForCode: 'Student\'s code',
        askForGrade: 'Student grade',
        extraFields: 'Extra fields',
        extraFieldButton: 'Add new field',
        extraFieldLabel: 'Field name:',
        extraFieldLabelPlaceholder: 'Insert here the field name',
        customFieldEmptyLabelError: 'You must insert a field name',
        extraFieldContent: 'Field content:',
        extraFieldContentPlaceholder: 'Insert here the field content',
        customFieldEmptyContentError: 'You must insert the field content',
        numberOfLines: 'Number of answer lines',
        numberOfLinesJustify: 'Number of justification lines',
        showLines: 'Show lines',
        includeRubric: 'Include rubric in the exported versions',
        noRubric: 'You haven\'t selected a rubric yet',
        sectionWithoutExercises: 'This section doesn\'t have exercises',
      },
      attemptsExport: {
        includeSchoolLogo: 'Include school logo',
        schoolLogoPlaceholder: 'Drag or click here to insert an image',
        schoolLogoEmptyError: 'You must insert an image for the school logo',
        includeGrade: 'Include grade',
        includeRubric: 'Include rubric',
        waitWhileGenerateDocuments: 'Wait while we generate the documents',
        clickBelowToDownloadDocuments: 'Click below to download the documents',
        exportAttemptsPDF: 'Export attempts PDF',
        generatingPDFs: 'Generating the PDFs',
        zippingPDFs: 'Zipping the PDFs',
        generateSuccessDescription: 'The exams were sucessfully exported to PDF! Click in the button below to download.',
        includeModelAnswer: 'Include model answer',
        exportAttemptsNotAvailable: 'There are no attempts to export',
        downloadAttempts: 'Download attempts',
        downloadValidatedExplanation: 'Only validated attempts will be exported',
      },
      send: {
        sendTest: 'Send assessment',
        keepCopy: 'Keep original and send a copy',
        successSendTest: 'The assessment has been sent successfully',
        errorSendTestGroup: 'You must choose a group to send the assessment.',
        errorSendTestSchool: 'You must choose a space to send the assessment.',
        errorSendTestToMySpace: 'You can\'t send assessments from other users to your personal space.',
      },
      settingsModal: {
        name: 'Name',
        successEditSettings: 'The test settings were updated with success',
      },
      rubric: {
        addRubric: 'Add rubric',
        selectRubricFromBank: 'Select rubric from bank',
        successSetRubric: 'The rubric has been successfully added',
        changeRubric: 'Change rubric',
        noRubric: 'There is no rubric associated with this test yet',
        totalPonderations: 'Total ponderations for the rubric criteria',
        notEmpty: 'The exercise criteria weight can\'t be empty',
        negativeValueError: 'The exercise criteria weight can\'t have a negative value',
        weightTooHigh: 'The exercise criteria weight can\'t be over 100%',
        successUpdateCriteriaWeight: 'The exercise criteria weight was updated with success',
        errorTotal: 'The total of the weights for an exercise must equal 100%',
        createRubric: 'Create new rubric',
        editRubric: 'Edit rubric',
        successEditRubric: 'The rubric was updated with success',
      },
      exercises: {
        addSection: 'Add section',
        sectionNameLabel: 'Section name',
        sectionDescriptionLabel: 'Section description',
        sectionNameRequiredError: 'The section name is required',
        addSectionSuccessful: 'The section was successfully added to the test',
        editSectionSuccessful: 'The section was successfully updated',
        sectionPresentation: 'Section presentation',
        sectionNavigation: 'Section navigation',
        sectionTimerLabel: 'Section timer in minutes (optional)',
        sectionTimerWarning: 'The section timer only works on incremental tests',
        pauseItem: 'Pause item',
        addPauseItem: 'Add pause item',
        addPauseItemStepHeader: 'Pause message',
        addPauseItemPlaceholder: 'Write here the pause message',
        successAddPauseItem: 'The pause item was created successfully',
        editPublishedExerciseWarning: 'Once the assessment is published, certain details cannot be edited and upon saving the changes, the exercise will be automatically graded again and any previous grades will be replaced.',
        editPointsSuccess: 'The exercise points were successfully updated',
        editPointsSuccessAfterPublish: 'The exercise points were successfully updated. The grades might take some time to update',
        editWeightsSuccess: 'The exercise criteria weights were successfully updated',
        editWeightsSuccessAfterPublish: 'The exercise criteria weights were successfully updated. The grades might take some time to update',
      },
    },
    exerciseForm: {
      createExerciseOf: 'Create exercise of',
      editExerciseOf: 'Edit exercise of',
      editExercise: 'Edit exercise',
      selectExerciseType: 'Select exercise type',
      typeChoices: 'Multiple choice',
      typeTrueFalse: 'True / False',
      typeText: 'Text',
      typeImage: 'Images submission',
      typeFilling: 'Fill the gaps',
      typeConnecting: 'Connecting',
      typeOrdering: 'Ordering',
      typeSmartChoices: 'Smart multiple choice',
      typeCaption: 'Captioning',
      typeSegmentation: 'Segmentation',
      typeTable: 'Table',
      newChoices: 'New multiple choice exercise',
      newTrueFalse: 'New true / false exercise',
      newText: 'New text exercise',
      newImage: 'New image submission exercise',
      newSmartChoices: 'New smart multiple choice exercise',
      editChoices: 'Editing multiple choice exercise',
      editTrueFalse: 'Editing true / false exercise',
      editText: 'Editing text exercise',
      editImage: 'Editing image submission exercise',
      editSmartChoices: 'Editing smart multiple choice exercise',
      discardTypeTitle: 'Discard exercise',
      discardTypeContent: 'If you continue, all the additions will be lost',
      saveWarningContent: 'Upon saving, the exercise will be automatically graded again and any previous grades will be replaced.',
      statement: 'Statement',
      statementPlaceholder: 'Write here the statement',
      statementPlaceholderInformation: 'Write here the information',
      noEmptyStatement: 'The statement can\'t be empty',
      noEmptyFormula: 'You cannot insert an empty formula',
      noInvalidFormula: 'The formula seems to be invalid. If it has initial or ending spaces remove them. Please fix it before continuing',
      trueFalse: 'True / false',
      noEmptyTruth: 'It is required to select a truth value',
      choices: 'Choices',
      choicesTip: 'Add the different options and choose the correct option by clicking the checkmark button on the left side of the option.',
      oneCorrectOption: 'As you published the assessment with the exercise having only one correct answer, you must keep only one correct option.',
      multipleCorrectOptions: 'As you published the assessment with the exercise having more than one correct answer, you must keep at least two correct options.',
      itemstoOrder: 'Items to order',
      itemsToOrderTip: 'The items should be ordered in the order you consider as correct. The items will be randomized when presented to the students.',
      option: 'Option',
      addOption: 'Add new option',
      removeOption: 'Remove option',
      noEmptyOption: 'There cannot be empty options',
      noDuplicateOptions: 'There cannot be duplicate options',
      noAnswerChoiceDefined: 'It is required to define the correct option',
      editMultipleOptionFail: 'It is required to define at least two correct options',
      item: 'Item',
      addItem: 'Add new item',
      removeItem: 'Remove item',
      noEmptyItem: 'There cannot be empty items',
      noDuplicateItems: 'There cannot be duplicate items',
      noAnswerGapDefined: 'It is required to define at least one gap',
      noExtraWordDefined: 'It is required to define at least one extra option for each gap',
      previewAndSave: 'Preview and save',
      saveExercise: 'Save exercise',
      successExerciseCreated: 'Exercise created with success',
      successExerciseEdited: 'Changes saved with success',
      successExerciseEditedAfterPublish: 'Changes saved with success. The grades might take some time to update',
      advancedMode: 'LaTeX mode',
      formulaHeader: 'Add formula',
      formulaLabel: 'Write here your formula',
      formulaReference: 'Supported formula reference',
      formulaSectionGeneral: 'General',
      formulaSectionMath: 'Math',
      videoHeader: 'Add video',
      youtube: 'Youtube video',
      youtubeLink: 'Youtube link',
      invalidUrl: 'It is only possible to insert Youtube videos links',
      noVideoChosen: 'You didn\'t choose any link',
      myVideo: 'My video',
      addVideoLabel: 'Click to add a video',
      justificationLabel: 'Justification',
      justificationText: 'Add space for justification',
      askForImage: 'Image',
      askForImageError: 'Wrong file type! Please insert only image files',
      caption: 'Caption',
      createCaptions: 'Create the captions',
      editCaptions: 'Edit the captions',
      createCaptionsDescription: 'Click the image to add a caption. You can press and hold a caption to be able to drag it.',
      editCaptionsDescription: 'Click a caption to edit the text.',
      captionWarning: 'Warning: This exercise has a better experience when answering it on a computer.',
      shortAnswer: 'Answer',
      shortAnswerText: 'Turn into short answer exercise',
      characterLimit: 'Character limit',
      characterLimitError: 'Specify the number of characters',
      extraText: 'Extra text',
      extraTextPlaceholder: 'Insert here the extra text',
      extraTextStartOpen: 'Start fully expanded',
      identifiers: 'Identifiers',
      useMathSymbols: 'Use math symbols (Student)',
      wordCountLabel: 'Show word count (Student)',
      exerciseSettings: {
        label: 'Exercise Settings',
        title: 'Exercise Title',
        whoCanSee: 'Who can see this exercise?',
        areas: 'Which subjects are relevant to this exercise?',
        years: 'For which grades?',
        errorTitleRequired: 'It is required to pick a title',
        errorSubjectRequired: 'It is required to pick a subject',
        errorYearRequired: 'It is required to pick a grade',
        'public': 'Public',
        publicTip: 'Visible on the "Explore" page',
        'private': 'Private',
        privateTip: 'Visible only to you or to the groups that you share the exercise with',
      },
      gap: {
        choosingGaps: 'Choosing the words to hide',
        choosingGapsSub: 'Select the words you want to hide from the students',
        noGaps: 'There are no words in the statement yet',
        choosingGapExtraOption: 'Exercise options (student side)',
        dragDropOption: 'Show the list of hidden words (dragging)',
        dropdownOption: 'Show the list of hidden words (dropdown)',
        writeOption: 'Don\'t how the list of hidden words (write)',
        example: 'Example',
        write: 'Write...',
        correctionAuto: 'Automatic correction by the platform',
        correctionManual: 'Correction made by the teacher',
        askForExtraGapsButton: 'Save extra words',
        addExtraGaps: 'Add extra words',
        editExtraGaps: 'Edit extra words',
        addExtraGapsSub: 'Write extra words to add difficulty to this exercise',
        editExtraGapsSub: 'Edit the extra words to add difficulty to this exercise',
        addGapOptions: 'Add options for gap: ',
        editGapOptions: 'Edit options for gap: ',
        word: 'Word',
        orderGaps: 'Order words',
        noExtraGaps: 'There are no extra words to edit',
      },
      choice: {
        shuffleChoices: 'Shuffle multiple choices',
      },
      connecting: {
        shuffleConnectors: 'Shuffle connectors',
        connectors: 'Connectors',
        connector: 'Connector',
        connections: 'Connections',
        addConnector: 'Add connector',
        noEmptyConnector: 'There cannot be empty connectors',
        noConnections: 'It is required to have at least one connection',
        noConnectors: 'It is required to have at least two connectors',
        noConnectorsLeft: 'It is required to have at least one connector on the left column',
        noConnectorsRight: 'It is required to have at least one connector on the right column',
      },
      segmentation: {
        createConnectorsAndConnections: 'Create connectors and connections',
        createConnectorsAndConnectionsDescription: 'Click the image to add a connector. You can create a connection by clicking the two connectors you want to connect.',
        showOnlyLine: 'Show only line',
      },
      table: {
        tableGrading: 'Grading options',
        gradeByRow: 'By row',
        gradeByTable: 'By table',
        gradeByRowTip: 'The exercise will be graded by row, meaning the student has to answer correctly on every column of the row, by either selecting it or not, if not the row is considered incorrect.',
        gradeByTableTip: 'The exercise will be graded by table, meaning that every single cell has to be answered correctly, otherwise the exercise is considered incorrect.',
        noEmptyHeaderCell: 'There cannot be empty main header cells',
        headerPlaceholder: 'Header',
        rowGradingExplanation: 'Graded by row',
        tableGradingExplanation: 'Graded by table',
      },
      audio: {
        audioHeader: 'Add audio',
        addAudioLabel: 'Click to add an audio',
        askForAudioError: 'Wrong file type! Please insert only audio files',
        noAudioChosen: 'You didn\'t choose an audio file',
        canPauseLabel: 'The student can pause the audio execution',
        canPause: 'Can pause',
        cannotPause: 'Cannot pause',
        limitRepetitionsLabel: 'Limit the amount of times the student can repeat the audio',
        maxRepetitionsLabel: 'Maximum repetitions',
        repetitions: 'repetitions',
        retryMessage: 'An error occurred. Try to play the audio again',
      },
      video: {
        audioHeader: 'Add video',
        addAudioLabel: 'Click to add a video',
        askForAudioError: 'Wrong file type! Please insert only video files',
        noAudioChosen: 'You didn\'t choose an video file',
        canPauseLabel: 'The student can pause the video execution',
        canPause: 'Can pause',
        cannotPause: 'Cannot pause',
        limitRepetitionsLabel: 'Limit the amount of times the student can repeat the video',
        maxRepetitionsLabel: 'Maximum repetitions',
        repetitions: 'repetitions',
      },
      modelAnswer: 'Model Answer',
      modelAnswerPlaceholder: 'Insert here the model answer',
    },
    exercises: {
      title: 'Exercises database',
      subtitle: 'All the exercises created',
      createNewExercise: 'Create exercise',
      createNewExerciseSubtitle: 'Here we\'re going to create a new exercise',
      cancelCreation: 'Cancel creation',
      cancelEdit: 'Cancel edit',
      creatingNewExerciseOn: 'Creating a new exercise on',
      editingExercise: 'Editing an exercise on',
      deleteExercise: 'Delete exercise',
      deleteExercises: 'Delete exercises',
      deleteExerciseInfo: 'Are you sure you want to delete this exercise?',
      deleteExercisesInfo: 'Are you sure you want to delete these exercises?',
      deleteExerciseInfo2: 'This action is not reversible',
      successDeleteExercise: 'The exercise was successfully deleted',
      successDeleteExercises: 'The exercises were successfully deleted',
      successDuplicateExercise: 'The exercise was duplicated with success',
      deleteErrorDoesNotExist: 'Delete could not be done because the exercise already does not exist',
      previewExercise: 'Preview exercise',
      previewErrorDoesNotExist: 'Preview could not be loaded because the exercise does not exist anymore',
      noExercises: 'No exercises have been created yet.',
      noExercisesForFilters: 'There\'s no exercises for the selected filters.',
      gapList: 'Words/expressions list:',
      gapListUnused: 'Unused words/expressions list:',
      labels: {
        addLabel: 'Add label',
        createLabel: 'Create label',
        repeatedLabelError: 'There is already a label with that name',
        labelName: 'Label name',
        errorNoColorSelected: 'It is required to set a color',
        errorNameRequired: 'It is required to set a name',
        labels: 'Labels',
        editLabels: 'Edit labels',
        successEditLabel: 'The label was successfully edited',
        successDeleteLabel: 'The label was successfully deleted',
      },
      filters: 'Filters',
      teacherFilter: 'Teachers',
      typeFilter: 'Exercise types',
      order: 'Order',
      orderNewest: 'Newest',
      orderOldest: 'Oldest',
      orderLastModified: 'Last modified',
      send: {
        sendExercise: 'Send exercise',
        sendExercises: 'Send exercises',
        keepCopy: 'Keep original and send a copy',
        sendLabels: 'Send labels to the other group',
        successSendExercise: 'The exercise has been sent successfully',
        successSendExercises: 'The exercises have been sent successfully',
        errorSendExerciseGroup: 'You must choose a group to send the exercise.',
        errorSendExercisesGroup: 'You must choose a group to send the exercises.',
        errorSendExercisesSchool: 'You must choose a space to send the exercises.',
        errorSendExercisesToMySpace: 'You can\'t send exercises from other users to your personal space.',
      },
      modelAnswer: 'Model answer',
    },
    attempt: {
      nextExercise: 'Next exercise',
      nextItem: 'Next',
      previousExercise: 'Previous exercise',
      previousItem: 'Previous',
      finishedAttempt: 'Finished. You can close the tab.',
      finishedAttemptIave: 'Test finished. Wait for teacher\'s instructions.',
      characterLimitReached: 'Maximum number of characters reached',
      timesUp: 'Section time\'s up',
      unsavedAnswers: 'There was an error saving your answer(s). Check your internet connection and try to save again',
      unsavedAnswersFinish: 'You have unsaved answers. Are you sure you want to finish?',
      answersSaved: 'The answers were successfully saved',
      errorSaveAnswers: 'An error ocurred while saving the answers',
      exitPinModal: {
        pinLabel: 'Please input the exit exam pin provided by the supervisor',
        pinPlaceholder: 'Pin',
        modalHeader: 'Exit exam pin',
        wrongPin: 'The pin is not correct',
        emptyPin: 'You must insert a pin to proceed',
      },
      oops: 'An error occurred while saving your answer(s). Check your internet connection and try again',
      continueWithoutSaving: 'Continue without saving',
      saveAndContinue: 'Save and continue',
      status: 'Status',
      statusValid: 'Valid',
      statusInvalid: 'Invalid',
      statusPending: 'Pending',
      statusUpdated: 'Successfully updated',
    },
    groups: {
      title: 'Groups',
      teachers: 'teachers',
      teacher: 'teacher',
      createNewGroup: 'Create group',
      groupName: 'Group\'s name',
      successCreateGroup: 'The group was successfully created',
      hintTargets: 'This field defines which teachers will be added to the group',
      errorNameRequired: 'It is required to set a name',
      noGroups: 'Still don\'t have a group? Create one and start collaborating with other teachers!',
      successEditGroup: 'The group was successfully edited',
      userNotAllowed: 'The user has already exceeded the limit of groups they can join',
    },
    group: {
      title: 'Group',
      leaveGroup: 'Leave group',
      leaveGroupInfo: 'Are you sure you want to leave this group?',
      leaveGroupInfo2: 'This action is not reversible and you will lose access to all the group\'s content.',
      successLeaveGroup: 'You successfully left the group',
      removeTeacher: 'Remove teacher?',
      removeTeacherInfo: 'Are you sure you want to remove this teacher?',
      successRemoveTeacher: 'The teacher was removed successfully',
      successAddTeacher: 'The teachers were added successfully',
      addTeacher: 'Add teachers',
      hintTargets: 'Insert the e-mails of the teachers that you want to add to the group',
      hintTargetsCreatable: 'Insert the e-mails of the teachers that you want to add to the group. You can add e-mails of teachers that are not in Intuitivo in order to invite them.',
      groupSettings: 'Group settings',
      deleteGroup: 'Delete group',
      deleteGroups: 'Delete groups',
      deleteGroupInfo: 'Are you sure you want to delete the group? You won\'t be able to recover the contents afterwards. This action is not reversible.',
      deleteGroupsInfo: 'Are you sure you want to delete these groups? You won\'t be able to recover the contents afterwards. This action is not reversible.',
      successDeleteGroup: 'The groups was successfully deleted',
      removeSelected: 'Remove selected',
    },
    publication: {
      password: 'Test\'s password',
      testWindowClosedInfo: 'The test\'s opening window closed.',
      studentName: 'Student name',
      errorNameRequired: 'It is required to insert your name.',
      errorEmail: 'The e-mail you provided is incorrect.',
      passwordRequired: 'It is required to provide a password.',
      emailHint: 'The inserted e-mail will be used to send you your grade and feedback once it\'s published.',
      continueAsGuest: 'Continue as guest',
      continueAs: (name) => `Continue as ${name}`,
      notYou: 'Not you?',
      enterAsNewStudent: 'Enter as a new student',
    },
    rubrics: {
      title: 'Rubrics',
      noRubrics: 'No rubrics have been created yet',
      previewRubric: 'Preview rubric',
      performanceLevels: 'Performance levels',
      createRubric: 'Create rubric',
      editRubric: 'Edit rubric',
      deleteRubric: 'Delete rubric',
      deleteRubrics: 'Delete rubrics',
      deleteRubricInformation: 'Are you sure you want to delete this rubric? This action is not reversible.',
      deleteRubricsInformation: 'Are you sure you want to delete these rubrics? This action is not reversible.',
      successDeleteRubric: 'The rubric was successfully deleted',
      successDeleteRubrics: 'The rubrics were successfully deleted',
      successDuplicateRubric: 'The rubric was successfully duplicated',
      form: {
        rubricNameLabel: 'Rubric name',
        rubricDescriptionLabel: 'Rubric description',
        criteriaNameLabel: 'Criteria name',
        criteriaDescriptionLabel: 'Criteria description',
        performanceLevelDescriptionLabel: 'Performance level description',
        addCriteria: 'Add criteria',
        discardRubric: 'Discard rubric',
        nameRequiredError: 'It is required to insert a name for the rubric.',
        criteriaNameRequiredError: 'It is required to insert a name for the criteria.',
        criteriaRequiredError: 'It is required to create at least one criteria for this rubric.',
        createRubricSuccess: 'The rubric was successfully created',
        editRubricSuccess: 'The rubric was successfully updated',
      },
      send: {
        sendRubric: 'Send rubric',
        sendRubrics: 'Send rubrics',
        keepCopy: 'Keep original and send a copy',
        successSendRubric: 'The rubric has been sent successfully',
        successSendRubrics: 'The rubrics have been sent successfully',
        errorSendRubricGroup: 'You must choose a group to send the rubric.',
        errorSendRubricsGroup: 'You must choose a group to send the rubrics.',
        errorSendRubricsSchool: 'You must choose a space to send the rubrics.',
        errorSendRubricsToMySpace: 'You can\'t send rubrics from other users to your personal space.',
      },
    },
    explore: {
      title: 'Explore',
      header: 'Explore the available contents',
      headerXs: 'Explore',
      contentsListHeader: 'Most used contents, according to the selected filters',
      contentsListHeaderLg: 'Most used contents',
      contentsListHeaderXs: 'Contents',
      noResultsHeader: 'Oops... We couldn\'t find any content matching your search :(',
      noResultsDescription: 'Try searching for a more general term, change the filters or verify if there is some error in the search keywords.',
      noResultsDescription2: 'If these tips don\'t work, we invite you to create new content.',
      createNewExercise: 'Create exercise',
      endMessage: 'You\'ve seen all the results corresponding to your search.',
      scrollBackToTopMessage: 'Scroll back to the top of the page',
      searchPlaceholder: 'Search for contents in Intuitivo...',
      addFilter: 'Add filter',
      subjectAreas: 'Subject areas',
      schoolYears: 'School years',
      numberOfUses: 'Usages',
      copyInformation: 'To which group do you which to copy this exercise?',
      successCopyExercise: 'The exercise was successfully copied to your group',
      infoTip: 'Fill in your preferences on your profile page for a more personalized experience',
      searchAll: 'All content',
    },
    profilePreferences: {
      setProfilePreferences: 'Before you continue, help us improve your experience.',
      saveProfilePreferences: 'The preferences were successfully saved',
      warningProfilePreferences: 'To change your preferences go to the "Account" page',
      subjects: 'Which subjects are you teaching?',
      years: 'To which grades?',
    },
    login: {
      loginWithMicrosoft: 'Login with Microsoft',
      poweredBy: 'Powered by',
    },
    tours: {
      tip: 'Guides',
      title: 'Do you need help?',
      startTour: 'Start',
      subtitles: [
        'In this section you can find multiple guides to help you interact with Intuitivo.',
        'To start, simply select one of the guides available below.',
      ],
      back: 'Back',
      next: 'Next',
      complete: 'Complete',
      introduction: {
        title: 'Introduction',
        subtitle: 'Learn the basics of Intuitivo',
        steps: {
          introduction: 'Welcome to Intuitivo, we have put together a guide to help you get started. To continue click on the "Next" button. If you\'re already familiar with Intuitivo, you can close this message.',
          testCard: 'To help you get started with Intuitivo, we\'ve created a simple assessment to explore together.',
          testCardPublishButton: 'Published assessments will show up with this green colored icon. If not yet published, it will be grey.',
          testCardLeft: 'Click on this card to see more details for this assessment.',
          tabExercises: 'Right now, you\'re in the "Exercises" tab. Here you can see which exercises constitute the assessment.',
          tabGrade: 'For this guide, the assessment has already been published and has had 2 submissions. Select the "Correction" tab to see the student\' submissions',
          statistics: 'You can see the general stats for this assessment.',
          attempts: 'You also have access to this list of students\' names and grades who have submitted their answers for this assessment. Clicking on a name will show the student\'s answers.',
          attemptContainer: 'This entire section is dedicated to the selected student\'s individual attempt.',
          attemptHeader: 'The header of each attempt contains information about the student, as well as final grade and submission link.',
          multipleChoice: 'You\'ll notice that the student has answered correctly to this "Multiple Choice" exercise.',
          multipleChoiceGrader: 'Intuitivo automatically grades some exercise types, as is the case for this "Multiple Choice" exercise. The student has obtained the maximum score, however, you can freely edit this value.',
          openQuestionGrader: 'For "Text exercises" you\'ll have to manually grade it.',
          openQuestionFeedback: 'You can also provide feedback on a per question basis.',
          publishGradesButton: 'Once you\'ve graded the assessment for all students, you can publish grades and corrections. Students which have provided an email address in the beginning of the test will receive their grade by email.',
          tabStatistics: 'In the "Statistics" tab, you can have a detailed view of students performance.',
          assessmentSidebarOption: 'Let\'s go back to the "Assessments" page.',
          testCardDropdown: 'When you\'re done exploring this assessment, you can delete it at any time by clicking on this menu and choosing the "Delete" option.',
          hub: 'You can have another go at this guide at anytime. We recommend you explore this section in case you need help with creating assessments and exercises.',
        },
      },
      assessments: {
        title: 'Assessments',
        subtitle: 'Learn how to create tests and worksheets',
        steps: {
          sidebarGoToPage: 'First, go to the "Assessments" page.',
          sidebar: 'You can access assessments on the "Assessments" page.',
          createButton: 'To create an assessment, click on the "Create Assessment" button.',
          nameInput: 'Give a name to your assessment, something like "My first assessment".',
          typeInput: `Choose an assessment type:
            <ul>
              <li><strong>Test:</strong> Has a time limit and is scheduled for a specific date.</li>
              <li><strong>Worksheet:</strong> Accessible until a delivery date.</li>
            </ul>
            Let's leave the default "Test" option.`,
          classificationInput: `Choose an assessment type:
            <ul>
              <li><strong>Classic:</strong> Student submissions are graded.</li>
              <li><strong>Rubric:</strong> Have a defined assessment criteria (You can learn more about rubrics on the "Rubrics" tour).</li>
              <li><strong>No Classification:</strong> No grade is given to student submissions.</li>
            </ul>
            Let's leave the default "Classic" option.`,
          groupInput: 'You can save this assessment on any group you belong to. (You can learn more about groups on the "Groups" tour).',
          submitButton: 'Complete assessment creation by submitting this form.',
          exercisesTab: 'You\'re currently on the "Exercises" tab. Here is where you can add content to your assessment, like exercises, sections and information blocks.',
          gradesTab: 'When your assessment is published and is answered by your students, you\'l be able to see their submissions in the "Correction" tab.',
          physicalTab: 'In the "Physical Version" tab, you can download your assessment as a PDF document.',
          contentForm: 'In this section you can add content to your assessment.',
          createNewExercise: 'Let\'s do exactly that. Click on "Create a new exercise".',
          exerciseType: 'You can choose what exercise type you want to create.',
          trueOrFalseExerciseType: 'Choose the "True/False" option.',
          exerciseStatement: 'Write the exercise\'s statement, something like "Are dolphins mammals?"',
          exerciseCorrectAnswer: 'In this section you can choose the correct answer.',
          exerciseTrue: 'In this case, let\'s choose the "True" option.',
          exerciseSubmit: 'You\'re all set. This exercise will also be added to your "Exercise" library (You can learn more about exercises on the "Exercise" guide). For now, click on "Confirm".',
          exerciseGrade: 'You can edit the grade of single exercises.',
          exerciseEdit: 'You can edit an exercise anytime by clicking this button.',
          exerciseDelete: 'An exercise can also be deleted from an assessment.',
          publishButton: 'You\'re test is now ready to be published. Click on the "Publish" button.',
          publishFormFormat: 'You can choose if you want to show all exercises at once, or have only one exercise visible at a time.',
          publishFormStart: 'Choose a start date for this test.',
          publishFormTolerance: 'Choose the number of minutes a student can be late for the test.',
          publishFormDuration: 'Choose how much time your students have to complete this test',
          publishFormPassword: 'You can choose to protect your test by setting a password which will be requested when your students start your test.',
          publishFormShuffle: 'You can choose to shuffle the test\'s questions, that way all your students will have a different test.',
          publishFormFeedback: 'You can also choose to allow your students to have access to the test\'s answers after submitting.',
          publishFormModelAnswer: 'If the immediate feedback option is chosen, you can choose to allow your students to have access to the exercise model answers after submitting.',
          publishFormSubmit: 'You\'re all set, you can now publish your test by clicking in "Publish".',
          publishLink: 'Congratulations, your test has been published. Now you simply need to share this link with your students for them to take the test.',
          publishCloseModal: 'You can now close this window.',
          publishButtonFinal: 'You can view the previous window again by clicking on this button.',
          hub: 'This and other guides are always available on the Guide section.',
        },
      },
      explore: {
        title: 'Explore',
        subtitle: 'Learn how to use and adapt other teachers\' content.',
        steps: {
          sidebarExplore: 'To start this guide, go to the "Explore" page.',
          sidebar: 'You can access assessments on the "Explore" page.',
          exerciseList: 'On this page you can find all the exercises and assessments created and made public by other schools and teachers that use Intuitivo. To contribute, just save your own content as public during exercise or assessment creation.',
          header: 'On this section you have access to a toll which allows you to search public contents.',
          headerTextInput: 'You can freely search for keywords.',
          headerContentDropdown: 'You can also filter by content type: Assessment, Exercises or both',
          headerFilters: 'There\'s also the option to search by School Year or Subject. By default, you profile preferences are selected (You cna change them on your account settings).',
          exerciseCardClick: 'Click on the following content for a detailed view.',
          exerciseCardPreview: 'Here you can preview the content in more detail',
          exerciseCardPreviewCopy: 'To add this content to you profile you simply need to click on "Copy".',
          exerciseCardPreviewSelectGroup: 'Then you need to choose the destination group for this content.',
          exerciseCardPreviewSubmit: 'And finally click on "Confirm".',
          exerciseCardClosed: 'Now you can freely see and edit this content on the "Assessments" or "Exercises" page, depending on the type.',
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
      groups: {
        title: 'Groups',
        subtitle: 'Learn how to create and use groups to collaborate with your colleagues',
        steps: {
          sidebarGoToPage: 'To start this guide, go to the "Groups" page.',
          sidebar: 'You can access groups on the "Groups" page.',
          createNewGroup: 'To create a new group click on the "Create Group" button',
          formNameInput: 'Name this group, for example "Math Teachers - Junior Year',
          formTeachersInput: 'In this section you can add your colleagues that are also using Intuitivo through their email address. However, for the purpose of this guide you can leave this field blank.',
          formConfirm: 'Finish your new group creation by clicking on "Confirm".',
          groupCard: 'Each group will be presented as a card named after it. Click on it to have a detailed view.',
          teachersTable: 'In this table you can see all group members\' name and email address.',
          addTeacherButton: 'To add new members, you can click on this button, which will present you with a form to add the new members\' email address.',
          groupShortcuts: `
            <p>
              On this card, you can find shortcuts to the "Assessments", "Exercises" and "Rubrics" pages for this group.
              In each of these pages you'll find a dropdown to navigate between groups, as the following image illustrates:
            </p>
            <img style="width: 100%" src="${process.env.REACT_APP_S3_URL}/resources/groups-header-shortcut-en.png" style="width: 100%">
          `,
          leaveGroup: 'To leave a group simply click on this menu and select the "Leave Group" option.',
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
      rubrics: {
        title: 'Rubrics',
        subtitle: 'Learn how to create and use rubrics on your assessments',
        steps: {
          sidebarGoToPage: 'To start this guide, go to the "Rubrics" page.',
          sidebar: 'You can access assessments on the "Rubrics" page.',
          createNewButton: 'To create a new Rubric click on "Create rubric".',
          nameInput: 'Name your rubric, for example "Assessment Criteria - History"',
          descriptionInput: 'You can write a description to add more context',
          criteriaCard: 'In this section you can add all the criteria you think are necessary. Criteria levels range from 1 to 5.',
          criteria0NameInput: 'Name this criteria "Contents".',
          criteria0ValueInput: `
            Next, you can add performance descriptors to this rubric. For example:
            <ul>
              <li><strong>(5):</strong> Explains completely what was intended;</li>
              <li><strong>(4):</strong> Explains completely what was partially intended;</li>
              <li><strong>(3):</strong> Explains incompletely what was intended;</li>
              <li><strong>(2):</strong> Explains incompletely what was partially intended;
              <li><strong>(1):</strong> Explains incompletely only part of what was intended;</li>
            </ul>`,
          addCriteriaButton: 'Add a new criteria by clicking on this button',
          criteria1NameInput: 'Name this criteria "Communication".',
          criteria1ValueInput: 'Similarly to the last criteria, you can add what performance descriptors you deem as necessary. However, for our case you can leave them blank.',
          criteria1RemoveButton: `
            If you want to delete a criteria, just click on the icon
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
            </svg>`,
          exerciseExample: `
            <p>
              During an assessment's correction of type rubric, each question's assessment will be done by criteria.
              That way you can attribute specific weight to each criteria for each exercise, as the next image illustrates:
            </p>
            <img src="${process.env.REACT_APP_S3_URL}/resources/exercise-rubric-example-en.png" style="width: 100%">

            <p>If you need to, there "Assessments" and "Introduction" guides demonstrate assessment creation, publication and correction.</p>
          `,
          groupDropdown: 'Just like in other examples, you can choose a group to save this rubric on. The "Groups" guide contains more information about this feature.',
          saveButton: 'Finish the creation of this rubric by clicking on "Save".',
          card: 'A created rubric will show up as such.',
          cardActions: `
            In this section of the rubric's card you can:
            <ul>
              <li>Preview;</li>
              <li>Edit;</li>
              <li>Send to another group;</li>
              <li>Duplicate;</li>
              <li>Delete;</li>
            </ul>
          `,
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
      exercises: {
        title: 'Exercises',
        subtitle: 'Learn how to create and use exercises',
        steps: {
          sidebarGoToPage: 'First, go to the "Exercises" page.',
          sidebar: 'You can access exercises on the "Exercises" page.',
          createExerciseButton: 'To create an exercise, click on the button "Create Exercise"',
          createExerciseType: 'There are multiple types of exercises to choose from. We recommend you dedicate some time to explore all these different types. If you need any guidance, you can always check our <a href="https://intuitivo.pt/en/support/tutorials" target="_blank">Tutorials</a> page.',
          createExerciseTypeText: 'For the purposes of this guide, create a simple "Text" exercise.',
          editorHeaderTypeDropdown: 'The first step for exercise creation is complete. However, if you need to change the exercise type you can do it here.',
          editorHeaderGroupDropdown: 'Likewise, you can choose in which group this exercise will be saved. For more information about groups, there\'s the "Groups" guide available on the guide section.',
          editorStatement: `
            Write down the exercise statement. You can add multiple media contents such as images, videos and audios. You also have access to a math formula editor.
            <br/>
            For this example exercise, you can write down something like <i>"Which city was the capital of the Anglo-Saxon kingdom of Wessex?"</i>.
          `,
          editorShortAnswer: 'Depending on the type of exercise, you\'ll have different customization options available. For text exercises you can limit the answer\'s number of characters and allow access to the maths formula editor.',
          editorExtraText: 'In every exercise type, you can also add extra text to the exercise, which will be shown below the student answer field.',
          editorModelAnswer: 'Depending on the exercise type, you can also add a model answer to the exercise, which will not be shown to the student during the assessment but can be optionally presented after the assessment ends.',
          editorPreview: 'You\'ll always have a preview available. This will be what your students will see during an assessment.',
          editorSettingsFormTitle: `
            To finish exercise creation, you'll have to give it a name.
            <br/>
            How about something like <i>"The capital of Wessex"</i>?
          `,
          editorSettingsFormVisibility: `
            Next you can choose if you want to keep your exercise public or private:
            <ul>
              <li><strong>Public:</strong> the exercise will be available on the <i>"Explore"</i> page and can be copied and used by other teachers.
              <li><strong>Private:</strong> the exercise will only be available for you and group members.</li>
            </ul>
            For more information about the <i>"Explore"</i> and <i>"Groups"</i> page, there are guides walking you through those features.
          `,
          editorSettingsFormVisibilityPrivate: 'Let\'s keep the exercise private. Select the <i>"Private"</i> option.',
          editorLabelsButton: 'If necessary, you can create and add labels to catalogue your exercises. This effort will help you filter and search exercises.',
          editorHeaderSaveExercise: 'Finally, complete exercise creation by clicking on the "Save" button.',
          exerciseCard: 'An exercise will show up like this',
          exerciseCardActions: `
            In this section you can:
            <ul>
              <li>Preview the exercise;</li>
              <li>Edit;</li>
              <li>Send to another group;</li>
              <li>Duplicate;</li>
              <li>Delete;</li>
            </ul>
          `,
          exerciseFilters: `
            You also have the possibility of filtering exercises by:
            <ul>
              <li>Authoring teacher;</li>
              <li>Type;</li>
              <li>Labels;</li>
            </ul>
            And sort by:
            <ul>
              <li>Most recent;</li>
              <li>Oldest;</li>
              <li>Last modified;</li>
            </ul>
          `,
          hub: 'You can come back to this and other guides anytime, by clicking on the "Guide" hub.',
        },
      },
    },
    plans: {
      premium: 'Premium',
      free: 'Basic',
      exceededTests: (limit) => `You exceeded the limit of ${limit} free ${limit === 1 ? 'assessment' : 'assessments'} created by you across all groups that you participate. Delete assessments or upgrade to premium with one of the options below.`,
      exceededTestsTip: (limit) => `You exceeded the limit of ${limit} free ${limit === 1 ? 'assessment' : 'assessments'} created by you across all groups that you participate. Delete assessments or upgrade to premium.`,
      upgrade: 'UPGRADE',
      getPremium: 'Get Premium',
      unlimitedAccessMessage: 'Unlock unlimited access to Intuitivo individually.',
      recommendSchool: 'Recommend School',
      recommend: 'RECOMMEND',
      dedicatedSpace: 'Unlock a dedicated space for your school with unlimited access to Intuitivo.',
      exceededExercises: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'exercise' : 'exercises'} created by you across all groups that you participate. Delete exercises or upgrade to premium with one of the options below.`,
      exceededExercisesTip: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'exercise' : 'exercises'} created by you across all groups that you participate. Delete exercises or upgrade to premium.`,
      exceededRubrics: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'rubric' : 'rubrics'} created by you across all groups that you participate. Delete rubrics or upgrade to premium with one of the options below.`,
      exceededRubricsTip: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'rubric' : 'rubrics'} created by you across all groups that you participate. Delete rubrics or upgrade to premium.`,
      exceededGroups: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'group' : 'groups'}. Leave groups or upgrade to premium with one of the options below.`,
      exceededSections: (limit) => `You have exceeded the limit of ${limit} free ${limit === 1 ? 'section' : 'sections'} per test. Delete sections or upgrade to premium with one of the options below.`,
      premiumFeature: 'This is a premium feature. Upgrade to premium to unlock it',
      warningCreatePremiumExercise: 'You can create but you can\'t publish an assessment with this type of exercises.',
      warningPublishTestWithPremiumExercise: 'You can\'t publish an assessment that contains premium exercises. Delete those exercises or upgrade to premium with one of the options below.',
      warningPublishTestWithExceededSections: (limit) => `You can't publish an assessment that contains more than ${limit} ${limit === 1 ? 'section' : 'sections'}. Delete sections or upgrade to premium with one of the options below.`,
      warningExportTestMaxUsages: (limit) => `You can only export up to ${limit} ${limit === 1 ? 'version' : 'versions'}. Upgrade to premium to unlock more.`,
      schools: 'Schools',
    },
    navigation: {
      title: 'Navigation',
      section: 'Section',
    },
    curricula: {
      pt_PT: 'Portuguese',
      other: 'Other',
      curriculum: 'Curriculum',
    },
  },
  pt: {
    ok: 'Ok',
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Submeter',
    hide: 'Esconder',
    preview: 'Visualizar',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Apagar',
    remove: 'Remover',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpar',
    saveChanges: 'Guardar alterações',
    save: 'Gravar',
    copy: 'Copiar',
    oops: 'Algo não correu bem. Por favor tente novamente',
    waitSeconds: 'Este processo pode demorar alguns segundos',
    waitMinutes: 'Este processo pode demorar alguns minutos',
    waitTime: 'Este processo pode demorar algum tempo',
    date: 'Data',
    previous: 'Anterior',
    next: 'Seguinte',
    'export': 'Exportar',
    en: 'Inglês',
    pt: 'Português',
    esES: 'Espanhol',
    ptBR: 'Português (Brasil)',
    unauthorizedMessage: 'Parece que ainda não é suficientemente qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que se perdeu no nosso site... Talvez não seja assim tão Intuitivo.',
    errorMessage: 'Algo de errado aconteceu... Vamos verificar e corrigir o mais rápido possível.',
    noPermissionSpace: 'Não tem permissões para aceder a este espaço.',
    home: 'Início',
    account: 'Conta',
    signout: 'Sair',
    finished: 'Terminado',
    example: 'Exemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos a trabalhar para melhorar esta funcionalidade',
    enable: 'Ativar',
    disable: 'Desativar',
    noOptionsAvailable: 'Nenhuma opção disponível',
    close: 'Fechar',
    'continue': 'Continuar',
    'in': 'em',
    goBack: 'Voltar atrás',
    name: 'Nome',
    email: 'E-mail',
    shortcuts: 'Atalhos',
    leave: 'Sair',
    send: 'Enviar',
    comingSoon: 'Em breve!',
    comment: 'Comentário',
    help: 'Ajuda',
    feedback: 'Sugestões',
    copied: 'Copiado',
    noEmail: 'Sem email',
    selectAll: 'Selecionar tudo',
    general: 'Geral',
    discardWarning: 'Se continuar, todas as adições serão perdidas.',
    notFound: 'Não encontrado',
    copyOf: 'Cópia de',
    otherOptions: 'Outras opções',
    reschedule: 'Reagendar',
    results: 'Resultados',
    'new': 'Novo',
    filterBy: 'Filtrar por',
    copies: 'Cópias',
    orderBy: 'Ordenar por',
    oldest: 'Mais antigo',
    newest: 'Mais recente',
    relevancy: 'Relevância',
    showMore: 'Ver mais',
    showLess: 'Ver menos',
    settings: 'Definições',
    scrollToSides: 'Move para os lados',
    download: 'Descarregar',
    fileTooLarge: 'O ficheiro não pode ser maior que 10Mb',
    fileTooLarge1Gb: 'O ficheiro não pode ser maior que 1Gb',
    drag: 'Arrastar',
    dropHere: 'Arrasta para aqui',
    changeLang: 'Mudar linguagem',
    logout: 'Terminar sessão',
    video: 'Vídeo',
    audio: 'Áudio',
    tutorial: 'Tutoriais',
    continueWith: 'Continuar com',
    or: 'Ou',
    notYou: 'Não é a sua conta?',
    guest: 'Convidado',
    select: 'Selecionar',
    internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
    openFormatting: 'Abrir formatação',
    openMedia: 'Abrir media',
    appNotAvailable: 'O website já não está disponível a partir do browser. Por favor utilize a aplicação para aceder às provas.',
    generateDocument: 'Gerar documento',
    refresh: 'Atualizar',
    seeMore: 'Ver mais',
    deleteDisabled: 'Não pode apagar conteúdos de outros utilizadores',
    andXMore: (x) => `E ${x} outros...`,
    words: 'Palavras',
    contentSettings: {
      'public': 'Público',
      'private': 'Privado',
    },
    common: {
      edit: 'Editar',
      cancel: 'Cancelar',
      discard: 'Descartar',
      finish: 'Terminar',
      goBack: 'Voltar atrás',
      somethingWentWrong: 'Algo não correu bem. Por favor tente novamente',
      internetWentWrong: 'Algo não correu bem. Por favor verifique a sua conexão à internet e tente novamente',
      createdBy: 'Criado por',
      publish: 'Publicar',
      published: 'Publicado',
      name: 'Nome',
      preview: 'Pré-visualizar',
      fontSize: {
        small: 'Pequeno',
        normal: 'Normal',
        large: 'Grande',
        huge: 'Gigante',
      },
    },
    time: {
      day: 'dia',
      days: 'dias',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Turmas',
      tests: 'Testes',
      test: 'Teste',
      threads: 'Fóruns',
      admin: 'Administrador',
      lesson: 'Aula',
      subject: 'Matéria',
      'class': 'Turma',
      exercises: 'Exercícios',
      exercise: 'Exercício',
      correction: 'Correção',
      answer: 'Resposta',
      grade: 'Nota',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Meus conteúdos',
      allGroups: 'Todos os grupos',
      personalSpace: 'Espaço pessoal',
      students: 'Estudantes',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (período experimental)',
      premiumInfo: 'Período experimental por duração indefinida. Neste momento, pode utilizar a plataforma e todas as suas funcionalidades de forma gratuita e ilimitada. Mais tarde, iremos introduzir diferentes planos para professores, sendo que haverá sempre um plano gratuito apesar de ter certas limitações em termos de funcionalidades.',
      rubrics: 'Rubricas',
      rubric: 'Rubrica',
      criterion: 'Critério',
      criteria: 'Critérios',
      classic: 'Clássico',
      noClassification: 'Sem classificação',
      worksheet: 'Ficha',
      assessment: 'Avaliação',
      assessments: 'Avaliações',
      spaces: 'Espaços',
      space: 'Espaço',
      statistics: 'Estatísticas',
      teachers: 'Professores',
      events: 'Eventos',
      exams: 'Exames',
      examGroupsMin: 'Grupos Provas',
    },
    tests: {
      title: 'Testes',
      teacher: {
        subtitle: 'Todos os testes criados para as turmas a que dá aulas',
        exercise: 'item',
        exercises: 'itens',
        resolution: 'resolução',
        resolutions: 'resoluções',
        publish: 'Publicar',
        grades: 'Notas',
        stateUnpublished: 'Não publicado',
        stateUpcoming: 'Agendado',
        stateOngoing: 'A decorrer',
        stateComplete: 'Terminado',
        noTests: 'Ainda não existe nenhum teste criado',
      },
      student: {
        subtitle: 'Todos os testes criados pelos seus professores',
        noTests: 'Não existem testes atualmente disponíveis para si',
        opensIn: 'Abre em',
        openingClosesAt: 'Abertura fecha em',
        testEndsAt: 'Teste termina em',
        testNotOpen: 'O teste ainda não abriu',
        testWindowClosed: 'A tolerância de abertura do teste terminou. Já não será possível abrir o teste',
        testComplete: 'Este teste já foi completado. Todas as respostas foram guardadas',
        testCompleteTime: 'O teste já terminou',
        testPasswordModal: 'Password do teste',
        testWrongPassword: 'A password está errada',
      },
    },
    test: {
      title: 'Teste',
      duplicateTest: 'Duplicar teste',
      question: 'Item',
      informationBlock: 'Bloco de informação',
      information: 'Informação',
      'true': 'Verdadeiro',
      'false': 'Falso',
      textPlaceholder: 'Escreva aqui a resposta',
      justificationLabel: 'Justificação',
      justificationPlaceholder: 'Escreva aqui a justificação',
      dragImageHint: 'Arraste imagens para aqui, ou clique para selecionar imagens',
      dropImageHint: 'Largue para adicionar',
      addQuestion: 'Adicionar conteúdo',
      selectExercises: 'Selecionar exercícios',
      addExercises: 'Adicionar exercícios',
      selectedExercises: 'exercícios selecionados',
      successAddExercises: 'Os exercícios foram adicionados com sucesso',
      successAddExercise: 'O exercício foi adicionado com sucesso',
      successAddInformationBlock: 'O bloco de informação foi adicionado com sucesso',
      successDuplicateTest: 'A avaliação foi duplicada com sucesso',
      errorAddExists: 'Não é possível adicionar exercícios que já foram adicionados ao teste',
      selectExercisesFromOtherSpace: 'Só pode utilizar exercícios criados por si se selecionar outro espaço!',
      selectRubricsFromOtherSpace: 'Só pode utilizar rubricas criadas por si se selecionar outro espaço!',
      createNewExercise: 'Criar um novo exercício',
      addInformationBlock: 'Adicionar bloco de informação',
      selectExerciseFromDb: 'Selecionar exercício do banco',
      thisTestIsInvalid: 'Este teste é inválido',
      youAlreadyCompleted: 'Já concluíu este teste anteriormente',
      deleteTest: 'Apagar a avaliação?',
      deleteTestInfo: 'Tem a certeza que quer apagar esta avaliação?',
      deleteTestInfo2: 'Esta ação não é reversível.',
      successDeleteTest: 'A avaliação foi apagada com sucesso',
      deleteErrorDoesNotExist: 'Não foi possível apagar a avaliação porque não existe',
      timeRemaining: 'Tempo restante',
      finishTest: 'Terminar',
      finishHintConfirm: 'Tens a certeza que queres terminar agora?',
      finishHintIncomplete: 'Ainda existem itens por responder. Tem a certeza que pretende terminar agora?',
      finishTestSuccess: 'Terminado. Bom trabalho!',
      finishTestEnded: 'Já tinha terminado antes',
      finishErrorNetwork: 'Ocorreu um erro ao terminar. Por favor verifique a sua conexão à internet e tente novamente',
      answerError: (num) => `Ocorreu um erro a guardar a resposta ao item ${num}. Por favor tente novamente.`,
      answerErrorNetwork: (num) => `Ocorreu um erro a guardar a resposta ao item ${num}. Por favor verifique a sua conexão à internet e tente novamente.`,
      answered: 'Respondida',
      attemptWindow: 'Tolerância de abertura',
      duration: 'Duração',
      stateScheduled: 'Agendado',
      stateOngoing: 'A decorrer',
      stateFinished: 'Terminado',
      moveUp: 'Mover para cima',
      moveDown: 'Mover para baixo',
      errorMovePublished: 'Não foi possível alterar a ordem porque o teste se encontra publicado. Despublique o teste para efetuar alterações',
      hintMoveNotPossible: 'Mover o exercício nessa direção não é possível',
      removeFromTest: 'Remover do teste',
      removeExerciseFromTest: 'Remover exercício da avaliação',
      removeInformationBlockFromTest: 'Remover bloco de informação da avaliação',
      removeSectionFromTest: 'Remover secção da avaliação',
      hintConfirmRemove: 'Tem a certeza que quer remover este exercício da avaliação?',
      hintConfirmRemoveInformationBlock: 'Tem a certeza que quer remover este bloco de informação da avaliação?',
      hintConfirmRemoveSection: 'Tem a certeza que quer remover esta secção da avaliação?',
      errorRemovePublished: 'Não foi possível remover o exercício porque o teste se encontra publicado. Despublique o teste para efetuar alterações',
      link: 'Link',
      copied: 'Copiado',
      password: 'Password',
      compacted: 'Compacto',
      presentationFull: 'Completo',
      presentationIncremental: 'Incremental',
      navigationLinear: 'Linear',
      navigationNonLinear: 'Não-linear',
      presentationFullTip: 'Todos os itens visíveis ao mesmo tempo',
      presentationIncrementalTip: 'Um item visível de cada vez',
      navigationLinearTip: 'Sem possibilidade de voltar atrás para alterar uma resposta',
      navigationNonLinearTip: 'Com possibilidade de voltar atrás para alterar uma resposta',
      header: {
        exportPhysical: 'Versão física',
      },
      testForm: {
        createTest: 'Criar avaliação',
        editTest: 'Editar teste',
        testName: 'Nome da avaliação',
        errorNameRequired: 'É obrigatório preencher o nome',
        errorSubjectRequired: 'É obrigatório escolher uma disciplina',
        errorGroupRequired: 'É obrigatório escolher um grupo',
        errorNoExercises: 'É obrigatório adicionar pelo menos um exercício',
        successCreateTest: 'O teste foi criado com sucesso',
        successEditTest: 'O teste foi editado com sucesso',
        classificationTypeLabel: 'Tipo de classificação',
        whoCanSee: 'Quem pode ver este teste?',
        privacyTypeLabel: 'Tipo de privacidade',
        classicClassificationTypeTip: 'Os exercícios são pontuados e uma nota final é dada',
        rubricClassificationTypeTip: 'O nível dos alunos é avaliado através dos critérios definidos',
        noClassificationTypeTip: 'Os exercícios não são classificados e não existe nota final',
        testTypeLabel: 'Tipo de avaliação',
        testTypeTip: 'Avaliação agendada para um horário específico, com tempo limite para o aluno resolver',
        worksheetTypeTip: 'Avaliação que fica disponível até à data limite de entrega',
        'public': 'Público',
        publicTip: 'Vísivel na página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visível apenas para si ou para os grupos com os quais partilhar o exercício',
      },
      publishModal: {
        testFormat: 'Formato de avaliação',
        navigationType: 'Tipo de navegação',
        errorTypeRequired: 'É obrigatório escolher o tipo do teste',
        testShuffleLabel: 'Baralhar a ordem dos exercícios',
        shuffleNoneLabel: 'Não baralhar nada',
        shuffleBaseLabel: 'Baralhar as secções e exercícios fora das secções, e manter ordem dos exercícios dentro das secções',
        shuffleSectionsLabel: 'Manter ordem das secções e dos exercícios fora das secções, e baralhar exercícios dentro das secções',
        shuffleAllLabel: 'Baralhar todas as secções, exercícios fora das secções e exercícios dentro das secções',
        publishTest: 'Publicar avaliação',
        unpublishTest: 'Despublicar avaliação',
        rescheduleTest: 'Reagendar avaliação',
        editTest: 'Editar avaliação',
        editPublication: 'Editar publicação',
        successUnpublishTest: 'O teste foi despublicado com sucesso',
        beforePublishing: 'Antes de publicar...',
        labelStartsAt: 'Data e hora de abertura',
        labelEndsAt: 'Data e hora de fecho',
        labelAttemptWindow: 'Tolerância de abertura (em minutos)',
        labelDuration: 'Duração da resolução (em minutos)',
        labelPassword: 'Password',
        labelExitPin: 'Pin de saída',
        hintExitPin: 'Este campo define um pin de saída do teste para os alunos que usarem a aplicação de realização de provas',
        labelTargets: 'Turmas e estudantes',
        hintAttemptWindow: 'Este campo define quantos minutos os alunos têm para iniciar o teste',
        hintTargets: 'Este campo define quais turmas e alunos podem realizar o teste',
        errorStartsAtRequired: 'É obrigatório definir uma data de abertura',
        errorStartsAtPast: 'A data de abertura deve ser no futuro',
        errorEndsAtRequired: 'É obrigatório definir uma data de fecho',
        errorEndsAtBeforeStartsAt: 'A data de fecho deve ser depois da data de abertura',
        errorAttemptWindowRequired: 'É obrigatório definir os minutos de tolerância de abertura',
        errorAttemptWindowBoundaries: 'Este valor deve estar entre 1 e 4320 (3 dias)',
        errorDurationRequired: 'É obrigatório definir os minutos de duração do teste',
        errorDurationBoundaries: 'Este valor deve estar entre 1 e 1440 (1 dia)',
        errorEditDuration: (duration) => `A duração precisa de ser maior que ${duration} minutos`,
        successPublishTest: 'A avaliação foi publicada com sucesso',
        successRescheduleTest: 'O teste foi reagendado com sucesso',
        successEditPublication: 'A publicação foi editada com sucesso',
        publishErrorDoesNotExist: 'Não foi possível publicar o teste porque o teste não existe',
        publishErrorNoLesson: 'Não foi possível publicar o teste porque o teste deve pertencer a uma turma',
        publishErrorNoExercises: 'Não foi possível publicar o teste porque o teste não contém nenhum exercício',
        publishErrorAlreadyPublished: 'Não foi possível publicar o teste porque o teste já estava publicado',
        republishErrorNotPublished: 'Não foi possível reagendar o teste porque o teste não se encontra publicado',
        publishErrorAlreadyStarted: 'Não foi possível alterar a publicação porque o teste já começou ou falta menos de 1 minuto para começar',
        unpublishErrorDoesNotExist: 'Não foi possível despublicar o teste porque o teste não existe',
        unpublishErrorAlreadyUnpublished: 'Não foi possível despublicar o teste porque o teste já se encontra despublicado',
        publishPreHint: 'Antes de ser possível publicar, esta avaliação deve:',
        hintHaveExercises: 'Ter pelo menos 1 exercício',
        errorTargetsRequired: 'É obrigatório escolher pelo menos uma turma ou um aluno',
        hintInformationBlocksShuffle: 'Todos os blocos de informação ficarão no mesmo sítio, apenas os exercícios serão baralhados. Se está a usar um bloco de informação para dar contexto a exercícios seguintes por favor considere desselecionar esta opção.',
        hintHasRubric: 'Ter uma rubrica selecionada',
        hintAreWeightsCorrect: 'Ter o total das ponderações por exercício igual a 100%',
        hintSectionsHaveExercises: 'Todas as secções devem ter pelo menos 1 exercício',
        showRubricLabel: 'Mostrar rubrica aos estudantes durante o teste',
        opensIn: 'Abre em:',
        endsIn: 'Termina em:',
        scheduledFor: 'Agendado para',
        openedOn: 'Abriu a',
        endedOn: 'Terminou a',
        endsOn: 'Termina a',
        immediateFeedbackLabel: 'Feedback imediato: estudante pode ver o seu resultado e as soluções imediatamente após o teste terminar',
        modelAnswerLabel: 'Resposta modelo: estudante pode ver a resposta modelo após o teste terminar',
        linearNavigation: 'Navegação linear: estudante não pode voltar a itens anteriores',
        nonLinearNavigation: 'Navegação não-linear: estudante pode voltar a itens anteriores',
        createAttempts: 'Gerar provas',
        successCreateAttempts: 'As provas foram geradas com sucesso',
      },
      grades: {
        noAttempt: 'Sem submissão',
        point: 'Ponto',
        points: 'Pontos',
        defineGrade: 'Definir pontuação',
        assignGrade: 'Atribuir pontuação',
        maxValueExceeded: 'O valor da nota não pode exceder o valor máximo definido',
        notEmpty: 'O valor da nota não pode estar vazio',
        noStudents: 'De momento, não há estudantes atribuídos a esta avaliação.',
        maxValueLabel: 'Máximo:',
        negativeValueError: 'Os pontos atribuídos a um exercício não podem ser negativos',
        testOf: 'Teste de',
        generateGrades: 'Gerar notas',
        successUpdatePoints: 'Os pontos do exercício foram atualizados com sucesso!',
        successUpdatePointsWarn: 'Os pontos do exercício foram atualizados com sucesso! Não se esqueça de gerar as notas de novo',
        pointsTooHigh: 'A cotação atribuída ao exercício é demasiado alta!',
        studentName: 'Nome do estudante',
        noAnswersYet: 'Ainda nenhum aluno realizou a avaliação para poder gerar notas',
        submission: 'Submissão',
        successUpdateGrade: 'A nota foi atualizada com sucesso!',
        submissionLink: 'Link da submissão',
        publishCorrections: 'Publicar correções',
        correctionsPublished: 'Correções publicadas',
        exportResolutions: 'Exportar resoluções',
        addComment: 'Adicionar comentário',
        successUpdateFeedback: 'O comentário foi atualizado com sucesso!',
        unavailable: 'Ficará disponível quando o teste terminar',
        unavailableStudent: 'Ficará disponível quando o aluno terminar o teste',
        publishCorrectionsInformation: 'Um e-mail será enviado para cada aluno notificando que a avaliação deles já foi corrigida. O e-mail irá conter um link onde eles poderão ver o teste e comentários.',
        successUpdateCriteriaGrade: 'A nota do critério foi atualizada com sucesso',
        criteriaGradeNotEmptyError: 'A nota do critério não pode estar vazia',
        criteriaGradeNegativeValueError: 'A nota do critério não pode ser negativa',
        criteriaGradeTooHighError: 'A nota do critério não pode ser maior que 5',
        exportAttemptsCSV: 'Exportar resoluções CSV',
        exportGrades: 'Exportar notas Excel',
        deleteAttemptUnavailable: 'Este aluno ainda não começou a avaliação.',
        deleteAttemptConfirm: (name) => `Tem a certeza que quer apagar a submissão de ${name}?`,
        deleteAttemptConfirmSubtitle: 'Esta ação não é reversível.',
        successDeleteAttempt: 'A submissão foi apagada com sucesso.',
        studentSearch: 'Pesquisar por alunos',
        noStudentsFound: 'Sem resultados',
        showModelAnswer: 'Resposta modelo: o estudante pode ver a resposta modelo ao rever a avaliação',
        notFinished: 'Não terminado',
        attemptDates: 'Início - Conclusão',
        annotate: 'Anotar',
        saveAnnotation: 'Guardar anotações',
        comment: 'Comentar',
        saveComment: 'Guardar',
        commentBoxPlaceholder: 'Adicione um comentário...',
      },
      stats: {
        classAverage: 'Média',
        classMedian: 'Mediana',
        averageInfo: 'A média é calculada através da soma do resultado de todos os testes e posterior divisão pelo número total de testes',
        medianInfo: 'A mediana é o valor que se encontra no centro quando se ordenam os resultados dos testes do menor para o maior',
        positive: 'Positivas',
        negative: 'Negativas',
        highest: 'Nota mais alta',
        lowest: 'Nota mais baixa',
        question: 'Pergunta',
        sortOrder: 'Ordem: ',
        lowToHigh: 'Ascendente',
        highToLow: 'Descendente',
        avrgGrade: 'Nota média',
        avrgNormalized: 'Nota média normalizada',
        avrgNormalizedInfo: 'Ordenar as perguntas em ordem descendente ou ascendente pela nota média normalizada',
        avrgQuestionInfo: 'A nota média obtida nesta pergunta',
        premiumStats: 'Obtenha uma assinatura premium para ver estatísticas mais avançadas',
        bestQuestion: 'Pergunta com melhores respostas',
        worstQuestion: 'Pergunta com piores respostas',
        bestQuestionShort: 'Melhor pergunta',
        worstQuestionShort: 'Pior pergunta',
        noAnswer: 'Sem resposta',
        distributionChart: 'Número de resultados',
        choices: 'Escolha múltipla',
        ascSort: 'Clique para organizar por ordem ascendente',
        descSort: 'Clique para organizar por ordem descendente',
        cancelSort: 'Clique para cancelar a organização',
        fastestTime: 'Tempo mais rápido',
        slowestTime: 'Tempo mais lento',
        averageTime: 'Tempo médio',
        inProgress: 'Ainda a decorrer...',
        studentName: 'Nome do estudante',
        studentGrade: 'Classificação',
        studentRank: 'Posição',
        previewAnswer: 'Pré-visualizar resposta',
        studentCount: 'Número de estudantes',
        gradeCount: 'Número de resultados',
        gradeCountTip: 'Nos intervalos representados, como 0% a 10%, o limite inferior (0%) é contabilizado na barra e o limite superior não, com a exceção do último intervalo, 90% a 100%, que inclui ambos.',
      },
      physicalVersion: {
        physicalVersion: 'Versão Física',
        exportTest: 'Exportar avaliação',
        generalSettings: 'Configurações gerais',
        versionsLabel: 'Número de versões',
        versionsPlaceholder: 'Inserir o número de versões',
        localeLabel: 'Linguagem',
        shuffleExercisesLabel: 'Baralhar ordem dos exercícios',
        shuffleChoicesLabel: 'Baralhar ordem das escolhas dos exercícios de escolha múltipla',
        noExercises: 'O teste precisa de ter pelo menos um exercício para ser exportado',
        notEnoughExercises: 'Não existe exercícios suficientes para satisfazer o número de versões.',
        headerSettings: 'Configuração do cabeçalho',
        studentInput: 'Estudante a preencher',
        testName: 'Nome da avaliação',
        testNamePlaceholder: 'Insira aqui o nome da avaliação',
        testNameEmptyError: 'O nome da avaliação não pode estar vazio',
        displayVersion: 'Mostrar versão da avaliação',
        schoolName: 'Nome da escola',
        schoolNamePlaceholder: 'Insira aqui o nome da escola',
        schoolNameEmptyError: 'O nome da escola não pode estar vazio',
        schoolLogo: 'Logotipo da escola',
        schoolLogoPlaceholder: 'Arraste para aqui ou clique para inserir a imagem',
        schoolLogoEmptyError: 'Deve inserir uma imagem do logotipo da escola',
        subject: 'Disciplina',
        subjectPlaceholder: 'Insira aqui o nome da disciplina',
        subjectEmptyError: 'O nome da disciplina não pode estar vazio',
        testDuration: 'Duração da avaliação (em minutos)',
        testDurationPlaceholder: 'Insira aqui a duração da avaliação',
        testDurationEmptyError: 'A duração do teste não pode estar vazia',
        schoolYear: 'Ano escolar',
        schoolYearPlaceholder: 'Insira aqui o ano escolar',
        schoolYearEmptyError: 'O ano escolar não pode estar vazio',
        'class': 'Turma',
        classPlaceholder: 'Insira aqui a turma',
        classEmptyError: 'O nome da turma não pode estar vazio',
        date: 'Data da avaliação',
        datePlaceholder: 'Insira aqui a data da avaliação',
        dateEmptyError: 'A data da avaliação não pode estar vazia',
        exercisesSettings: 'Configurações de exercícios',
        includeZone: 'Incluir zona de resposta',
        askForName: 'Nome do aluno',
        askForCode: 'Código do aluno',
        askForGrade: 'Nota do aluno',
        extraFields: 'Campos extra',
        extraFieldButton: 'Adicionar campo novo',
        extraFieldLabel: 'Nome do campo:',
        extraFieldLabelPlaceholder: 'Insira aqui o nome do campo',
        customFieldEmptyLabelError: 'Deve inserir o nome do campo',
        extraFieldContent: 'Conteúdo do campo:',
        extraFieldContentPlaceholder: 'Insira aqui o conteúdo do campo',
        customFieldEmptyContentError: 'Deve inserir o conteúdo do campo',
        numberOfLines: 'Número de linhas para resposta',
        numberOfLinesJustify: 'Número de linhas para justificação',
        showLines: 'Mostrar linhas',
        includeRubric: 'Incluir rubrica nas versões exportadas',
        noRubric: 'Ainda não selecionaste nenhuma rubrica',
        sectionWithoutExercises: 'Esta secção não tem exercícios',
      },
      attemptsExport: {
        includeSchoolLogo: 'Incluir logotipo da escola',
        schoolLogoPlaceholder: 'Arraste para aqui ou clique para inserir a imagem',
        schoolLogoEmptyError: 'Deve inserir uma imagem do logotipo da escola',
        includeGrade: 'Incluir nota',
        includeRubric: 'Incluir rubrica',
        waitWhileGenerateDocuments: 'Aguarde enquanto geramos os documentos',
        clickBelowToDownloadDocuments: 'Clique abaixo para descarregar os documentos',
        exportAttemptsPDF: 'Exportar provas PDF',
        generatingPDFs: 'A gerar os PDFs',
        zippingPDFs: 'A criar o ficheiro zip',
        generateSuccessDescription: 'As provas foram exportadas para PDF com sucesso! Clique no botão abaixo para fazer download.',
        includeModelAnswer: 'Incluir resposta modelo',
        exportAttemptsNotAvailable: 'Não existem provas para exportar',
        downloadAttempts: 'Descarregar provas',
        downloadValidatedExplanation: 'Apenas as provas que foram validadas vão ser exportadas',
      },
      send: {
        sendTest: 'Enviar avaliação',
        keepCopy: 'Manter original e enviar uma cópia',
        successSendTest: 'A avaliação foi enviada com sucesso',
        errorSendTestGroup: 'Tem de escolher um grupo para mandar a avaliação.',
        errorSendTestSchool: 'Tem de escolher um espaço para mandar a avaliação.',
        errorSendTestToMySpace: 'Não pode enviar uma avaliação de outro utilizador para o seu espaço pessoal.',
      },
      settingsModal: {
        name: 'Nome',
        successEditSettings: 'As definições do teste foram atualizadas com sucesso',
      },
      rubric: {
        addRubric: 'Adicionar rubrica',
        selectRubricFromBank: 'Selecionar rubrica do banco',
        successSetRubric: 'A rubrica foi adicionada com sucesso',
        changeRubric: 'Alterar rubrica',
        noRubric: 'Ainda não há nenhuma rubrica associada a este teste',
        totalPonderations: 'Ponderação total dos critérios da rubrica',
        notEmpty: 'A ponderação do critério no exercício não pode estar vazia',
        negativeValueError: 'A ponderação do critério no exercício não pode ter um valor negativo',
        weightTooHigh: 'A ponderação do critério no exercício não pode estar acima de 100%',
        successUpdateCriteriaWeight: 'A ponderação do critério no exercício foi alterada com sucesso',
        errorTotal: 'O total das ponderações do exercício deve ser igual a 100%',
        createRubric: 'Criar nova rubrica',
        editRubric: 'Editar rubrica',
        successEditRubric: 'A rubrica foi alterada com sucesso',
      },
      exercises: {
        addSection: 'Adicionar secção',
        sectionNameLabel: 'Nome da secção',
        sectionDescriptionLabel: 'Descrição da secção',
        sectionNameRequiredError: 'O nome da secção é obrigatório',
        addSectionSuccessful: 'A secção foi adicionada ao teste com sucesso',
        editSectionSuccessful: 'A secção foi alterada com sucesso',
        sectionPresentation: 'Apresentação da secção',
        sectionNavigation: 'Navegação da secção',
        sectionTimerLabel: 'Tempo da secção em minutos (opcional)',
        sectionTimerWarning: 'O tempo de secção só funciona em testes incrementais',
        pauseItem: 'Item de pausa',
        addPauseItem: 'Adicionar item de pausa',
        addPauseItemStepHeader: 'Mensagem de pausa',
        addPauseItemPlaceholder: 'Escreva aqui a mensagem de pausa',
        successAddPauseItem: 'O item de pausa foi criado com sucesso',
        editPublishedExerciseWarning: 'Após a publicação da avaliação, algumas opções não poderão ser editadas e ao guardar as alterações, o exercício será automaticamente corrigido e quaisquer notas anteriores serão substituídas.',
        editPointsSuccess: 'Os pontos do exercício foram atualizados com sucesso',
        editPointsSuccessAfterPublish: 'Os pontos do exercício foram atualizados com sucesso. As notas podem demorar a atualizar',
        editWeightsSuccess: 'Os pesos dos critérios do exercício foram atualizados com sucesso',
        editWeightsSuccessAfterPublish: 'Os pesos dos critérios do exercício foram atualizados com sucesso. As notas podem demorar a atualizar',
      },
    },
    exerciseForm: {
      createExerciseOf: 'Criar exercício de',
      editExerciseOf: 'Editar exercício de',
      editExercise: 'Editar exercício',
      selectExerciseType: 'Selecione o tipo de exercício',
      typeChoices: 'Escolha múltipla',
      typeTrueFalse: 'Verdadeiro / Falso',
      typeText: 'Texto',
      typeImage: 'Submissão de imagens',
      typeFilling: 'Preencher o texto',
      typeConnecting: 'Conectar',
      typeOrdering: 'Ordenar',
      typeCaption: 'Legendar',
      typeSegmentation: 'Segmentos',
      typeTable: 'Tabela',
      typeSmartChoices: 'Escolha múltipla inteligente',
      newChoices: 'Novo exercício de escolha múltipla',
      newTrueFalse: 'Novo exercício de verdadeiro / falso',
      newText: 'Novo exercício de texto',
      newImage: 'Novo exercício de submissão de imagens',
      newSmartChoices: 'Novo exercício de escolha múltipla inteligente',
      editChoices: 'Editar exercício de escolha múltipla',
      editTrueFalse: 'Editar exercício de verdadeiro / falso',
      editText: 'Editar exercício de texto',
      editImage: 'Editar exercício de submissão de imagens',
      editSmartChoices: 'Editar exercício de escolha múltipla inteligente',
      discardTypeTitle: 'Descartar exercício',
      discardTypeContent: 'Se continuar, todas as adições serão perdidas',
      saveWarningContent: 'Ao guardar, o exercício será automaticamente corrigido e quaisquer notas anteriores serão substituídas.',
      statement: 'Enunciado',
      statementPlaceholder: 'Escreva aqui o enunciado',
      statementPlaceholderInformation: 'Escreva aqui a informação',
      noEmptyStatement: 'O enunciado não pode estar vazio',
      noEmptyFormula: 'Não é possível inserir uma fórmula vazia',
      noInvalidFormula: 'A fórmula parece estar inválida. Se tem espaços no inicio ou no fim, remova. Por favor corrija antes de continuar',
      trueFalse: 'Verdadeiro / falso',
      noEmptyTruth: 'É necessário definir um valor de verdade',
      choices: 'Escolhas',
      choicesTip: 'Coloque as diferentes opções e escolha a opção correta carregando no botão de verificação à esquerda da opção.',
      oneCorrectOption: 'Como publicou a avaliação com o exercício tendo apenas uma escolha correta, deve manter apenas uma escolha correta.',
      multipleCorrectOptions: 'Como publicou a avaliação com o exercício tendo mais que uma escolha correta, deve manter pelo menos duas opções corretas.',
      itemstoOrder: 'Itens a ordenar',
      itemsToOrderTip: 'Os itens devem estar ordenados na ordem que considera como correta. Os itens serão depois apresentados de forma aleatória para os estudantes.',
      option: 'Opção',
      addOption: 'Adicionar nova opção',
      removeOption: 'Remover opção',
      noEmptyOption: 'Não pode haver opções vazias',
      noDuplicateOptions: 'Não pode haver opções duplicadas',
      noAnswerChoiceDefined: 'É necessário definir a opção correta',
      editMultipleOptionFail: 'É necessário definir pelo menos duas opções corretas',
      item: 'Item',
      addItem: 'Adicionar novo item',
      removeItem: 'Remover item',
      noEmptyItem: 'Não pode haver itens vazios',
      noDuplicateItems: 'Não pode haver itens duplicados',
      noAnswerGapDefined: 'É necessário definir pelo menos uma palavra a esconder',
      noExtraWordDefined: 'É necessário definir pelo menos uma opção extra para cada palavra selecionada',
      previewAndSave: 'Pré-visualizar e guardar',
      saveExercise: 'Guardar exercício',
      successExerciseCreated: 'Exercício criado com sucesso',
      successExerciseEdited: 'Alterações guardadas com sucesso',
      successExerciseEditedAfterPublish: 'Alterações guardadas com sucesso. As notas podem demorar a atualizar',
      advancedMode: 'Modo LaTeX',
      formulaHeader: 'Adicionar fórmula',
      formulaLabel: 'Escreva aqui a tua fórmula',
      formulaReference: 'Referência de fórmulas suportadas',
      formulaSectionGeneral: 'Geral',
      formulaSectionMath: 'Matemática',
      videoHeader: 'Adicionar vídeo',
      youtube: 'Vídeo do Youtube',
      youtubeLink: 'Link do youtube',
      invalidUrl: 'Apenas são aceites links de videos do Youtube',
      noVideoChosen: 'Não inseriu nenhum link',
      myVideo: 'O meu vídeo',
      addVideoLabel: 'Clique para adicionar um vídeo',
      justificationLabel: 'Justificação',
      justificationText: 'Adicionar espaço para justificação',
      askForImage: 'Imagem',
      askForImageError: 'Tipo de ficheiro errado! Por favor insira um ficheiro de imagem',
      caption: 'Legenda',
      createCaptions: 'Crie as legendas',
      editCaptions: 'Edite as legendas',
      createCaptionsDescription: 'Clique na imagem para adicionar uma legenda. Pode arrastar as legendas para não obstruir a imagem.',
      editCaptionsDescription: 'Clique na legenda para editar o texto.',
      captionWarning: 'Aviso: Este exercício tem melhor experiência ao responder no computador.',
      shortAnswer: 'Resposta',
      shortAnswerText: 'Tornar em exercício de resposta curta',
      characterLimit: 'Limite de caracteres',
      characterLimitError: 'Especifique o limite de caracteres',
      extraText: 'Texto extra',
      extraTextPlaceholder: 'Insira aqui o texto extra',
      extraTextStartOpen: 'Começar totalmente expandido',
      identifiers: 'Identificadores',
      useMathSymbols: 'Utilizar símbolos matemáticos (Aluno)',
      wordCountLabel: 'Mostrar contagem de palavras (Aluno)',
      exerciseSettings: {
        label: 'Definições de exercício',
        title: 'Título do exercício',
        whoCanSee: 'Quem pode ver este exercício?',
        areas: 'Quais as áreas disciplinares relevantes para este exercício?',
        years: 'Em que anos escolares?',
        errorTitleRequired: 'É necessário escolher um título',
        errorSubjectRequired: 'É necessário escolher uma área',
        errorYearRequired: 'É necessário escolher um ano',
        'public': 'Público',
        publicTip: 'Vísivel na página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visível apenas para si ou para os grupos com os quais partilhar o exercício',
      },
      gap: {
        choosingGaps: 'Escolher as palavras a esconder',
        choosingGapsSub: 'Selecione as palavras que pretende esconder dos alunos',
        noGaps: 'Ainda não existem palavras no enunciado',
        choosingGapExtraOption: 'Opções de exercício (lado do aluno)',
        dragDropOption: 'Mostrar a lista de palavras escondidas (arrastar)',
        dropdownOption: 'Mostrar a lista de opções para cada palavra (escolher)',
        writeOption: 'Não mostrar a lista de palavras (escrever)',
        example: 'Exemplo',
        write: 'Escrever...',
        correctionAuto: 'Correção automática pela plataforma',
        correctionManual: 'Correção feita pelo professor',
        askForExtraGapsButton: 'Guardar palavras extra',
        addExtraGaps: 'Adicionar palavras extra',
        editExtraGaps: 'Editar palavras extra',
        addExtraGapsSub: 'Escrever palavras extra para adicionar dificuldade a este exercício',
        editExtraGapsSub: 'Editar palavras extra para adicionar dificuldade a este exercício',
        addGapOptions: 'Adicionar opções para a palavra: ',
        editGapOptions: 'Editar opções para a palavra: ',
        word: 'Palavra',
        orderGaps: 'Ordenar palavras',
        noExtraGaps: 'Não existem palavras extra para editar',
      },
      choice: {
        shuffleChoices: 'Baralhar escolhas múltiplas',
      },
      connecting: {
        shuffleConnectors: 'Baralhar conectores',
        connectors: 'Conectores',
        connector: 'Conector',
        connections: 'Conexões',
        addConnector: 'Adicionar conector',
        noEmptyConnector: 'Não pode existir conectores vazios',
        noConnections: 'É obrigatório existir pelo menos uma conexão',
        noConnectors: 'É obrigatório existirem pelo menos dois conectores',
        noConnectorsLeft: 'É obrigatório existir pelo menos um conector na coluna da esquerda',
        noConnectorsRight: 'É obrigatório existir pelo menos um conector na coluna da direita',
      },
      segmentation: {
        createConnectorsAndConnections: 'Criar conectores e conexões',
        createConnectorsAndConnectionsDescription: 'Clique na imagem para adicionar um conector. Pode adicionar conexões clicando nos dois conectores que pretende conectar.',
        showOnlyLine: 'Mostrar apenas linha',
      },
      table: {
        tableGrading: 'Opções de correção',
        gradeByRow: 'Por linha',
        gradeByTable: 'Por tabela',
        gradeByRowTip: 'O exercício será corrigido por linha, o que significa que o aluno deve responder corretamente em todas as colunas da linha, selecionando-a ou não, caso contrário, a linha é considerada incorreta.',
        gradeByTableTip: 'O exercício será corrigido por tabela, o que significa que cada célula deve ser respondida corretamente, caso contrário, o exercício é considerado incorreto.',
        noEmptyHeaderCell: 'Não podem existir células de cabeçalho principais vazias',
        headerPlaceholder: 'Cabeçalho',
        rowGradingExplanation: 'Corrigido por linha',
        tableGradingExplanation: 'Corrigido por tabela',
      },
      audio: {
        audioHeader: 'Adicionar áudio',
        addAudioLabel: 'Clique para adicionar um áudio',
        askForAudioError: 'Tipo de ficheiro errado! Por favor insira um ficheiro de áudio',
        noAudioChosen: 'Não escolheu nenhum ficheiro de áudio',
        canPauseLabel: 'O estudante pode parar a reprodução do áudio',
        canPause: 'Pode parar',
        cannotPause: 'Não pode parar',
        limitRepetitionsLabel: 'Limitar a quantidade de vezes que o estudante pode ouvir o áudio',
        maxRepetitionsLabel: 'Repetições máximas',
        repetitions: 'repetições',
        retryMessage: 'Ocorreu um erro. Volta a tentar reproduzir o audio',
      },
      video: {
        audioHeader: 'Adicionar vídeo',
        addAudioLabel: 'Clique para adicionar um vídeo',
        askForAudioError: 'Tipo de ficheiro errado! Por favor insira um ficheiro de vídeo',
        noAudioChosen: 'Não escolheu nenhum ficheiro de vídeo',
        canPauseLabel: 'O estudante pode parar a reprodução do vídeo',
        canPause: 'Pode parar',
        cannotPause: 'Não pode parar',
        limitRepetitionsLabel: 'Limitar a quantidade de vezes que o estudante pode ver o vídeo',
        maxRepetitionsLabel: 'Repetições máximas',
        repetitions: 'repetições',
      },
      modelAnswer: 'Resposta Modelo',
      modelAnswerPlaceholder: 'Insira aqui a resposta modelo',
    },
    exercises: {
      title: 'Banco de exercícios',
      subtitle: 'Todos os exercícios criados',
      createNewExercise: 'Criar exercício',
      createNewExerciseSubtitle: 'Aqui iremos criar um novo exercício',
      cancelCreation: 'Cancelar criação',
      cancelEdit: 'Cancelar edição',
      deleteExercise: 'Apagar exercício',
      deleteExercises: 'Apagar exercícios',
      deleteExerciseInfo: 'Tem a certeza que quer apagar este exercício?',
      deleteExercisesInfo: 'Tem a certeza que quer apagar estes exercícios?',
      deleteExerciseInfo2: 'Esta ação não é reversível',
      successDeleteExercise: 'O exercício foi removido com sucesso',
      successDeleteExercises: 'Os exercícios foram removidos com sucesso',
      successDuplicateExercise: 'O exercício foi duplicado com sucesso',
      deleteErrorDoesNotExist: 'Não foi possivel remover o exercício porque ele já não existe',
      creatingNewExerciseOn: 'A criar novo exercício em',
      editingExercise: 'A editar exercício em',
      previewExercise: 'Pré-visualizar exercício',
      previewErrorDoesNotExist: 'Não foi possível carregar a pré-visualização porque o exercício já não existe',
      noExercises: 'Ainda não existe nenhum exercício criado.',
      noExercisesForFilters: 'Não existem exercícios para os filtros selecionados.',
      gapList: 'Lista de palavras/expressões:',
      gapListUnused: 'Lista de palavras/expressões não utilizadas:',
      labels: {
        addLabel: 'Adicionar etiqueta',
        createLabel: 'Criar etiqueta',
        repeatedLabelError: 'Já existe uma etiqueta com esse nome',
        labelName: 'Nome da etiqueta',
        errorNoColorSelected: 'É obrigatório selecionar uma cor',
        errorNameRequired: 'É obrigatório preencher o nome',
        labels: 'Etiquetas',
        editLabels: 'Editar etiquetas',
        successEditLabel: 'A etiqueta foi editada com sucesso',
        successDeleteLabel: 'A etiqueta foi eliminada com sucesso',
      },
      filters: 'Filtros',
      teacherFilter: 'Professores',
      typeFilter: 'Tipo de exercícios',
      order: 'Ordenar',
      orderNewest: 'Mais recente',
      orderOldest: 'Mais antigo',
      orderLastModified: 'Último modificado',
      send: {
        sendExercise: 'Enviar exercício',
        sendExercises: 'Enviar exercícios',
        keepCopy: 'Manter original e enviar uma cópia',
        sendLabels: 'Enviar as etiquetas para o outro grupo',
        successSendExercise: 'O exercício foi enviado com sucesso',
        successSendExercises: 'O exercícios foram enviados com sucesso',
        errorSendExerciseGroup: 'Tem de escolher um grupo para enviar o exercício.',
        errorSendExercisesGroup: 'Tem de escolher um grupo para enviar os exercícios.',
        errorSendExercisesSchool: 'Tem de escolher um espaço para enviar os exercícios.',
        errorSendExercisesToMySpace: 'Não pode enviar exercícios de outros utilizadores para o seu espaço pessoal.',
      },
      modelAnswer: 'Resposta modelo',
    },
    attempt: {
      nextExercise: 'Próximo exercício',
      nextItem: 'Próximo',
      previousExercise: 'Exercício anterior',
      previousItem: 'Anterior',
      finishedAttempt: 'Terminado. Pode fechar a janela.',
      finishedAttemptIave: 'Prova terminada. Aguarda instruções do professor vigilante.',
      characterLimitReached: 'Número máximo de caracteres atingido',
      timesUp: 'O tempo da secção acabou',
      unsavedAnswers: 'Ocorreu um erro ao gravar a(s) sua(s) resposta(s). Verifique a sua ligação à internet e tente gravar',
      unsavedAnswersFinish: 'Tem respostas que não foram gravadas. Tem a certeza que quer terminar?',
      answersSaved: 'As respostas foram gravadas com sucesso',
      errorSaveAnswers: 'Ocorreu um erro ao guardar as respostas',
      exitPinModal: {
        pinLabel: 'Por favor insira o pin de saída do exame disponibilizado pelo supervisor',
        pinPlaceholder: 'Pin',
        modalHeader: 'Pin de saída de exame',
        wrongPin: 'O pin não está correto',
        emptyPin: 'Deve inserir um pin para proceder',
      },
      oops: 'Ocorreu um erro ao gravar a(s) sua(s) resposta(s). Verifique a sua ligação à internet e tente gravar',
      continueWithoutSaving: 'Continuar sem gravar',
      saveAndContinue: 'Gravar e continuar',
      status: 'Estado',
      statusValid: 'Válido',
      statusInvalid: 'Inválido',
      statusPending: 'Pendente',
      statusUpdated: 'Atualizado com sucesso',
    },
    groups: {
      title: 'Grupos',
      teachers: 'professores',
      teacher: 'professor',
      createNewGroup: 'Criar grupo',
      groupName: 'Nome do grupo',
      successCreateGroup: 'O grupo foi criado com sucesso',
      hintTargets: 'Este campo define quais os professores que vão ser adicionados ao grupo',
      errorNameRequired: 'É obrigatório preencher o nome',
      noGroups: 'Ainda não tens grupos? Crie um e comece a colaborar com outros professores!',
      successEditGroup: 'O grupo foi editado com sucesso',
      userNotAllowed: 'O utilizador já excedeu o limite de grupos em que pode estar',
    },
    group: {
      title: 'Grupo',
      leaveGroup: 'Sair do grupo',
      leaveGroupInfo: 'Tem a certeza que quer sair do grupo?',
      leaveGroupInfo2: 'Esta ação não é reversível e vai perder o acesso a todos conteúdos do grupo.',
      successLeaveGroup: 'Saíu do grupo com sucesso',
      removeTeacher: 'Remover professor?',
      removeTeacherInfo: 'Tem a certeza que quer remover este professor?',
      successRemoveTeacher: 'O professor foi removido com sucesso',
      successAddTeacher: 'Os professores foram adicionados com sucesso',
      addTeacher: 'Adicionar professores',
      hintTargets: 'Insira os e-mails dos professores que quer adicionar ao grupo',
      hintTargetsCreatable: 'Insira os e-mails dos professores que quer adicionar ao grupo. Pode adicionar e-mails de professores que não estejam na Intuitivo para os convidar.',
      groupSettings: 'Definições do grupo',
      deleteGroup: 'Eliminar grupo',
      deleteGroups: 'Eliminar grupos',
      deleteGroupInfo: 'Tem a certeza que quer eliminar este grupo? Depois não poderá recuperar os conteúdos. Esta ação não é reversível.',
      deleteGroupsInfo: 'Tem a certeza que quer eliminar estes grupos? Depois não poderá recuperar os conteúdos. Esta ação não é reversível.',
      successDeleteGroup: 'O grupo foi eliminado com sucesso',
      removeSelected: 'Remover selecionados',
    },
    publication: {
      password: 'Password do teste',
      testWindowClosedInfo: 'A tolerância de abertura do teste terminou.',
      studentName: 'Nome de estudante',
      errorNameRequired: 'É obrigatório preencher o nome.',
      errorEmail: 'O e-mail inserido não é válido.',
      passwordRequired: 'É obrigatório preencher a password.',
      emailHint: 'O e-mail inserido será utilizado para depois enviar a nota e correção mal estas sejam publicadas.',
      continueAsGuest: 'Continuar como convidado',
      continueAs: (name) => `Continuar como ${name}`,
      notYou: 'Não é o seu nome?',
      enterAsNewStudent: 'Entre como um novo estudante',
    },
    rubrics: {
      title: 'Rubricas',
      noRubrics: 'Ainda não existe nenhuma rubrica criada',
      previewRubric: 'Visualizar rubrica',
      performanceLevels: 'Níveis de desempenho',
      createRubric: 'Criar rubrica',
      editRubric: 'Editar rubrica',
      deleteRubric: 'Eliminar rubrica',
      deleteRubrics: 'Eliminar rubricas',
      deleteRubricInformation: 'Tem a certeza que quer eliminar esta rubrica? Esta ação não é reversível.',
      deleteRubricsInformation: 'Tem a certeza que quer eliminar estas rubricas? Esta ação não é reversível.',
      successDeleteRubric: 'A rubrica foi eliminada com sucesso',
      successDeleteRubrics: 'As rubricas foram eliminadas com sucesso',
      successDuplicateRubric: 'A rubrica foi duplicada com sucesso',
      form: {
        rubricNameLabel: 'Nome da rubrica',
        rubricDescriptionLabel: 'Descrição da rubrica',
        criteriaNameLabel: 'Nome do critério',
        criteriaDescriptionLabel: 'Descrição do critério',
        performanceLevelDescriptionLabel: 'Descrição do nível de desempenho',
        addCriteria: 'Adicionar critério',
        discardRubric: 'Descartar rubrica',
        nameRequiredError: 'É necessário inserir um nome para a rubrica.',
        criteriaNameRequiredError: 'É necessário inserir um nome para o critério.',
        criteriaRequiredError: 'É necessário criar pelo menos um critério para esta rubrica.',
        createRubricSuccess: 'A rubrica foi criada com sucesso',
        editRubricSuccess: 'A rubrica foi atualizada com sucesso',
      },
      send: {
        sendRubric: 'Enviar rubrica',
        sendRubrics: 'Enviar rubricas',
        keepCopy: 'Manter original e enviar uma cópia',
        successSendRubric: 'A rubrica foi enviada com sucesso',
        successSendRubrics: 'As rubricas foram enviadas com sucesso',
        errorSendRubricGroup: 'Tem de escolher um grupo para enviar a rubrica.',
        errorSendRubricsGroup: 'Tem de escolher um grupo para enviar as rubricas.',
        errorSendRubricsSchool: 'Tem de escolher um espaço para enviar as rubricas.',
        errorSendRubricsToMySpace: 'Não pode enviar rubricas de outros utilizadores para o seu espaço pessoal.',
      },
    },
    explore: {
      title: 'Explorar',
      header: 'Explore os conteúdos disponíveis',
      headerXs: 'Explorar',
      contentsListHeader: 'Conteúdos mais utilizados, consoante os filtros selecionados',
      contentsListHeaderLg: 'Conteúdos mais utilizados',
      contentsListHeaderXs: 'Conteúdos',
      noResultsHeader: 'Oops... Não encontramos nenhum conteúdo que corresponda à sua pesquisa :( ',
      noResultsDescription: 'Tente pesquisar por um termo mais geral, mude os filtros ou verifique se há alguma gralha nas palavras pesquisadas.',
      noResultsDescription2: 'Se essas dicas não funcionarem, convidamo-lo a criar novo conteúdo.',
      createNewExercise: 'Criar exercício',
      endMessage: 'Já viu todos os conteúdos que correspondem à sua pesquisa.',
      scrollBackToTopMessage: 'Voltar ao topo da página',
      searchPlaceholder: 'Pesquisar conteúdos na Intuitivo...',
      addFilter: 'Adicionar filtro',
      subjectAreas: 'Áreas disciplinares',
      schoolYears: 'Anos escolares',
      numberOfUses: 'Utilizações',
      copyInformation: 'Para que grupo gostaria de copiar este exercício?',
      successCopyExercise: 'O exercício foi copiado para o seu grupo com sucesso',
      infoTip: 'Preencha as suas preferências na página do seu perfil para uma experiência mais personalizada',
      searchAll: 'Todos os conteúdos',
    },
    profilePreferences: {
      setProfilePreferences: 'Antes de continuar, ajude-nos a melhorar a sua experiência',
      saveProfilePreferences: 'As preferências foram guardadas com sucesso',
      warningProfilePreferences: 'Para alterar as suas preferências vá à página "Conta"',
      subjects: 'Que áreas disciplinares leciona?',
      years: 'Em que anos escolares?',
    },
    login: {
      loginWithMicrosoft: 'Iniciar sessão com a Microsoft',
      poweredBy: 'Powered by',
    },
    tours: {
      tip: 'Guias',
      title: 'Precisa de ajuda?',
      startTour: 'Começar',
      subtitles: [
        'Aqui poderá encontrar vários guias que o irão ajudar a interagir com a Intuitivo.',
        'Para começar, basta selecionar um dos guias disponíveis abaixo.',
      ],
      back: 'Voltar atrás',
      next: 'Próximo',
      complete: 'Terminar',
      introduction: {
        title: 'Introdução',
        subtitle: 'Aprenda os básicos da Intuitivo.',
        steps: {
          introduction: 'Bem vindo à Intuitivo! Preparamos um guia para o ajudar a começar a usar a plataforma. Para continuar basta carregar no botão "Próximo". Se está familiarizado com a Intuitivo, pode fechar esta mensagem.',
          testCard: 'Para o ajudarmos na utilização da Intuitivo, criámos uma avaliação para explorar.',
          testCardPublishButton: 'As avaliações já publicadas estão identificadas com este ícone a verde. Caso ainda não tenha sido publicada, estará a cinzento escuro.',
          testCardLeft: 'Veja mais detalhes desta avaliação clicando no cartão.',
          tabExercises: 'Neste momento está na aba de "Exercícios". Aqui pode ver os exercícios que constituem a avaliação.',
          tabGrade: 'Para este guia, a avaliação já foi publicada e obteve 2 submissões. Selecione a aba "Correção" para ver as submissões dos alunos.',
          statistics: 'Aqui pode ver estatísticas gerais da correção da avaliação.',
          attempts: 'Nesta secção pode ver quais os alunos que submeteram a avaliação, assim como a sua nota. Ao clicar num nome, poderá ver a sua resolução.',
          attemptContainer: 'Esta secção contém toda a informação relativa a uma resolução individual.',
          attemptHeader: 'O cabeçalho de cada resolução contém informação do aluno, bem como a sua nota final e link de submissão.',
          multipleChoice: 'Nesta secção consegue reparar que o aluno respondeu corretamente a este exercício de "Escolha Múltipla".',
          multipleChoiceGrader: 'A Intuitivo é capaz de corrigir alguns tipos de exercícios automaticamente, como é o caso do tipo "Escolha Múltipla". Neste exercício o aluno obteve a pontuação máxima, mas pode editar livremente este valor.',
          openQuestionGrader: 'No entanto, para perguntas de resposta aberta terá que fazer a correção manual e atribuir a pontuação.',
          openQuestionFeedback: 'Pode também dar feedback a qualquer resposta de um aluno, mesmo tendo correção automática.',
          publishGradesButton: 'Assim que tiver corrigido a avaliação pode publicar as correções. Os alunos que tenham fornecido o seu endereço de email ao começar a avaliação irão receber a sua nota e correção.',
          tabStatistics: 'Na aba "Estatísticas" tem acesso a mais informação detalhada da avaliação e desempenho dos seus alunos.',
          assessmentSidebarOption: 'Vamos voltar à pagina de Avaliações.',
          testCardDropdown: 'Assim que acabar de explorar esta avaliação, pode apagá-la a qualquer momento clicando neste menu e escolhendo a opção "Apagar".',
          hub: 'Pode repetir este guia a qualquer altura na secção de guias. Recomendamos que explore esta secção caso precise de ajuda a criar avaliações e exercícios.',
        },
      },
      assessments: {
        title: 'Avaliações',
        subtitle: 'Aprenda a criar e publicar avaliações',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Avaliações".',
          sidebar: 'Pode aceder às avaliações na página de "Avaliações".',
          createButton: 'Para criar uma avaliação, clique no botão "Criar Avaliação".',
          nameInput: 'Dê um nome à sua avaliação, algo como "A minha avaliação".',
          typeInput: `Escolha o tipo de avaliação:
              <ul>
                <li><strong>Teste:</strong> Tem tempo limite e está agendado para uma data específica.</li>
                <li><strong>Ficha:</strong> Acessível até uma data de submissão.</li>
              </ul>
              Vamos deixar a opção pré-definida "Teste".`,
          classificationInput: `Escolha o tipo de classificação:
              <ul>
                <li><strong>Clássico:</strong> As submissões dos alunos são corrigidas e atribuídas uma nota</li>
                <li><strong>Rubrica:</strong> Tem um critério de correção específico (Pode aprender mais sobre rubricas no guia "Rubricas").</li>
                <li><strong>Sem classificação:</strong> As submissões dos alunos não ficam com uma nota atribuída</li>
              </ul>
              Vamos deixar a opção pré-definida "Clássico".`,
          groupInput: 'Pode guardar esta avaliação em qualquer grupo a que pertença. (Pode aprender mais sobre grupos no guia "Grupos").',
          submitButton: 'Complete a criação desta avaliação clicando em "Criar".',
          exercisesTab: 'Neste momento está na aba de "Exercícios". Aqui pode adicionar conteúdo à sua avaliação, como exercícios, secções ou blocos de informação.',
          gradesTab: 'Quando publicar o seu teste e este for respondido pelos seus alunos, irá conseguir ver as suas resoluções na aba "Correção".',
          physicalTab: 'Na aba "Versão Física", poderá exportar a avaliação para um documento PDF.',
          contentForm: 'Nesta secção poderá adicionar conteúdo à sua avaliação.',
          createNewExercise: 'Vamos fazer exatamente isso. Carregue em "Criar um novo exercício".',
          exerciseType: 'Pode escolher que tipo de exercício quer criar.',
          trueOrFalseExerciseType: 'Neste caso, escolha a opção "Verdadeiro/Falso".',
          exerciseStatement: 'Escreva o enunciado do exercício, algo como "Os golfinhos são mamíferos?"',
          exerciseCorrectAnswer: 'Nesta secção pode escolher a opção correta.',
          exerciseTrue: 'Neste caso, escolha a opção "Verdadeiro".',
          exerciseSubmit: 'Está pronto. Este exercício também será adicionado à sua biblioteca de exercícios. (Pode aprender mais sobre exercícios no guia "Exercícios"). Pode agora clicar em "Confirmar".',
          exerciseGrade: 'Pode editar a cotação de um exercício específico.',
          exerciseEdit: 'Pode editar um exercício a qualquer altura clicando no botão de editar.',
          exerciseDelete: 'Um exercício pode também ser apagado de uma avaliação.',
          publishButton: 'O seu teste está pronto para ser publicado. Clique no botão "Publicar".',
          publishFormFormat: 'Pode escolher se quer apresentar os exercícios todos juntos, ou um de cada vez.',
          publishFormStart: 'Escolha uma data de início para este teste.',
          publishFormTolerance: 'Escolha o número total (em minutos) que um aluno pode começar o teste depois da data de início.',
          publishFormDuration: 'Escolha o tempo total (em minutos) que os alunos têm para completar o teste.',
          publishFormPassword: 'Pode proteger o seu teste com uma palavra-chave que terá que ser introduzida pelos alunos para darem início ao teste.',
          publishFormShuffle: 'Pode escolher baralhar a ordem das perguntas, deste modo os seus alunos terão testes diferentes.',
          publishFormFeedback: 'Pode também permitir que os seus alunos tenham acesso à correção do teste após o submeterem.',
          publishFormModelAnswer: 'Se a opção the feedback imediato estiver escolhida, pode permitir que os alunos tenham acesso às respostas modelo após submeterem o teste.',
          publishFormSubmit: 'Está pronto, pode agora publicar o teste clicando em "Publicar".',
          publishLink: 'Parabéns, o seu teste foi publicado. Agora basta partilhar este link com os seus alunos para que eles consigam realizar o teste.',
          publishCloseModal: 'Pode fechar esta janela.',
          publishButtonFinal: 'Pode ver a janela anterior a qualquer altura clicando neste botão.',
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      explore: {
        title: 'Banco Público',
        subtitle: 'Aprenda como usar recursos partilhados por outros Professores',
        steps: {
          sidebarExplore: 'Para começar, vá à página de "Explorar".',
          sidebar: 'Pode aceder ao banco público na página "Explorar".',
          exerciseList: 'Nesta página poderá encontrar todos os exercícios e avaliações criadas e partilhadas pela comunidade de professores e escolas que usam a Intuitivo. Para contribuir, basta marcar o seu conteúdo como público durante a criação de um exercício ou avaliação.',
          header: 'Nesta secção tem acesso a uma ferramenta de pesquisa para conteúdos do banco público.',
          headerTextInput: 'Pode pesquisar por palavras chave.',
          headerContentDropdown: 'Pode filtrar por tipo de conteúdo: Avaliações, Exercícios ou ambos.',
          headerFilters: 'Pode também adicionar filtros por Área Disciplinar ou Ano de Escolaridade. Por defeito, estão selecionados os filtros relativos às suas preferências (Pode alterá-las nas definições da sua conta).',
          exerciseCardClick: 'Clique no seguinte conteúdo para ter uma vista detalhada.',
          exerciseCardPreview: 'Aqui pode ver em detalhe a estrutura do conteúdo.',
          exerciseCardPreviewCopy: 'Para adicionar este conteúdo ao seu banco privado basta clicar no botão "Copiar".',
          exerciseCardPreviewSelectGroup: 'De seguida necessita de escolher o grupo de destino.',
          exerciseCardPreviewSubmit: 'E finalmente clicar em "Confirmar".',
          exerciseCardClosed: 'Agora pode ver e editar este conteúdo na página de "Avaliações" ou "Exercícios", dependendo do tipo.',
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      groups: {
        title: 'Grupos',
        subtitle: 'Aprenda a criar e utilizar grupos para colaborar com os seus colegas',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Grupos".',
          sidebar: 'Pode aceder aos grupos na página de "Grupos".',
          createNewGroup: 'Para criar um grupo basta clicar no botão "Criar Grupo".',
          formNameInput: 'Dê um nome a este grupo, por exemplo "Professores de Matemática 3º Ciclo".',
          formTeachersInput: 'Nesta secção poderá adicionar os seus colegas que também usam a Intuitivo, através dos seus endereços de email. No entanto, para efeitos deste guia poderá deixar este campo vazio',
          formConfirm: 'Termine a criação do grupo clicando em "Confirmar"',
          groupCard: 'Cada grupo será um cartão com o nome do mesmo. Clique no cartão para ter uma vista detalhada.',
          teachersTable: 'Nesta tabela estão presentes o nome e endereço de email de cada participante do grupo.',
          addTeacherButton: 'Para adicionar novos membros pode clicar neste botão, que irá apresentar uma formulário para adicionar o endereço de email de cada professor.',
          groupShortcuts: `
            <p>
              Neste cartão pode encontrar atalhos para as páginas de "Avaliações", "Exercícios" e "Rubricas" pertencentes a este grupo.
              Em cada uma destas páginas irá encontrar um selecionador para navegar entre grupos, como ilustra a imagem:
            </p>
            <img style="width: 100%" src="${process.env.REACT_APP_S3_URL}/resources/groups-header-shortcut-pt.png" style="width: 100%">
          `,
          leaveGroup: 'Para sair de um grupo basta clicar neste menu e escolher a opção "Sair do Grupo".',
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      rubrics: {
        title: 'Rubricas',
        subtitle: 'Aprenda a criar rubricas e a utilizá-las nas avaliações',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Rubricas".',
          sidebar: 'Pode aceder às rubricas na página "Rubricas".',
          createNewButton: 'Para criar uma rubrica clique no botão "Criar rubrica".',
          nameInput: 'Dê um nome à rubrica, por exemplo "Critérios de Avaliação - História 12º ano".',
          descriptionInput: 'Pode escrever uma descrição de rubrica para adicionar mais contexto.',
          criteriaCard: 'Nesta secção pode adicionar todos os critérios que achar necessários. Os niveis dos critérios vão de 1 a 5.',
          criteria0NameInput: 'Dê o nome "Conteúdos" a este critério.',
          criteria0ValueInput: `
            De seguida pode adicionar descritores de desempenho a este rubrica. Por exemplo:
            <ul>
              <li><strong>(5):</strong> Explica, de forma completa, o pretendido;</li>
              <li><strong>(4):</strong> Explica, de forma completa, apenas parte do pretendido;</li>
              <li><strong>(3):</strong> Explica, de forma incompleta, o pretendido;</li>
              <li><strong>(2):</strong> Explica, de forma incompleta, grande parte do pretendido;</li>
              <li><strong>(1):</strong> Explica, de forma incompleta, apenas parte do pretendido;</li>
            </ul>`,
          addCriteriaButton: 'Adicione um novo critério clicando neste botão.',
          criteria1NameInput: 'Dê o nome "Comunicação" a este critério.',
          criteria1ValueInput: 'Semelhante ao critério anterior, pode adicionar os descritores de desempenho que achar indicado. No entanto, para efeitos deste guia pode passar este passo.',
          criteria1RemoveButton: `Caso pretenda eliminar um critério, basta clicar no icon
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
            </svg>`,
          exerciseExample: `
            <p>
              Durante a correção de uma avaliação do tipo rubrica, a avaliação de cada pergunta será feita por critérios.
              Assim pode atribuir o peso de cada critério a cada exercício, como ilustra a seguinte imagem:
            </p>
            <img src="${process.env.REACT_APP_S3_URL}/resources/exercise-rubric-example-pt.png" style="width: 100%">
            <p>Caso necessite, tem os guias "Avaliações" e "Introdução", que demonstram os processos de criação, publicação e correção de uma avaliação.</p>
          `,
          groupDropdown: 'Como em outros exemplos, pode sempre escolher o grupo de destino de uma rubrica. O guia "Grupos" contém mais informações sobre esta funcionalidade.',
          saveButton: 'Termine a criação da rubrica clicando no botão "Gravar"',
          card: 'Uma rubrica criada irá aparecer da seguinte forma.',
          cardActions: `
            Nesta secção do cartão da rubrica pode:
            <ul>
              <li>Pré-visualizar;</li>
              <li>Editar;</li>
              <li>Enviar para outro grupo;</li>
              <li>Duplicar;</li>
              <li>Apagar;</li>
            </ul>
          `,
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
      exercises: {
        title: 'Exercícios',
        subtitle: 'Aprenda a criar e usar exercícios',
        steps: {
          sidebarGoToPage: 'Para começar, vá à página de "Exercícios".',
          sidebar: 'Pode aceder aos exercícios na página de "Exercícios".',
          createExerciseButton: 'Para criar um exercício, carregue no botão "Criar Exercício".',
          createExerciseType: 'Existem vários tipos de exercícios que tem à sua disposição. Recomendamos que dedique algum tempo a explorar os diferentes tipos de exercícios. Caso necessite, pode assistir aos nossos <a href="https://intuitivo.pt/suporte/tutoriais" target="_blank">Tutoriais</a>.',
          createExerciseTypeText: 'Para efeitos deste guia, crie um simples exercício de texto.',
          editorHeaderTypeDropdown: 'O primeiro passo para a criação de um exercício está completo. No entanto, se preferir alterar o tipo de exercício pode fazê-lo nesta área.',
          editorHeaderGroupDropdown: 'Da mesma forma, pode escolher o grupo de destino deste exercício. Para mais informações sobre grupos, tem disponível o guia "Grupos" na secção de guias.',
          editorStatement: `
            Escreva o enunciado deste exercício. Pode adicionar conteúdos multimédia como imagens, vídeos e áudios. Também tem acesso a um editor de fórmulas matemáticas.
            <br/>
            Para este caso, pode escrever algo simples como <i>"Quem foi o primeiro rei de Portugal?"</i>
          `,
          editorShortAnswer: 'Dependendo do tipo de exercício, terá opções diferentes de customização. Para exercícios de texto pode limitar o número de caracteres da resposta, e permitir ao aluno acesso ao editor de fórmulas matemáticas.',
          editorExtraText: 'Em todos os tipos exercícios, pode adicionar texto extra ao exercício que estará disponível depois do campo de resposta do aluno.',
          editorModelAnswer: 'Dependendo do tipo de exercício, tem a opção adicionar uma resposta modelo ao exercício, que não será apresentada ao estudante durante a avaliação mas pode ser opcionalmente apresentada depois da avaliação terminar.',
          editorPreview: 'Terá sempre uma pré-visualização do exercício. Isto será a versão que os alunos irão ver durante a realização de uma avaliação.',
          editorSettingsFormTitle: `
            Para terminar a criação do exercício, terá que lhe dar um nome identificativo.
            <br/>
            Que tal algo como <i>"O primeiro rei de Portugal"</i>?
          `,
          editorSettingsFormVisibility: `
            De seguida pode escolher se quer manter o seu exercício público ou privado:
            <ul>
              <li><strong>Público:</strong> o exercício estará disponível na página <i>"Explorar"</i>, e poderá ser copiado e utilizado por outros professores.</li>
              <li><strong>Privado:</strong> o exercício apenas estará visível para si e para membros do grupo onde guardar este exercício.</li>
            </ul>
            Para mais informações sobre a página <i>"Explorar"</i> e <i>"Grupos"</i> tem à sua disposição guias sobre essas funcionalidades.
          `,
          editorSettingsFormVisibilityPrivate: 'Para este caso, vamos manter o exercício privado. Selecione a opção <i>"Privado"</i>.',
          editorLabelsButton: 'Se achar necessário, pode criar e adicionar etiquetas para catalogar este exercício. Este esforço irá facilitar a filtragem e pesquisa de exercícios.',
          editorHeaderSaveExercise: 'Finalmente, termine a criação de exercícios clicando no botão "Gravar".',
          exerciseCard: 'Um exercício criado irá aparecer da seguinte forma.',
          exerciseCardActions: `
            Nesta secção do cartão do exercício pode.
            <ul>
              <li>Pré-visualizar o exercício;</li>
              <li>Editá-lo;</li>
              <li>Enviar para outro grupo;</li>
              <li>Duplicá-lo;</li>
              <li>Apagá-lo;</li>
            </ul>
          `,
          exerciseFilters: `
            Terá sempre a possibilidade de filtrar exercícios por:
            <ul>
              <li>Professor que o criou;</li>
              <li>Tipo;</li>
              <li>Etiquetas;</li>
            </ul>
            E ordenar por:
            <ul>
              <li>Mais recente;</li>
              <li>Mais antigo;</li>
              <li>Último modificado;</li>
            </ul>
          `,
          hub: 'Este e outros guias estão sempre disponíveis para consulta na secção de "Guias".',
        },
      },
    },
    plans: {
      premium: 'Premium',
      free: 'Básico',
      exceededTests: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criadas por si, englobando todos os grupos em que participa. Elimine avaliações ou atualize para o premium com uma das duas opções abaixo.`,
      exceededTestsTip: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criados por si, englobando todos os grupos em que participa. Elimine avaliações ou atualize para o premium.`,
      upgrade: 'ATUALIZAR',
      getPremium: 'Obter Premium',
      unlimitedAccessMessage: 'Desbloqueie acesso ilimitado à Intuitivo individualmente.',
      recommendSchool: 'Recomendar Escola',
      recommend: 'RECOMENDAR',
      dedicatedSpace: 'Desbloqueie um espaço dedicado para a sua escola com acesso ilimitado à Intuitivo.',
      exceededExercises: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'exercício' : 'exercícios'} ${limit === 1 ? 'gratuito' : 'gratuitos'} criados por si, englobando todos os grupos em que participa. Elimine exercícios ou atualize para o premium com uma das duas opções abaixo.`,
      exceededExercisesTip: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'exercício' : 'exercícios'} ${limit === 1 ? 'gratuito' : 'gratuitos'} criados por si, englobando todos os grupos em que participa. Elimine exercícios ou atualize para o premium.`,
      exceededRubrics: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criadas por si, englobando todos os grupos em que participa. Elimine rubricas ou atualize para o premium com uma das duas opções abaixo.`,
      exceededRubricsTip: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'} ${limit === 1 ? 'gratuita' : 'gratuitas'} criadas por si, englobando todos os grupos em que participa. Elimine rubricas ou atualize para o premium.`,
      exceededGroups: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'grupo' : 'grupos'} ${limit === 1 ? 'gratuito' : 'gratuitos'}. Saia de grupos ou atualize para o premium com uma das duas opções abaixo.`,
      exceededSections: (limit) => `Excedeu o limite de ${limit} ${limit === 1 ? 'secção' : 'secções'} ${limit === 1 ? 'gratuita' : 'gratuitas'} por teste. Elimine secções ou atualize para o premium com uma das duas opções abaixo.`,
      premiumFeature: 'Esta é uma funcionalidade premium. Atualize para o premium para desbloquear.',
      warningCreatePremiumExercise: 'Pode criar este tipo de exercício, mas não pode publicar testes que o contenha.',
      warningPublishTestWithPremiumExercise: 'Não pode publicar um teste que contenha exercícios premium. Remova esses exercícios ou atualize para o premium com uma das duas opções abaixo.',
      warningPublishTestWithExceededSections: (limit) => `Não pode publicar um teste que contenha mais de ${limit} ${limit === 1 ? 'secção' : 'secções'}. Remova essas secções ou atualize para o premium com uma das duas opções abaixo.`,
      warningExportTestMaxUsages: (limit) => `Só pode exportar no máximo ${limit} ${limit === 1 ? 'versão' : 'versões'}. Atualize para o premium para desbloquear mais.`,
      schools: 'Escolas',
    },
    navigation: {
      title: 'Navegação',
      section: 'Secção',
    },
    curricula: {
      pt_PT: 'Português',
      other: 'Outro',
      curriculum: 'Currículo',
    },
  },
  esES: {
    ok: 'Ok',
    add: 'Añadir',
    create: 'Crear',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Enviar',
    hide: 'Ocultar',
    preview: 'Vista previa',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Borrar',
    'delete': 'Eliminar',
    remove: 'Quitar',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpiar',
    saveChanges: 'Guardar cambios',
    save: 'Guardar',
    copy: 'Copiar',
    oops: 'Algo salió mal. Por favor intenta de nuevo',
    waitSeconds: 'Este proceso puede tardar unos segundos',
    waitMinutes: 'Este proceso puede tardar unos minutos',
    waitTime: 'Este proceso puede tardar algún tiempo',
    date: 'Fecha',
    previous: 'Anterior',
    next: 'Siguiente',
    'export': 'Exportar',
    en: 'Inglés',
    pt: 'Portugués',
    esES: 'Español',
    ptBR: 'Portugués (Brasil)',
    unauthorizedMessage: 'Parece que aún no estás calificado para estar en esta área... ¡Tal vez el próximo año!',
    notFoundMessage: '¡Oh no! Parece que te perdiste en nuestra página web... Quizás no es tan intuitiva.',
    errorMessage: 'Algo salió mal... Lo solucionaremos lo antes posible.',
    noPermissionSpace: 'No tienes permisos para acceder a este espacio.',
    home: 'Inicio',
    account: 'Cuenta',
    signout: 'Cerrar sesión',
    finished: 'Terminado',
    example: 'Ejemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos trabajando para mejorar esta función',
    enable: 'Habilitar',
    disable: 'Deshabilitar',
    noOptionsAvailable: 'No hay opciones disponibles',
    close: 'Cerrar',
    'continue': 'Continuar',
    'in': 'en',
    goBack: 'Volver',
    name: 'Nombre',
    email: 'Correo electrónico',
    shortcuts: 'Atajos',
    leave: 'Salir',
    send: 'Enviar',
    comingSoon: '¡Próximamente!',
    comment: 'Comentario',
    help: 'Ayuda',
    feedback: 'Comentarios',
    copied: 'Copiado',
    noEmail: 'Sin correo',
    selectAll: 'Seleccionar todo',
    general: 'General',
    discardWarning: 'Si continúas, todas las adiciones se perderán.',
    notFound: 'No encontrado',
    copyOf: 'Copia de',
    otherOptions: 'Otras opciones',
    reschedule: 'Reprogramar',
    results: 'Resultados',
    'new': 'Nuevo',
    filterBy: 'Filtrar por',
    copies: 'Copias',
    orderBy: 'Ordenar por',
    oldest: 'Más antiguo',
    newest: 'Más reciente',
    relevancy: 'Relevancia',
    showMore: 'Mostrar más',
    showLess: 'Mostrar menos',
    settings: 'Configuraciones',
    scrollToSides: 'Desplazar a los lados',
    download: 'Descargar',
    fileTooLarge: 'El archivo no puede ser mayor a 10Mb',
    fileTooLarge1Gb: 'El archivo no puede ser mayor a 1Gb',
    drag: 'Arrastrar',
    dropHere: 'Soltar aquí',
    changeLang: 'Cambiar idioma',
    logout: 'Cerrar sesión',
    video: 'Video',
    audio: 'Audio',
    tutorial: 'Tutoriales',
    continueWith: 'Continuar con',
    or: 'O',
    notYou: '¿No es tu cuenta?',
    guest: 'Invitado',
    select: 'Seleccionar',
    internetWentWrong: 'Algo salió mal. Verifica tu conexión a internet y vuelve a intentarlo',
    openFormatting: 'Abrir formato',
    openMedia: 'Abrir medios',
    appNotAvailable: 'El sitio web ya no está disponible a través del navegador. Utiliza la aplicación para acceder a los exámenes.',
    generateDocument: 'Generar documento',
    refresh: 'Actualizar',
    seeMore: 'Ver más',
    deleteDisabled: 'No puedes eliminar el contenido de otros usuarios',
    andXMore: (x) => `Y ${x} más...`,
    words: 'Palabras',
    contentSettings: {
      'public': 'Público',
      'private': 'Privado',
    },
    common: {
      edit: 'Editar',
      cancel: 'Cancelar',
      discard: 'Descartar',
      finish: 'Finalizar',
      goBack: 'Volver',
      somethingWentWrong: 'Algo salió mal. Por favor intenta de nuevo',
      internetWentWrong: 'Algo salió mal. Verifica tu conexión a internet y vuelve a intentarlo',
      createdBy: 'Creado por',
      publish: 'Publicar',
      published: 'Publicado',
      name: 'Nombre',
      fontSize: {
        small: 'Pequeño',
        normal: 'Normal',
        large: 'Grande',
        huge: 'Enorme',
      },
    },
    time: {
      day: 'día',
      days: 'días',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Clases',
      tests: 'Pruebas',
      test: 'Prueba',
      threads: 'Hilos',
      admin: 'Administrador',
      lesson: 'Lección',
      subject: 'Asignatura',
      'class': 'Clase',
      exercises: 'Ejercicios',
      exercise: 'Ejercicio',
      correction: 'Corrección',
      answer: 'Respuesta',
      grade: 'Calificación',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Mi contenido',
      allGroups: 'Todos los grupos',
      personalSpace: 'Espacio personal',
      students: 'Estudiantes',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (prueba gratuita)',
      premiumInfo: 'Período experimental de duración indefinida. Actualmente, puedes usar la plataforma y todas sus funcionalidades de forma gratuita. Más adelante, introduciremos planes de pago para profesores, pero siempre habrá un plan gratuito, aunque con algunas limitaciones en cuanto a funcionalidades.',
      rubrics: 'Rúbricas',
      rubric: 'Rúbrica',
      criterion: 'Criterios',
      criteria: 'Criterios',
      classic: 'Clásico',
      noClassification: 'Sin clasificación',
      worksheet: 'Hoja de trabajo',
      assessment: 'Evaluación',
      assessments: 'Evaluaciones',
      spaces: 'Espacios',
      space: 'Espacio',
      statistics: 'Estadísticas',
      teachers: 'Profesores',
      events: 'Eventos',
      exams: 'Exámenes',
      examGroupsMin: 'Grupos de exámenes',
    },
    tests: {
      title: 'Pruebas',
      teacher: {
        subtitle: 'Todas las pruebas creadas para las clases que enseñas',
        exercise: 'item',
        exercises: 'ítems',
        resolution: 'resolución',
        resolutions: 'resoluciones',
        publish: 'Publicar',
        grades: 'Calificaciones',
        stateUnpublished: 'No publicado',
        stateUpcoming: 'Programado',
        stateOngoing: 'En progreso',
        stateComplete: 'Completado',
        noTests: 'Aún no se han creado pruebas',
      },
      student: {
        subtitle: 'Todas las pruebas realizadas por tus profesores',
        noTests: 'Actualmente no hay pruebas disponibles para ti',
        opensIn: 'Abre en',
        openingClosesAt: 'La apertura cierra a las',
        testEndsAt: 'La prueba termina a las',
        testNotOpen: 'La prueba aún no ha abierto',
        testWindowClosed: 'La ventana de apertura de la prueba se cerró. Ya no es posible abrir la prueba',
        testComplete: 'Ya has completado la prueba. Todas tus respuestas fueron guardadas',
        testCompleteTime: 'La prueba ya terminó',
        testPasswordModal: 'Contraseña de la prueba',
        testWrongPassword: 'La contraseña es incorrecta',
      },
    },
    test: {
      title: 'Prueba',
      duplicateTest: 'Duplicar prueba',
      question: 'Ítem',
      informationBlock: 'Bloque de información',
      information: 'Información',
      'true': 'Verdadero',
      'false': 'Falso',
      textPlaceholder: 'Escribe aquí tu respuesta',
      justificationLabel: 'Justificación',
      justificationPlaceholder: 'Escribe aquí tu justificación',
      dragImageHint: 'Arrastra y suelta imágenes aquí, o haz clic para seleccionar imágenes',
      dropImageHint: 'Suelta para añadir',
      addQuestion: 'Añadir contenido',
      selectExercises: 'Seleccionar ejercicios',
      addExercises: 'Añadir ejercicios',
      selectedExercises: 'ejercicios seleccionados',
      successAddExercises: 'Los ejercicios se añadieron con éxito',
      successAddExercise: 'El ejercicio se añadió con éxito',
      successAddInformationBlock: 'El bloque de información se añadió con éxito',
      successDuplicateTest: 'La evaluación se duplicó con éxito',
      errorAddExists: 'No es posible añadir ejercicios que ya están añadidos a la prueba',
      selectExercisesFromOtherSpace: '¡Solo puedes usar ejercicios creados por ti si seleccionas otros espacios!',
      selectRubricsFromOtherSpace: '¡Solo puedes usar rúbricas creadas por ti si seleccionas otros espacios!',
      createNewExercise: 'Crear un nuevo ejercicio',
      addInformationBlock: 'Añadir bloque de información',
      selectExerciseFromDb: 'Seleccionar ejercicio de la base de datos',
      thisTestIsInvalid: 'Esta prueba es inválida',
      youAlreadyCompleted: 'Ya has completado esta prueba antes',
      deleteTest: '¿Eliminar evaluación?',
      deleteTestInfo: '¿Estás seguro de que deseas eliminar esta evaluación?',
      deleteTestInfo2: 'Esta acción no es reversible.',
      successDeleteTest: 'La evaluación se eliminó con éxito',
      deleteErrorDoesNotExist: 'No se pudo eliminar porque la evaluación no existe',
      timeRemaining: 'Tiempo restante',
      finishTest: 'Finalizar',
      finishHintConfirm: '¿Estás seguro de que deseas finalizar ahora?',
      finishHintIncomplete: 'Aún quedan ítems por responder. ¿Estás seguro de que deseas finalizar ahora?',
      finishTestSuccess: 'Has terminado. ¡Buen trabajo!',
      finishTestEnded: 'Ya habías terminado antes',
      finishErrorNetwork: 'Ocurrió un error al finalizar. Verifica tu conexión a internet e intenta de nuevo',
      answerError: (num) => `Ocurrió un error al guardar la respuesta del ítem ${num}. Inténtalo de nuevo.`,
      answerErrorNetwork: (num) => `Ocurrió un error al guardar la respuesta del ítem ${num}. Verifica tu conexión a internet e intenta de nuevo.`,
      answered: 'Respondido',
      attemptWindow: 'Ventana de apertura',
      duration: 'Duración',
      stateScheduled: 'Programado',
      stateOngoing: 'En progreso',
      stateFinished: 'Finalizado',
      moveUp: 'Mover arriba',
      moveDown: 'Mover abajo',
      errorMovePublished: 'No se pudo cambiar el orden porque la prueba está publicada. Despublica si deseas realizar cambios',
      hintMoveNotPossible: 'Ese movimiento no es posible',
      removeFromTest: 'Quitar de la prueba',
      removeExerciseFromTest: 'Quitar ejercicio de la evaluación',
      removeInformationBlockFromTest: 'Quitar bloque de información de la evaluación',
      removeSectionFromTest: 'Quitar sección de la evaluación',
      hintConfirmRemove: '¿Estás seguro de que deseas quitar este ejercicio de la evaluación?',
      hintConfirmRemoveInformationBlock: '¿Estás seguro de que deseas quitar este bloque de información de la evaluación?',
      hintConfirmRemoveSection: '¿Estás seguro de que deseas quitar esta sección de la evaluación?',
      errorRemovePublished: 'No se pudo quitar el ejercicio porque la prueba está publicada. Despublica si deseas realizar cambios',
      link: 'Enlace',
      copied: 'Copiado',
      password: 'Contraseña',
      compacted: 'Compacto',
      presentationFull: 'Completo',
      presentationIncremental: 'Incremental',
      navigationLinear: 'Lineal',
      navigationNonLinear: 'No lineal',
      presentationFullTip: 'Todos los ítems visibles al mismo tiempo',
      presentationIncrementalTip: 'Solo un ítem visible a la vez',
      navigationLinearTip: 'No se puede volver para actualizar una respuesta',
      navigationNonLinearTip: 'Se puede volver para actualizar una respuesta',
      header: {
        exportPhysical: 'Versión física',
      },
      testForm: {
        createTest: 'Crear evaluación',
        editTest: 'Editar prueba',
        testName: 'Nombre de la evaluación',
        errorNameRequired: 'Es necesario establecer un nombre',
        errorSubjectRequired: 'Es necesario elegir una asignatura',
        errorGroupRequired: 'Es necesario elegir un grupo',
        errorNoExercises: 'Es necesario añadir al menos un ejercicio',
        successCreateTest: 'La prueba se creó con éxito',
        successEditTest: 'La prueba se actualizó con éxito',
        classificationTypeLabel: 'Tipo de clasificación',
        whoCanSee: '¿Quién puede ver esta prueba?',
        privacyTypeLabel: 'Tipo de privacidad',
        classicClassificationTypeTip: 'Los ejercicios son calificados y se da una calificación final',
        rubricClassificationTypeTip: 'El nivel de los estudiantes se evalúa a través de los criterios definidos',
        noClassificationTypeTip: 'Los ejercicios no son calificados y no hay calificación final',
        testTypeLabel: 'Tipo de evaluación',
        testTypeTip: 'Evaluación con límite de tiempo programada para una fecha específica',
        worksheetTypeTip: 'Evaluación que permanece disponible hasta la fecha de entrega',
        'public': 'Público',
        publicTip: 'Visible en la página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visible solo para ti o para los grupos con los que compartes el ejercicio',
      },
      publishModal: {
        testFormat: 'Formato de evaluación',
        navigationType: 'Tipo de navegación',
        errorTypeRequired: 'Es necesario elegir un tipo de prueba',
        testShuffleLabel: 'Mezclar el orden de los ejercicios',
        shuffleNoneLabel: 'No mezclar nada',
        shuffleBaseLabel: 'Mezclar secciones y ejercicios fuera de las secciones, manteniendo el orden dentro de las secciones',
        shuffleSectionsLabel: 'Mantener el orden de las secciones y mezclar los ejercicios dentro de ellas',
        shuffleAllLabel: 'Mezclar todas las secciones, ejercicios fuera de ellas y los ejercicios dentro de las secciones',
        publishTest: 'Publicar evaluación',
        unpublishTest: 'Despublicar evaluación',
        rescheduleTest: 'Reprogramar evaluación',
        editTest: 'Editar evaluación',
        editPublication: 'Editar publicación',
        successUnpublishTest: 'La prueba se despublicó con éxito',
        beforePublishing: 'Antes de publicar...',
        labelStartsAt: 'Fecha y hora de inicio',
        labelEndsAt: 'Fecha y hora de finalización',
        labelAttemptWindow: 'Ventana de apertura (en minutos)',
        labelDuration: 'Duración (en minutos)',
        labelPassword: 'Contraseña',
        labelExitPin: 'PIN de salida',
        hintExitPin: 'Este campo define un PIN de salida para los estudiantes que usan la aplicación de examen',
        labelTargets: 'Clases y estudiantes',
        hintAttemptWindow: 'Este campo define cuántos minutos tienen los estudiantes para comenzar la prueba',
        hintTargets: 'Este campo define qué clases y estudiantes pueden abrir la prueba',
        errorStartsAtRequired: 'Es necesario establecer una fecha de inicio',
        errorStartsAtPast: 'La fecha de inicio debe estar en el futuro',
        errorEndsAtRequired: 'Es necesario establecer una fecha de finalización',
        errorEndsAtBeforeStartsAt: 'La fecha de finalización debe ser posterior a la de inicio',
        errorAttemptWindowRequired: 'Es necesario establecer una ventana de apertura',
        errorAttemptWindowBoundaries: 'Este valor debe estar entre 1 y 4320 (3 días)',
        errorDurationRequired: 'Es necesario establecer una duración',
        errorDurationBoundaries: 'Este valor debe estar entre 1 y 1440 (1 día)',
        errorEditDuration: (duration) => `La duración debe ser mayor a ${duration} minutos`,
        successPublishTest: 'La evaluación se publicó con éxito',
        successRescheduleTest: 'La prueba se reprogramó con éxito',
        successEditPublication: 'La publicación se actualizó con éxito',
        publishErrorDoesNotExist: 'La prueba no se pudo publicar porque no existe',
        publishErrorNoLesson: 'La prueba no se pudo publicar porque debe pertenecer a una clase',
        publishErrorNoExercises: 'La prueba no se pudo publicar porque no tiene ejercicios',
        publishErrorAlreadyPublished: 'La prueba no se pudo publicar porque ya está publicada',
        republishErrorNotPublished: 'La prueba no se pudo reprogramar porque no está publicada',
        publishErrorAlreadyStarted: 'No fue posible actualizar la información de publicación porque la prueba ya ha comenzado o comienza en menos de 1 minuto',
        unpublishErrorDoesNotExist: 'La prueba no se pudo despublicar porque no existe',
        unpublishErrorAlreadyUnpublished: 'La prueba no se pudo despublicar porque ya está despublicada',
        publishPreHint: 'Antes de que sea posible publicar, esta evaluación debe:',
        hintHaveExercises: 'Tener al menos 1 ejercicio',
        errorTargetsRequired: 'Es necesario elegir al menos una clase o un estudiante',
        hintInformationBlocksShuffle: 'Todos los bloques de información permanecerán en el mismo lugar, solo los ejercicios se mezclarán. Si estás usando un bloque de información para dar contexto a ejercicios, considera desmarcar esta opción.',
        hintHasRubric: 'Tener una rúbrica seleccionada',
        hintAreWeightsCorrect: 'Asegúrate de que el total de los pesos por ejercicio sume 100%',
        hintSectionsHaveExercises: 'Tener al menos 1 ejercicio en cada sección',
        showRubricLabel: 'Mostrar la rúbrica a los estudiantes durante la prueba',
        opensIn: 'Abre en:',
        endsIn: 'Termina en:',
        scheduledFor: 'Programado para',
        openedOn: 'Abierto el',
        endedOn: 'Terminado el',
        endsOn: 'Termina el',
        immediateFeedbackLabel: 'Retroalimentación inmediata: el estudiante puede ver el resultado y las soluciones inmediatamente después de que termine la prueba',
        modelAnswerLabel: 'Respuesta modelo: el estudiante puede ver la respuesta modelo después de que termine la prueba',
        linearNavigation: 'Navegación lineal: el estudiante no puede volver a los ítems anteriores',
        nonLinearNavigation: 'Navegación no lineal: el estudiante puede volver a los ítems anteriores',
        createAttempts: 'Crear intentos',
        successCreateAttempts: 'Los intentos se crearon con éxito',
      },
      grades: {
        noAttempt: 'Sin entrega',
        point: 'Punto',
        points: 'Puntos',
        defineGrade: 'Definir calificación',
        assignGrade: 'Asignar calificación',
        maxValueExceeded: 'El valor de la calificación no puede exceder el valor máximo definido',
        notEmpty: 'El valor de la calificación no puede estar vacío',
        noStudents: 'Actualmente no hay estudiantes asignados a esta evaluación.',
        maxValueLabel: 'Máximo:',
        negativeValueError: 'Los puntos asignados a un ejercicio no pueden ser negativos',
        testOf: 'Prueba de',
        generateGrades: 'Generar calificaciones',
        successUpdatePoints: 'Los puntos del ejercicio se actualizaron con éxito',
        successUpdatePointsWarn: '¡Los puntos del ejercicio se actualizaron con éxito! No olvides generar las calificaciones nuevamente',
        pointsTooHigh: '¡Los puntos asignados al ejercicio son demasiado altos!',
        studentName: 'Nombre del estudiante',
        noAnswersYet: 'Ningún estudiante ha intentado la evaluación aún para generar calificaciones',
        submission: 'Entrega',
        successUpdateGrade: 'La calificación se actualizó con éxito',
        submissionLink: 'Enlace de entrega',
        publishCorrections: 'Publicar correcciones',
        correctionsPublished: 'Correcciones publicadas',
        exportResolutions: 'Exportar resoluciones de los estudiantes',
        addComment: 'Añadir comentario',
        successUpdateFeedback: 'El comentario se actualizó con éxito',
        unavailable: 'Esto estará disponible una vez que la prueba haya terminado',
        unavailableStudent: 'Esto estará disponible una vez que el estudiante haya terminado la prueba',
        publishCorrectionsInformation: 'Se enviará un correo electrónico a cada estudiante notificándole que su evaluación fue corregida. El correo incluirá un enlace donde podrán ver el intento y los comentarios.',
        successUpdateCriteriaGrade: 'La calificación del criterio se actualizó con éxito',
        criteriaGradeNotEmptyError: 'La calificación del criterio no puede estar vacía',
        criteriaGradeNegativeValueError: 'La calificación del criterio no puede ser negativa',
        criteriaGradeTooHighError: 'La calificación del criterio no puede ser mayor a 5',
        exportAttemptsCSV: 'Exportar resoluciones CSV',
        exportGrades: 'Exportar calificaciones Excel',
        deleteAttemptUnavailable: 'Este estudiante aún no ha comenzado un intento.',
        deleteAttemptConfirm: (name) => `¿Estás seguro de que deseas eliminar el intento de ${name}?`,
        deleteAttemptConfirmSubtitle: 'Esta acción no es reversible.',
        successDeleteAttempt: 'El intento fue eliminado con éxito.',
        studentSearch: 'Buscar estudiantes',
        noStudentsFound: 'No se encontraron estudiantes',
        showModelAnswer: 'Respuesta modelo: el estudiante puede ver la respuesta modelo al revisar la evaluación',
        notFinished: 'No terminado',
        attemptDates: 'Comenzado a las - Terminado a las',
        annotate: 'Anotar',
        saveAnnotation: 'Guardar anotaciones',
      },
      stats: {
        classAverage: 'Promedio',
        classMedian: 'Mediana',
        averageInfo: 'El valor promedio se calcula sumando todos los resultados de las pruebas y dividiendo entre el número de pruebas',
        medianInfo: 'La mediana es el valor medio obtenido cuando todos los resultados de las pruebas se ordenan de menor a mayor',
        positive: 'Positivo',
        negative: 'Negativo',
        highest: 'Calificación más alta',
        lowest: 'Calificación más baja',
        question: 'Pregunta',
        sortOrder: 'Ordenar: ',
        lowToHigh: 'Ascendente',
        highToLow: 'Descendente',
        avrgGrade: 'Calificación promedio',
        avrgNormalized: 'Calificación normalizada promedio',
        avrgNormalizedInfo: 'Ordenar preguntas por calificación normalizada promedio en orden ascendente o descendente',
        avrgQuestionInfo: 'La calificación promedio obtenida en esta pregunta',
        premiumStats: 'Obtén una membresía premium para ver estadísticas más avanzadas',
        bestQuestion: 'Pregunta con las mejores respuestas',
        worstQuestion: 'Pregunta con las peores respuestas',
        bestQuestionShort: 'Mejor pregunta',
        worstQuestionShort: 'Peor pregunta',
        noAnswer: 'Sin respuesta',
        distributionChart: 'Número de resultados',
        choices: 'Opción múltiple',
        ascSort: 'Haz clic para ordenar ascendente',
        descSort: 'Haz clic para ordenar descendente',
        cancelSort: 'Haz clic para cancelar la ordenación',
        fastestTime: 'Tiempo más rápido',
        slowestTime: 'Tiempo más lento',
        averageTime: 'Tiempo promedio',
        inProgress: 'En progreso...',
        studentName: 'Nombre del estudiante',
        studentGrade: 'Calificación',
        studentRank: 'Rango',
        previewAnswer: 'Vista previa del ejercicio',
        studentCount: 'Número de estudiantes',
        gradeCount: 'Número de calificaciones',
        gradeCountTip: 'En los intervalos representados, como 0% a 10%, el límite inferior (0%) está incluido en la barra y el límite superior no, excepto en el último intervalo, 90% a 100%, que incluye ambos.',
      },
      physicalVersion: {
        physicalVersion: 'Versión física',
        exportTest: 'Exportar evaluación',
        generalSettings: 'Configuraciones generales',
        versionsLabel: 'Número de versiones',
        versionsPlaceholder: 'Inserta el número de versiones',
        localeLabel: 'Idioma',
        shuffleExercisesLabel: 'Mezclar el orden de los ejercicios',
        shuffleChoicesLabel: 'Mezclar el orden de las opciones de los ejercicios de opción múltiple',
        noExercises: 'La prueba necesita al menos un ejercicio para ser exportada',
        notEnoughExercises: 'No hay suficientes ejercicios para satisfacer las versiones solicitadas.',
        headerSettings: 'Configuraciones del encabezado',
        studentInput: 'Datos del estudiante',
        testName: 'Nombre de la evaluación',
        testNamePlaceholder: 'Inserta aquí el nombre de la evaluación',
        testNameEmptyError: 'El nombre de la evaluación no puede estar vacío',
        displayVersion: 'Mostrar la versión de la evaluación',
        schoolName: 'Nombre de la escuela',
        schoolNamePlaceholder: 'Inserta aquí el nombre de la escuela',
        schoolNameEmptyError: 'El nombre de la escuela no puede estar vacío',
        schoolLogo: 'Logo de la escuela',
        schoolLogoPlaceholder: 'Arrastra o haz clic aquí para insertar una imagen',
        schoolLogoEmptyError: 'Debes insertar una imagen para el logo de la escuela',
        subject: 'Asignatura',
        subjectPlaceholder: 'Inserta aquí el nombre de la asignatura',
        subjectEmptyError: 'El nombre de la asignatura no puede estar vacío',
        testDuration: 'Duración de la evaluación (en minutos)',
        testDurationPlaceholder: 'Inserta aquí la duración de la evaluación',
        testDurationEmptyError: 'La duración de la prueba no puede estar vacía',
        schoolYear: 'Año escolar',
        schoolYearPlaceholder: 'Inserta aquí el año escolar',
        schoolYearEmptyError: 'El año escolar no puede estar vacío',
        'class': 'Clase',
        classPlaceholder: 'Inserta aquí la clase',
        classEmptyError: 'La clase no puede estar vacía',
        date: 'Fecha de la evaluación',
        datePlaceholder: 'Inserta aquí la fecha de la evaluación',
        dateEmptyError: 'La fecha de la evaluación no puede estar vacía',
        exercisesSettings: 'Configuraciones de los ejercicios',
        includeZone: 'Incluir zona de respuestas',
        askForName: 'Nombre del estudiante',
        askForCode: 'Código del estudiante',
        askForGrade: 'Calificación del estudiante',
        extraFields: 'Campos adicionales',
        extraFieldButton: 'Añadir nuevo campo',
        extraFieldLabel: 'Nombre del campo:',
        extraFieldLabelPlaceholder: 'Inserta aquí el nombre del campo',
        customFieldEmptyLabelError: 'Debes insertar un nombre para el campo',
        extraFieldContent: 'Contenido del campo:',
        extraFieldContentPlaceholder: 'Inserta aquí el contenido del campo',
        customFieldEmptyContentError: 'Debes insertar el contenido del campo',
        numberOfLines: 'Número de líneas para las respuestas',
        numberOfLinesJustify: 'Número de líneas de justificación',
        showLines: 'Mostrar líneas',
        includeRubric: 'Incluir rúbrica en las versiones exportadas',
        noRubric: 'Aún no has seleccionado una rúbrica',
        sectionWithoutExercises: 'Esta sección no tiene ejercicios',
      },
      attemptsExport: {
        includeSchoolLogo: 'Incluir el logo de la escuela',
        schoolLogoPlaceholder: 'Arrastra o haz clic aquí para insertar una imagen',
        schoolLogoEmptyError: 'Debes insertar una imagen para el logo de la escuela',
        includeGrade: 'Incluir calificación',
        includeRubric: 'Incluir rúbrica',
        waitWhileGenerateDocuments: 'Espera mientras generamos los documentos',
        clickBelowToDownloadDocuments: 'Haz clic abajo para descargar los documentos',
        exportAttemptsPDF: 'Exportar intentos en PDF',
        generatingPDFs: 'Generando los PDFs',
        zippingPDFs: 'Comprimiendo los PDFs',
        generateSuccessDescription: 'Los exámenes fueron exportados a PDF con éxito. Haz clic en el botón de abajo para descargar.',
        includeModelAnswer: 'Incluir respuesta modelo',
        exportAttemptsNotAvailable: 'No hay intentos para exportar',
        downloadAttempts: 'Descargar intentos',
        downloadValidatedExplanation: 'Solo se exportarán los intentos validados',
      },
      send: {
        sendTest: 'Enviar evaluación',
        keepCopy: 'Mantener original y enviar una copia',
        successSendTest: 'La evaluación se envió con éxito',
        errorSendTestGroup: 'Debes elegir un grupo para enviar la evaluación.',
        errorSendTestSchool: 'Debes elegir un espacio para enviar la evaluación.',
        errorSendTestToMySpace: 'No puedes enviar evaluaciones de otros usuarios a tu espacio personal.',
      },
      settingsModal: {
        name: 'Nombre',
        successEditSettings: 'La configuración de la prueba se actualizó con éxito',
      },
      rubric: {
        addRubric: 'Añadir rúbrica',
        selectRubricFromBank: 'Seleccionar rúbrica del banco',
        successSetRubric: 'La rúbrica se añadió con éxito',
        changeRubric: 'Cambiar rúbrica',
        noRubric: 'Aún no hay una rúbrica asociada con esta prueba',
        totalPonderations: 'Ponderaciones totales para los criterios de la rúbrica',
        notEmpty: 'El peso del criterio no puede estar vacío',
        negativeValueError: 'El peso del criterio no puede ser negativo',
        weightTooHigh: 'El peso del criterio no puede exceder el 100%',
        successUpdateCriteriaWeight: 'El peso del criterio se actualizó con éxito',
        errorTotal: 'El total de los pesos por ejercicio debe sumar 100%',
        createRubric: 'Crear nueva rúbrica',
        editRubric: 'Editar rúbrica',
        successEditRubric: 'La rúbrica se actualizó con éxito',
      },
      exercises: {
        addSection: 'Añadir sección',
        sectionNameLabel: 'Nombre de la sección',
        sectionDescriptionLabel: 'Descripción de la sección',
        sectionNameRequiredError: 'El nombre de la sección es obligatorio',
        addSectionSuccessful: 'La sección se añadió con éxito a la prueba',
        editSectionSuccessful: 'La sección se actualizó con éxito',
        sectionPresentation: 'Presentación de la sección',
        sectionNavigation: 'Navegación de la sección',
        sectionTimerLabel: 'Temporizador de la sección en minutos (opcional)',
        sectionTimerWarning: 'El temporizador de la sección solo funciona en pruebas incrementales',
        pauseItem: 'Ítem de pausa',
        addPauseItem: 'Añadir ítem de pausa',
        addPauseItemStepHeader: 'Mensaje de pausa',
        addPauseItemPlaceholder: 'Escribe aquí el mensaje de pausa',
        successAddPauseItem: 'El ítem de pausa se creó con éxito',
        editPublishedExerciseWarning: 'Una vez que la evaluación se publica, ciertos detalles no se pueden editar y al guardar los cambios, el ejercicio será calificado nuevamente y cualquier calificación anterior será reemplazada.',
        editPointsSuccess: 'Los puntos del ejercicio se actualizaron con éxito',
        editPointsSuccessAfterPublish: 'Los puntos del ejercicio se actualizaron con éxito. Las calificaciones pueden tardar un tiempo en actualizarse',
        editWeightsSuccess: 'Los pesos de los criterios del ejercicio se actualizaron con éxito',
        editWeightsSuccessAfterPublish: 'Los pesos de los criterios del ejercicio se actualizaron con éxito. Las calificaciones pueden tardar un tiempo en actualizarse',
      },
    },
    exerciseForm: {
      createExerciseOf: 'Crear ejercicio de',
      editExerciseOf: 'Editar ejercicio de',
      editExercise: 'Editar ejercicio',
      selectExerciseType: 'Seleccionar tipo de ejercicio',
      typeChoices: 'Opción múltiple',
      typeTrueFalse: 'Verdadero / Falso',
      typeText: 'Texto',
      typeImage: 'Envío de imágenes',
      typeFilling: 'Rellenar los espacios',
      typeConnecting: 'Conectar',
      typeOrdering: 'Ordenar',
      typeSmartChoices: 'Opción múltiple inteligente',
      typeCaption: 'Subtitulado',
      typeSegmentation: 'Segmentación',
      typeTable: 'Tabla',
      newChoices: 'Nuevo ejercicio de opción múltiple',
      newTrueFalse: 'Nuevo ejercicio de verdadero / falso',
      newText: 'Nuevo ejercicio de texto',
      newImage: 'Nuevo ejercicio de envío de imágenes',
      newSmartChoices: 'Nuevo ejercicio de opción múltiple inteligente',
      editChoices: 'Editando ejercicio de opción múltiple',
      editTrueFalse: 'Editando ejercicio de verdadero / falso',
      editText: 'Editando ejercicio de texto',
      editImage: 'Editando ejercicio de envío de imágenes',
      editSmartChoices: 'Editando ejercicio de opción múltiple inteligente',
      discardTypeTitle: 'Descartar ejercicio',
      discardTypeContent: 'Si continúas, todas las adiciones se perderán',
      saveWarningContent: 'Al guardar, el ejercicio será calificado nuevamente y cualquier calificación anterior será reemplazada.',
      statement: 'Declaración',
      statementPlaceholder: 'Escribe aquí la declaración',
      statementPlaceholderInformation: 'Escribe aquí la información',
      noEmptyStatement: 'La declaración no puede estar vacía',
      noEmptyFormula: 'No puedes insertar una fórmula vacía',
      noInvalidFormula: 'La fórmula parece ser inválida. Si tiene espacios al inicio o al final, elimínalos. Corrígelo antes de continuar',
      trueFalse: 'Verdadero / falso',
      noEmptyTruth: 'Es necesario seleccionar un valor de verdad',
      choices: 'Opciones',
      choicesTip: 'Añade las diferentes opciones y elige la correcta haciendo clic en el botón de marca a la izquierda de la opción.',
      oneCorrectOption: 'Como publicaste la evaluación con el ejercicio teniendo solo una respuesta correcta, debes mantener solo una opción correcta.',
      multipleCorrectOptions: 'Como publicaste la evaluación con el ejercicio teniendo más de una respuesta correcta, debes mantener al menos dos opciones correctas.',
      itemstoOrder: 'Ítems para ordenar',
      itemsToOrderTip: 'Los ítems deben ordenarse en el orden que consideras correcto. Los ítems se presentarán de manera aleatoria a los estudiantes.',
      option: 'Opción',
      addOption: 'Añadir nueva opción',
      removeOption: 'Quitar opción',
      noEmptyOption: 'No puede haber opciones vacías',
      noDuplicateOptions: 'No puede haber opciones duplicadas',
      noAnswerChoiceDefined: 'Es necesario definir la opción correcta',
      editMultipleOptionFail: 'Es necesario definir al menos dos opciones correctas',
      item: 'Ítem',
      addItem: 'Añadir nuevo ítem',
      removeItem: 'Quitar ítem',
      noEmptyItem: 'No puede haber ítems vacíos',
      noDuplicateItems: 'No puede haber ítems duplicados',
      noAnswerGapDefined: 'Es necesario definir al menos un espacio',
      noExtraWordDefined: 'Es necesario definir al menos una opción extra para cada espacio',
      previewAndSave: 'Previsualizar y guardar',
      saveExercise: 'Guardar ejercicio',
      successExerciseCreated: 'Ejercicio creado con éxito',
      successExerciseEdited: 'Cambios guardados con éxito',
      successExerciseEditedAfterPublish: 'Cambios guardados con éxito. Las calificaciones pueden tardar un tiempo en actualizarse',
      advancedMode: 'Modo LaTeX',
      formulaHeader: 'Añadir fórmula',
      formulaLabel: 'Escribe aquí tu fórmula',
      formulaReference: 'Referencia de fórmulas soportadas',
      formulaSectionGeneral: 'General',
      formulaSectionMath: 'Matemáticas',
      videoHeader: 'Añadir video',
      youtube: 'Video de Youtube',
      youtubeLink: 'Enlace de Youtube',
      invalidUrl: 'Solo es posible insertar enlaces de videos de Youtube',
      noVideoChosen: 'No elegiste ningún enlace',
      myVideo: 'Mi video',
      addVideoLabel: 'Haz clic para añadir un video',
      justificationLabel: 'Justificación',
      justificationText: 'Añadir espacio para justificación',
      askForImage: 'Imagen',
      askForImageError: '¡Tipo de archivo incorrecto! Inserta solo archivos de imagen',
      caption: 'Subtítulo',
      createCaptions: 'Crear subtítulos',
      editCaptions: 'Editar subtítulos',
      createCaptionsDescription: 'Haz clic en la imagen para añadir un subtítulo. Puedes presionar y mantener un subtítulo para arrastrarlo.',
      editCaptionsDescription: 'Haz clic en un subtítulo para editar el texto.',
      captionWarning: 'Advertencia: Este ejercicio ofrece una mejor experiencia si se responde en una computadora.',
      shortAnswer: 'Respuesta corta',
      shortAnswerText: 'Convertir en ejercicio de respuesta corta',
      characterLimit: 'Límite de caracteres',
      characterLimitError: 'Especifica el número de caracteres',
      extraText: 'Texto adicional',
      extraTextPlaceholder: 'Inserta aquí el texto adicional',
      extraTextStartOpen: 'Iniciar completamente expandido',
      identifiers: 'Identificadores',
      useMathSymbols: 'Usar símbolos matemáticos (Estudiante)',
      wordCountLabel: 'Mostrar conteo de palabras (Estudiante)',
      exerciseSettings: {
        label: 'Configuraciones del ejercicio',
        title: 'Título del ejercicio',
        whoCanSee: '¿Quién puede ver este ejercicio?',
        areas: '¿Qué asignaturas son relevantes para este ejercicio?',
        years: '¿Para qué grados?',
        errorTitleRequired: 'Es necesario elegir un título',
        errorSubjectRequired: 'Es necesario elegir una asignatura',
        errorYearRequired: 'Es necesario elegir un grado',
        'public': 'Público',
        publicTip: 'Visible en la página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visible solo para ti o para los grupos con los que compartes el ejercicio',
      },
      gap: {
        choosingGaps: 'Seleccionando las palabras a ocultar',
        choosingGapsSub: 'Selecciona las palabras que deseas ocultar a los estudiantes',
        noGaps: 'Todavía no hay palabras en la declaración',
        choosingGapExtraOption: 'Opciones de ejercicio (lado del estudiante)',
        dragDropOption: 'Mostrar la lista de palabras ocultas (arrastrando)',
        dropdownOption: 'Mostrar la lista de palabras ocultas (desplegable)',
        writeOption: 'No mostrar la lista de palabras ocultas (escribir)',
        example: 'Ejemplo',
        write: 'Escribe...',
        correctionAuto: 'Corrección automática por la plataforma',
        correctionManual: 'Corrección hecha por el profesor',
        askForExtraGapsButton: 'Guardar palabras extra',
        addExtraGaps: 'Agregar palabras extra',
        editExtraGaps: 'Editar palabras extra',
        addExtraGapsSub: 'Escribe palabras extra para añadir dificultad a este ejercicio',
        editExtraGapsSub: 'Edita las palabras extra para añadir dificultad a este ejercicio',
        addGapOptions: 'Agregar opciones para el hueco: ',
        editGapOptions: 'Editar opciones para el hueco: ',
        word: 'Palabra',
        orderGaps: 'Ordenar palabras',
        noExtraGaps: 'No hay palabras extra para editar',
      },
      choice: {
        shuffleChoices: 'Mezclar opciones múltiples',
      },
      connecting: {
        shuffleConnectors: 'Mezclar conectores',
        connectors: 'Conectores',
        connector: 'Conector',
        connections: 'Conexiones',
        addConnector: 'Agregar conector',
        noEmptyConnector: 'No puede haber conectores vacíos',
        noConnections: 'Es necesario tener al menos una conexión',
        noConnectors: 'Es necesario tener al menos dos conectores',
        noConnectorsLeft: 'Es necesario tener al menos un conector en la columna izquierda',
        noConnectorsRight: 'Es necesario tener al menos un conector en la columna derecha',
      },
      segmentation: {
        createConnectorsAndConnections: 'Crear conectores y conexiones',
        createConnectorsAndConnectionsDescription: 'Haz clic en la imagen para agregar un conector. Puedes crear una conexión haciendo clic en los dos conectores que deseas conectar.',
        showOnlyLine: 'Mostrar solo línea',
      },
      table: {
        tableGrading: 'Opciones de calificación',
        gradeByRow: 'Por fila',
        gradeByTable: 'Por tabla',
        gradeByRowTip: 'El ejercicio se calificará por fila, lo que significa que el estudiante debe responder correctamente en cada columna de la fila, ya sea seleccionándola o no; si no, la fila se considera incorrecta.',
        gradeByTableTip: 'El ejercicio se calificará por tabla, lo que significa que cada celda debe responderse correctamente; de lo contrario, el ejercicio se considera incorrecto.',
        noEmptyHeaderCell: 'No puede haber celdas de encabezado principal vacías',
        headerPlaceholder: 'Encabezado',
        rowGradingExplanation: 'Calificado por fila',
        tableGradingExplanation: 'Calificado por tabla',
      },
      audio: {
        audioHeader: 'Agregar audio',
        addAudioLabel: 'Haz clic para agregar un audio',
        askForAudioError: '¡Tipo de archivo incorrecto! Solo inserta archivos de audio',
        noAudioChosen: 'No elegiste ningún archivo de audio',
        canPauseLabel: 'El estudiante puede pausar la reproducción del audio',
        canPause: 'Puede pausar',
        cannotPause: 'No puede pausar',
        limitRepetitionsLabel: 'Limitar la cantidad de veces que el estudiante puede repetir el audio',
        maxRepetitionsLabel: 'Repeticiones máximas',
        repetitions: 'repeticiones',
        retryMessage: 'Se produjo un error. Intenta reproducir el audio nuevamente',
      },
      video: {
        audioHeader: 'Agregar video',
        addAudioLabel: 'Haz clic para agregar un video',
        askForAudioError: '¡Tipo de archivo incorrecto! Solo inserta archivos de video',
        noAudioChosen: 'No elegiste ningún archivo de video',
        canPauseLabel: 'El estudiante puede pausar la reproducción del video',
        canPause: 'Puede pausar',
        cannotPause: 'No puede pausar',
        limitRepetitionsLabel: 'Limitar la cantidad de veces que el estudiante puede repetir el video',
        maxRepetitionsLabel: 'Repeticiones máximas',
        repetitions: 'repeticiones',
      },
      modelAnswer: 'Respuesta modelo',
      modelAnswerPlaceholder: 'Inserta aquí la respuesta modelo',
    },
    exercises: {
      title: 'Base de datos de ejercicios',
      subtitle: 'Todos los ejercicios creados',
      createNewExercise: 'Crear ejercicio',
      createNewExerciseSubtitle: 'Aquí vamos a crear un nuevo ejercicio',
      cancelCreation: 'Cancelar creación',
      cancelEdit: 'Cancelar edición',
      creatingNewExerciseOn: 'Creando un nuevo ejercicio en',
      editingExercise: 'Editando un ejercicio en',
      deleteExercise: 'Eliminar ejercicio',
      deleteExercises: 'Eliminar ejercicios',
      deleteExerciseInfo: '¿Estás seguro de que deseas eliminar este ejercicio?',
      deleteExercisesInfo: '¿Estás seguro de que deseas eliminar estos ejercicios?',
      deleteExerciseInfo2: 'Esta acción no es reversible',
      successDeleteExercise: 'El ejercicio se eliminó con éxito',
      successDeleteExercises: 'Los ejercicios se eliminaron con éxito',
      successDuplicateExercise: 'El ejercicio se duplicó con éxito',
      deleteErrorDoesNotExist: 'No se pudo realizar la eliminación porque el ejercicio ya no existe',
      previewExercise: 'Previsualizar ejercicio',
      previewErrorDoesNotExist: 'La vista previa no se pudo cargar porque el ejercicio ya no existe',
      noExercises: 'Aún no se han creado ejercicios.',
      noExercisesForFilters: 'No hay ejercicios para los filtros seleccionados.',
      gapList: 'Lista de palabras/expresiones:',
      gapListUnused: 'Lista de palabras/expresiones no utilizadas:',
      labels: {
        addLabel: 'Agregar etiqueta',
        createLabel: 'Crear etiqueta',
        repeatedLabelError: 'Ya existe una etiqueta con ese nombre',
        labelName: 'Nombre de la etiqueta',
        errorNoColorSelected: 'Es necesario establecer un color',
        errorNameRequired: 'Es necesario establecer un nombre',
        labels: 'Etiquetas',
        editLabels: 'Editar etiquetas',
        successEditLabel: 'La etiqueta se editó con éxito',
        successDeleteLabel: 'La etiqueta se eliminó con éxito',
      },
      filters: 'Filtros',
      teacherFilter: 'Profesores',
      typeFilter: 'Tipos de ejercicios',
      order: 'Ordenar',
      orderNewest: 'Más reciente',
      orderOldest: 'Más antiguo',
      orderLastModified: 'Última modificación',
      send: {
        sendExercise: 'Enviar ejercicio',
        sendExercises: 'Enviar ejercicios',
        keepCopy: 'Mantener original y enviar una copia',
        sendLabels: 'Enviar etiquetas al otro grupo',
        successSendExercise: 'El ejercicio se ha enviado con éxito',
        successSendExercises: 'Los ejercicios se han enviado con éxito',
        errorSendExerciseGroup: 'Debes elegir un grupo para enviar el ejercicio.',
        errorSendExercisesGroup: 'Debes elegir un grupo para enviar los ejercicios.',
        errorSendExercisesSchool: 'Debes elegir un espacio para enviar los ejercicios.',
        errorSendExercisesToMySpace: 'No puedes enviar ejercicios de otros usuarios a tu espacio personal.',
      },
      modelAnswer: 'Respuesta modelo',
    },
    attempt: {
      nextExercise: 'Siguiente ejercicio',
      nextItem: 'Siguiente',
      previousExercise: 'Ejercicio anterior',
      previousItem: 'Anterior',
      finishedAttempt: 'Terminado. Puedes cerrar la pestaña.',
      finishedAttemptIave: 'Prueba finalizada. Espera las instrucciones del profesor.',
      characterLimitReached: 'Se alcanzó el número máximo de caracteres',
      timesUp: 'El tiempo de la sección ha terminado',
      unsavedAnswers: 'Hubo un error al guardar tu(s) respuesta(s). Verifica tu conexión a internet e intenta guardar de nuevo',
      unsavedAnswersFinish: 'Tienes respuestas no guardadas. ¿Estás seguro de que deseas terminar?',
      answersSaved: 'Las respuestas se guardaron con éxito',
      errorSaveAnswers: 'Ocurrió un error al guardar las respuestas',
      exitPinModal: {
        pinLabel: 'Introduce el pin de salida del examen proporcionado por el supervisor',
        pinPlaceholder: 'Pin',
        modalHeader: 'Pin de salida del examen',
        wrongPin: 'El pin no es correcto',
        emptyPin: 'Debes introducir un pin para continuar',
      },
      oops: 'Se produjo un error al guardar tu(s) respuesta(s). Verifica tu conexión a internet e intenta nuevamente',
      continueWithoutSaving: 'Continuar sin guardar',
      saveAndContinue: 'Guardar y continuar',
      status: 'Estado',
      statusValid: 'Válido',
      statusInvalid: 'Inválido',
      statusPending: 'Pendiente',
      statusUpdated: 'Actualizado con éxito',
    },
    groups: {
      title: 'Grupos',
      teachers: 'profesores',
      teacher: 'profesor',
      createNewGroup: 'Crear grupo',
      groupName: 'Nombre del grupo',
      successCreateGroup: 'El grupo se creó con éxito',
      hintTargets: 'Este campo define qué profesores se agregarán al grupo',
      errorNameRequired: 'Es necesario establecer un nombre',
      noGroups: '¿Aún no tienes un grupo? ¡Crea uno y comienza a colaborar con otros profesores!',
      successEditGroup: 'El grupo se editó con éxito',
      userNotAllowed: 'El usuario ya ha excedido el límite de grupos a los que puede unirse',
    },
    group: {
      title: 'Grupo',
      leaveGroup: 'Dejar grupo',
      leaveGroupInfo: '¿Estás seguro de que deseas dejar este grupo?',
      leaveGroupInfo2: 'Esta acción no es reversible y perderás acceso a todo el contenido del grupo.',
      successLeaveGroup: 'Dejaste el grupo con éxito',
      removeTeacher: '¿Eliminar profesor?',
      removeTeacherInfo: '¿Estás seguro de que deseas eliminar a este profesor?',
      successRemoveTeacher: 'El profesor se eliminó con éxito',
      successAddTeacher: 'Los profesores se agregaron con éxito',
      addTeacher: 'Agregar profesores',
      hintTargets: 'Inserta los correos electrónicos de los profesores que deseas agregar al grupo',
      hintTargetsCreatable: 'Inserta los correos electrónicos de los profesores que deseas agregar al grupo. Puedes agregar correos electrónicos de profesores que no están en Intuitivo para invitarlos.',
      groupSettings: 'Configuraciones del grupo',
      deleteGroup: 'Eliminar grupo',
      deleteGroups: 'Eliminar grupos',
      deleteGroupInfo: '¿Estás seguro de que deseas eliminar el grupo? No podrás recuperar el contenido después. Esta acción no es reversible.',
      deleteGroupsInfo: '¿Estás seguro de que deseas eliminar estos grupos? No podrás recuperar el contenido después. Esta acción no es reversible.',
      successDeleteGroup: 'El grupo se eliminó con éxito',
      removeSelected: 'Eliminar seleccionado',
    },
    publication: {
      password: 'Contraseña de la prueba',
      testWindowClosedInfo: 'La ventana de apertura de la prueba está cerrada.',
      studentName: 'Nombre del estudiante',
      errorNameRequired: 'Es necesario insertar tu nombre.',
      errorEmail: 'El correo electrónico proporcionado es incorrecto.',
      passwordRequired: 'Es necesario proporcionar una contraseña.',
      emailHint: 'El correo electrónico insertado se usará para enviarte tu calificación y retroalimentación una vez que se publique.',
      continueAsGuest: 'Continuar como invitado',
      continueAs: (name) => `Continuar como ${name}`,
      notYou: '¿No eres tú?',
      enterAsNewStudent: 'Ingresar como un nuevo estudiante',
    },
    rubrics: {
      title: 'Rúbricas',
      noRubrics: 'Aún no se han creado rúbricas',
      previewRubric: 'Previsualizar rúbrica',
      performanceLevels: 'Niveles de desempeño',
      createRubric: 'Crear rúbrica',
      editRubric: 'Editar rúbrica',
      deleteRubric: 'Eliminar rúbrica',
      deleteRubrics: 'Eliminar rúbricas',
      deleteRubricInformation: '¿Estás seguro de que deseas eliminar esta rúbrica? Esta acción no es reversible.',
      deleteRubricsInformation: '¿Estás seguro de que deseas eliminar estas rúbricas? Esta acción no es reversible.',
      successDeleteRubric: 'La rúbrica se eliminó con éxito',
      successDeleteRubrics: 'Las rúbricas se eliminaron con éxito',
      successDuplicateRubric: 'La rúbrica se duplicó con éxito',
      form: {
        rubricNameLabel: 'Nombre de la rúbrica',
        rubricDescriptionLabel: 'Descripción de la rúbrica',
        criteriaNameLabel: 'Nombre del criterio',
        criteriaDescriptionLabel: 'Descripción del criterio',
        performanceLevelDescriptionLabel: 'Descripción del nivel de desempeño',
        addCriteria: 'Agregar criterio',
        discardRubric: 'Descartar rúbrica',
        nameRequiredError: 'Es necesario insertar un nombre para la rúbrica.',
        criteriaNameRequiredError: 'Es necesario insertar un nombre para el criterio.',
        criteriaRequiredError: 'Es necesario crear al menos un criterio para esta rúbrica.',
        createRubricSuccess: 'La rúbrica se creó con éxito',
        editRubricSuccess: 'La rúbrica se actualizó con éxito',
      },
      send: {
        sendRubric: 'Enviar rúbrica',
        sendRubrics: 'Enviar rúbricas',
        keepCopy: 'Mantener original y enviar una copia',
        successSendRubric: 'La rúbrica se ha enviado con éxito',
        successSendRubrics: 'Las rúbricas se han enviado con éxito',
        errorSendRubricGroup: 'Debes elegir un grupo para enviar la rúbrica.',
        errorSendRubricsGroup: 'Debes elegir un grupo para enviar las rúbricas.',
        errorSendRubricsSchool: 'Debes elegir un espacio para enviar las rúbricas.',
        errorSendRubricsToMySpace: 'No puedes enviar rúbricas de otros usuarios a tu espacio personal.',
      },
    },
    explore: {
      title: 'Explorar',
      header: 'Explora los contenidos disponibles',
      headerXs: 'Explorar',
      contentsListHeader: 'Contenidos más usados, según los filtros seleccionados',
      contentsListHeaderLg: 'Contenidos más usados',
      contentsListHeaderXs: 'Contenidos',
      noResultsHeader: 'Ups... No pudimos encontrar ningún contenido que coincida con tu búsqueda :(',
      noResultsDescription: 'Intenta buscar un término más general, cambia los filtros o verifica si hay algún error en las palabras clave de búsqueda.',
      noResultsDescription2: 'Si estos consejos no funcionan, te invitamos a crear nuevo contenido.',
      createNewExercise: 'Crear ejercicio',
      endMessage: 'Has visto todos los resultados correspondientes a tu búsqueda.',
      scrollBackToTopMessage: 'Desplázate hacia la parte superior de la página',
      searchPlaceholder: 'Buscar contenidos en Intuitivo...',
      addFilter: 'Agregar filtro',
      subjectAreas: 'Áreas de conocimiento',
      schoolYears: 'Años escolares',
      numberOfUses: 'Usos',
      copyInformation: '¿A qué grupo deseas copiar este ejercicio?',
      successCopyExercise: 'El ejercicio se copió con éxito a tu grupo',
      infoTip: 'Rellena tus preferencias en tu página de perfil para una experiencia más personalizada',
      searchAll: 'Todo el contenido',
    },
    profilePreferences: {
      setProfilePreferences: 'Antes de continuar, ayúdanos a mejorar tu experiencia.',
      saveProfilePreferences: 'Las preferencias se guardaron con éxito',
      warningProfilePreferences: 'Para cambiar tus preferencias ve a la página de "Cuenta"',
      subjects: '¿Qué asignaturas estás enseñando?',
      years: '¿Para qué grados?',
    },
    login: {
      loginWithMicrosoft: 'Iniciar sesión con Microsoft',
      poweredBy: 'Desarrollado por',
    },
    tours: {
      tip: 'Guías',
      title: '¿Necesitas ayuda?',
      startTour: 'Comenzar',
      subtitles: [
        'En esta sección puedes encontrar múltiples guías para ayudarte a interactuar con Intuitivo.',
        'Para empezar, simplemente selecciona una de las guías disponibles a continuación.',
      ],
      back: 'Atrás',
      next: 'Siguiente',
      complete: 'Completar',
      introduction: {
        title: 'Introducción',
        subtitle: 'Aprende los conceptos básicos de Intuitivo',
        steps: {
          introduction: 'Bienvenido a Intuitivo, hemos preparado una guía para ayudarte a comenzar. Para continuar haz clic en el botón "Siguiente". Si ya estás familiarizado con Intuitivo, puedes cerrar este mensaje.',
          testCard: 'Para ayudarte a comenzar con Intuitivo, hemos creado una evaluación simple para explorar juntos.',
          testCardPublishButton: 'Las evaluaciones publicadas se mostrarán con este icono de color verde. Si aún no está publicada, será gris.',
          testCardLeft: 'Haz clic en esta tarjeta para ver más detalles de esta evaluación.',
          tabExercises: 'En este momento, estás en la pestaña "Ejercicios". Aquí puedes ver qué ejercicios constituyen la evaluación.',
          tabGrade: 'Para esta guía, la evaluación ya ha sido publicada y ha tenido 2 presentaciones. Selecciona la pestaña "Corrección" para ver las presentaciones de los estudiantes',
          statistics: 'Puedes ver las estadísticas generales de esta evaluación.',
          attempts: 'También tienes acceso a esta lista de nombres y calificaciones de los estudiantes que han enviado sus respuestas para esta evaluación. Al hacer clic en un nombre, se mostrarán las respuestas del estudiante.',
          attemptContainer: 'Toda esta sección está dedicada al intento individual del estudiante seleccionado.',
          attemptHeader: 'El encabezado de cada intento contiene información sobre el estudiante, así como la calificación final y el enlace de presentación.',
          multipleChoice: 'Notarás que el estudiante ha respondido correctamente a este ejercicio de "Opción Múltiple".',
          multipleChoiceGrader: 'Intuitivo califica automáticamente algunos tipos de ejercicios, como es el caso de este ejercicio de "Opción Múltiple". El estudiante ha obtenido la puntuación máxima, sin embargo, puedes editar este valor libremente.',
          openQuestionGrader: 'Para ejercicios de "Texto", tendrás que calificarlos manualmente.',
          openQuestionFeedback: 'También puedes proporcionar retroalimentación por pregunta.',
          publishGradesButton: 'Una vez que hayas calificado la evaluación para todos los estudiantes, puedes publicar calificaciones y correcciones. Los estudiantes que hayan proporcionado una dirección de correo electrónico al comienzo de la prueba recibirán su calificación por correo electrónico.',
          tabStatistics: 'En la pestaña "Estadísticas", puedes tener una vista detallada del rendimiento de los estudiantes.',
          assessmentSidebarOption: 'Regresemos a la página de "Evaluaciones".',
          testCardDropdown: 'Cuando termines de explorar esta evaluación, puedes eliminarla en cualquier momento haciendo clic en este menú y eligiendo la opción "Eliminar".',
          hub: 'Puedes volver a hacer esta guía en cualquier momento. Te recomendamos que explores esta sección en caso de que necesites ayuda con la creación de evaluaciones y ejercicios.',
        },
      },
      assessments: {
        title: 'Evaluaciones',
        subtitle: 'Aprende a crear pruebas y hojas de trabajo',
        steps: {
          sidebarGoToPage: 'Primero, ve a la página de "Evaluaciones".',
          sidebar: 'Puedes acceder a las evaluaciones en la página "Evaluaciones".',
          createButton: 'Para crear una evaluación, haz clic en el botón "Crear Evaluación".',
          nameInput: 'Ponle un nombre a tu evaluación, algo como "Mi primera evaluación".',
          typeInput: `Elige un tipo de evaluación:
              <ul>
                <li><strong>Prueba:</strong> Tiene un límite de tiempo y está programada para una fecha específica.</li>
                <li><strong>Hoja de trabajo:</strong> Accesible hasta una fecha de entrega.</li>
              </ul>
              Dejemos la opción predeterminada "Prueba".`,
          classificationInput: `Elige un tipo de evaluación:
              <ul>
                <li><strong>Clásica:</strong> Las presentaciones de los estudiantes son calificadas.</li>
                <li><strong>Rúbrica:</strong> Tienen criterios de evaluación definidos (Puedes aprender más sobre las rúbricas en el tour de "Rúbricas").</li>
                <li><strong>Sin Clasificación:</strong> No se da calificación a las presentaciones de los estudiantes.</li>
              </ul>
              Dejemos la opción predeterminada "Clásica".`,
          groupInput: 'Puedes guardar esta evaluación en cualquier grupo al que pertenezcas. (Puedes aprender más sobre los grupos en el tour de "Grupos").',
          submitButton: 'Completa la creación de la evaluación enviando este formulario.',
          exercisesTab: 'Actualmente estás en la pestaña "Ejercicios". Aquí es donde puedes agregar contenido a tu evaluación, como ejercicios, secciones y bloques de información.',
          gradesTab: 'Cuando tu evaluación esté publicada y sea respondida por tus estudiantes, podrás ver sus presentaciones en la pestaña "Corrección".',
          physicalTab: 'En la pestaña "Versión Física", puedes descargar tu evaluación como un documento PDF.',
          contentForm: 'En esta sección puedes agregar contenido a tu evaluación.',
          createNewExercise: 'Hagamos exactamente eso. Haz clic en "Crear un nuevo ejercicio".',
          exerciseType: 'Puedes elegir qué tipo de ejercicio deseas crear.',
          trueOrFalseExerciseType: 'Elige la opción "Verdadero/Falso".',
          exerciseStatement: 'Escribe la declaración del ejercicio, algo como "¿Los delfines son mamíferos?"',
          exerciseCorrectAnswer: 'En esta sección puedes elegir la respuesta correcta.',
          exerciseTrue: 'En este caso, elijamos la opción "Verdadero".',
          exerciseSubmit: 'Todo está listo. Este ejercicio también se añadirá a tu biblioteca de "Ejercicios" (Puedes aprender más sobre los ejercicios en la guía de "Ejercicios"). Por ahora, haz clic en "Confirmar".',
          exerciseGrade: 'Puedes editar la calificación de ejercicios individuales.',
          exerciseEdit: 'Puedes editar un ejercicio en cualquier momento haciendo clic en este botón.',
          exerciseDelete: 'También se puede eliminar un ejercicio de una evaluación.',
          publishButton: 'Tu prueba está lista para ser publicada. Haz clic en el botón "Publicar".',
          publishFormFormat: 'Puedes elegir si quieres mostrar todos los ejercicios a la vez o tener solo un ejercicio visible a la vez.',
          publishFormStart: 'Elige una fecha de inicio para esta prueba.',
          publishFormTolerance: 'Elige el número de minutos que un estudiante puede llegar tarde a la prueba.',
          publishFormDuration: 'Elige cuánto tiempo tienen tus estudiantes para completar esta prueba',
          publishFormPassword: 'Puedes optar por proteger tu prueba estableciendo una contraseña que se solicitará cuando tus estudiantes comiencen tu prueba.',
          publishFormShuffle: 'Puedes elegir mezclar las preguntas de la prueba, de modo que todos tus estudiantes tengan una prueba diferente.',
          publishFormFeedback: 'También puedes permitir que tus estudiantes tengan acceso a las respuestas de la prueba después de enviarlas.',
          publishFormModelAnswer: 'Si se elige la opción de retroalimentación inmediata, puedes optar por permitir que tus estudiantes tengan acceso a las respuestas modelo de los ejercicios después de enviar la prueba.',
          publishFormSubmit: 'Todo está listo, ahora puedes publicar tu prueba haciendo clic en "Publicar".',
          publishLink: 'Felicidades, tu prueba ha sido publicada. Ahora solo necesitas compartir este enlace con tus estudiantes para que tomen la prueba.',
          publishCloseModal: 'Ahora puedes cerrar esta ventana.',
          publishButtonFinal: 'Puedes ver la ventana anterior nuevamente haciendo clic en este botón.',
          hub: 'Esta y otras guías están siempre disponibles en la sección de Guías.',
        },
      },
      explore: {
        title: 'Explorar',
        subtitle: 'Aprende a usar y adaptar el contenido de otros profesores.',
        steps: {
          sidebarExplore: 'Para comenzar esta guía, ve a la página "Explorar".',
          sidebar: 'Puedes acceder a las evaluaciones en la página "Explorar".',
          exerciseList: 'En esta página puedes encontrar todos los ejercicios y evaluaciones creados y hechos públicos por otras escuelas y profesores que usan Intuitivo. Para contribuir, simplemente guarda tu propio contenido como público durante la creación de ejercicios o evaluaciones.',
          header: 'En esta sección tienes acceso a una herramienta que te permite buscar contenidos públicos.',
          headerTextInput: 'Puedes buscar libremente palabras clave.',
          headerContentDropdown: 'También puedes filtrar por tipo de contenido: Evaluación, Ejercicios o ambos',
          headerFilters: 'También existe la opción de buscar por Año Escolar o Asignatura. Por defecto, se seleccionan tus preferencias de perfil (Puedes cambiarlas en la configuración de tu cuenta).',
          exerciseCardClick: 'Haz clic en el siguiente contenido para una vista detallada.',
          exerciseCardPreview: 'Aquí puedes previsualizar el contenido con más detalle',
          exerciseCardPreviewCopy: 'Para agregar este contenido a tu perfil solo necesitas hacer clic en "Copiar".',
          exerciseCardPreviewSelectGroup: 'Luego necesitas elegir el grupo de destino para este contenido.',
          exerciseCardPreviewSubmit: 'Y finalmente haz clic en "Confirmar".',
          exerciseCardClosed: 'Ahora puedes ver y editar libremente este contenido en la página de "Evaluaciones" o "Ejercicios", dependiendo del tipo.',
          hub: 'Puedes volver a esta y otras guías en cualquier momento, haciendo clic en el hub de "Guías".',
        },
      },
      groups: {
        title: 'Grupos',
        subtitle: 'Aprende a crear y usar grupos para colaborar con tus colegas',
        steps: {
          sidebarGoToPage: 'Para comenzar esta guía, ve a la página de "Grupos".',
          sidebar: 'Puedes acceder a los grupos en la página "Grupos".',
          createNewGroup: 'Para crear un nuevo grupo haz clic en el botón "Crear Grupo"',
          formNameInput: 'Nombra este grupo, por ejemplo "Profesores de Matemáticas - Año Junior',
          formTeachersInput: 'En esta sección puedes agregar a tus colegas que también están usando Intuitivo a través de su dirección de correo electrónico. Sin embargo, para el propósito de esta guía puedes dejar este campo en blanco.',
          formConfirm: 'Finaliza la creación de tu nuevo grupo haciendo clic en "Confirmar".',
          groupCard: 'Cada grupo se presentará como una tarjeta nombrada después de él. Haz clic en él para obtener una vista detallada.',
          teachersTable: 'En esta tabla puedes ver los nombres y direcciones de correo electrónico de todos los miembros del grupo.',
          addTeacherButton: 'Para agregar nuevos miembros, puedes hacer clic en este botón, que te presentará un formulario para agregar la dirección de correo electrónico de los nuevos miembros.',
          groupShortcuts: `
              <p>
                En esta tarjeta, puedes encontrar accesos directos a las páginas de "Evaluaciones", "Ejercicios" y "Rúbricas" para este grupo.
                En cada una de estas páginas encontrarás un desplegable para navegar entre grupos, como lo ilustra la siguiente imagen:
              </p>
              <img style="width: 100%" src="${process.env.REACT_APP_S3_URL}/resources/groups-header-shortcut-en.png" style="width: 100%">
            `,
          leaveGroup: 'Para dejar un grupo simplemente haz clic en este menú y selecciona la opción "Dejar Grupo".',
          hub: 'Puedes volver a esta y otras guías en cualquier momento, haciendo clic en el hub de "Guías".',
        },
      },
      rubrics: {
        title: 'Rúbricas',
        subtitle: 'Aprende a crear y usar rúbricas en tus evaluaciones',
        steps: {
          sidebarGoToPage: 'Para comenzar esta guía, ve a la página de "Rúbricas".',
          sidebar: 'Puedes acceder a las evaluaciones en la página "Rúbricas".',
          createNewButton: 'Para crear una nueva Rúbrica haz clic en "Crear rúbrica".',
          nameInput: 'Nombra tu rúbrica, por ejemplo "Criterios de Evaluación - Historia"',
          descriptionInput: 'Puedes escribir una descripción para agregar más contexto',
          criteriaCard: 'En esta sección puedes agregar todos los criterios que consideres necesarios. Los niveles de criterios van del 1 al 5.',
          criteria0NameInput: 'Nombra este criterio "Contenidos".',
          criteria0ValueInput: `
              A continuación, puedes agregar descriptores de rendimiento a esta rúbrica. Por ejemplo:
              <ul>
                <li><strong>(5):</strong> Explica completamente lo que se pretendía;</li>
                <li><strong>(4):</strong> Explica completamente lo que se pretendía parcialmente;</li>
                <li><strong>(3):</strong> Explica incompletamente lo que se pretendía;</li>
                <li><strong>(2):</strong> Explica incompletamente lo que se pretendía parcialmente;
                <li><strong>(1):</strong> Explica incompletamente solo parte de lo que se pretendía;</li>
              </ul>`,
          addCriteriaButton: 'Agrega un nuevo criterio haciendo clic en este botón',
          criteria1NameInput: 'Nombra este criterio "Comunicación".',
          criteria1ValueInput: 'Al igual que en el criterio anterior, puedes agregar los descriptores de rendimiento que consideres necesarios. Sin embargo, en nuestro caso puedes dejarlos en blanco.',
          criteria1RemoveButton: `
              Si deseas eliminar un criterio, simplemente haz clic en el icono
              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
              </svg>`,
          exerciseExample: `
              <p>
                Durante la corrección de una evaluación de tipo rúbrica, la evaluación de cada pregunta se realizará por criterios.
                De esta manera puedes atribuir un peso específico a cada criterio para cada ejercicio, como lo ilustra la siguiente imagen:
              </p>
              <img src="${process.env.REACT_APP_S3_URL}/resources/exercise-rubric-example-en.png" style="width: 100%">

              <p>Si necesitas, las guías de "Evaluaciones" e "Introducción" demuestran la creación, publicación y corrección de evaluaciones.</p>
            `,
          groupDropdown: 'Al igual que en otros ejemplos, puedes elegir un grupo para guardar esta rúbrica. La guía de "Grupos" contiene más información sobre esta característica.',
          saveButton: 'Finaliza la creación de esta rúbrica haciendo clic en "Guardar".',
          card: 'Una rúbrica creada se mostrará como tal.',
          cardActions: `
              En esta sección de la tarjeta de la rúbrica puedes:
              <ul>
                <li>Previsualizar;</li>
                <li>Editar;</li>
                <li>Enviar a otro grupo;</li>
                <li>Duplicar;</li>
                <li>Eliminar;</li>
              </ul>
            `,
          hub: 'Puedes volver a esta y otras guías en cualquier momento, haciendo clic en el hub de "Guías".',
        },
      },
      exercises: {
        title: 'Ejercicios',
        subtitle: 'Aprende a crear y usar ejercicios',
        steps: {
          sidebarGoToPage: 'Primero, ve a la página de "Ejercicios".',
          sidebar: 'Puedes acceder a los ejercicios en la página "Ejercicios".',
          createExerciseButton: 'Para crear un ejercicio, haz clic en el botón "Crear Ejercicio"',
          createExerciseType: 'Hay varios tipos de ejercicios para elegir. Te recomendamos que dediques algo de tiempo a explorar todos estos diferentes tipos. Si necesitas orientación, siempre puedes consultar nuestra página de <a href="https://intuitivo.pt/en/support/tutorials" target="_blank">Tutoriales</a>.',
          createExerciseTypeText: 'Para los fines de esta guía, crea un simple ejercicio de "Texto".',
          editorHeaderTypeDropdown: 'El primer paso para la creación del ejercicio está completo. Sin embargo, si necesitas cambiar el tipo de ejercicio, puedes hacerlo aquí.',
          editorHeaderGroupDropdown: 'Del mismo modo, puedes elegir en qué grupo se guardará este ejercicio. Para obtener más información sobre los grupos, hay una guía de "Grupos" disponible en la sección de guías.',
          editorStatement: `
              Escribe la declaración del ejercicio. Puedes agregar múltiples contenidos multimedia como imágenes, videos y audios. También tienes acceso a un editor de fórmulas matemáticas.
              <br/>
              Para este ejercicio de ejemplo, puedes escribir algo como <i>"¿Qué ciudad fue la capital del reino anglosajón de Wessex?"</i>.
            `,
          editorShortAnswer: 'Dependiendo del tipo de ejercicio, tendrás diferentes opciones de personalización disponibles. Para ejercicios de texto, puedes limitar el número de caracteres de la respuesta y permitir el acceso al editor de fórmulas matemáticas.',
          editorExtraText: 'En cada tipo de ejercicio, también puedes agregar texto adicional al ejercicio, que se mostrará debajo del campo de respuesta del estudiante.',
          editorModelAnswer: 'Dependiendo del tipo de ejercicio, también puedes agregar una respuesta modelo al ejercicio, que no se mostrará al estudiante durante la evaluación pero que puede presentarse opcionalmente después de que termine la evaluación.',
          editorPreview: 'Siempre tendrás una vista previa disponible. Esto es lo que verán tus estudiantes durante una evaluación.',
          editorSettingsFormTitle: `
              Para finalizar la creación del ejercicio, deberás darle un nombre.
              <br/>
              ¿Qué tal algo como <i>"La capital de Wessex"</i>?
            `,
          editorSettingsFormVisibility: `
              A continuación puedes elegir si deseas mantener tu ejercicio público o privado:
              <ul>
                <li><strong>Público:</strong> el ejercicio estará disponible en la página <i>"Explorar"</i> y puede ser copiado y utilizado por otros profesores.
                <li><strong>Privado:</strong> el ejercicio solo estará disponible para ti y los miembros del grupo.</li>
              </ul>
              Para obtener más información sobre la página de <i>"Explorar"</i> y <i>"Grupos"</i>, hay guías que te guiarán a través de esas características.
            `,
          editorSettingsFormVisibilityPrivate: 'Mantengamos el ejercicio privado. Selecciona la opción <i>"Privado"</i>.',
          editorLabelsButton: 'Si es necesario, puedes crear y agregar etiquetas para catalogar tus ejercicios. Este esfuerzo te ayudará a filtrar y buscar ejercicios.',
          editorHeaderSaveExercise: 'Finalmente, completa la creación del ejercicio haciendo clic en el botón "Guardar".',
          exerciseCard: 'Un ejercicio se mostrará de esta manera',
          exerciseCardActions: `
              En esta sección puedes:
              <ul>
                <li>Previsualizar el ejercicio;</li>
                <li>Editar;</li>
                <li>Enviar a otro grupo;</li>
                <li>Duplicar;</li>
                <li>Eliminar;</li>
              </ul>
            `,
          exerciseFilters: `
              También tienes la posibilidad de filtrar los ejercicios por:
              <ul>
                <li>Profesor autor;</li>
                <li>Tipo;</li>
                <li>Etiquetas;</li>
              </ul>
              Y ordenar por:
              <ul>
                <li>Más reciente;</li>
                <li>Más antiguo;</li>
                <li>Última modificación;</li>
              </ul>
            `,
          hub: 'Puedes volver a esta y otras guías en cualquier momento, haciendo clic en el hub de "Guías".',
        },
      },
    },
    plans: {
      premium: 'Premium',
      free: 'Básico',
      exceededTests: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'evaluación' : 'evaluaciones'} gratuitas creadas por ti en todos los grupos en los que participas. Elimina evaluaciones o actualiza a premium con una de las opciones a continuación.`,
      exceededTestsTip: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'evaluación' : 'evaluaciones'} gratuitas creadas por ti en todos los grupos en los que participas. Elimina evaluaciones o actualiza a premium.`,
      upgrade: 'ACTUALIZAR',
      getPremium: 'Obtener Premium',
      unlimitedAccessMessage: 'Desbloquea acceso ilimitado a Intuitivo individualmente.',
      recommendSchool: 'Recomendar Escuela',
      recommend: 'RECOMENDAR',
      dedicatedSpace: 'Desbloquea un espacio dedicado para tu escuela con acceso ilimitado a Intuitivo.',
      exceededExercises: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'ejercicio' : 'ejercicios'} gratuitos creados por ti en todos los grupos en los que participas. Elimina ejercicios o actualiza a premium con una de las opciones a continuación.`,
      exceededExercisesTip: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'ejercicio' : 'ejercicios'} gratuitos creados por ti en todos los grupos en los que participas. Elimina ejercicios o actualiza a premium.`,
      exceededRubrics: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'rúbrica' : 'rúbricas'} gratuitas creadas por ti en todos los grupos en los que participas. Elimina rúbricas o actualiza a premium con una de las opciones a continuación.`,
      exceededRubricsTip: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'rúbrica' : 'rúbricas'} gratuitas creadas por ti en todos los grupos en los que participas. Elimina rúbricas o actualiza a premium.`,
      exceededGroups: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'grupo' : 'grupos'} gratuitos. Deja grupos o actualiza a premium con una de las opciones a continuación.`,
      exceededSections: (limit) => `Has superado el límite de ${limit} ${limit === 1 ? 'sección' : 'secciones'} gratuitas por prueba. Elimina secciones o actualiza a premium con una de las opciones a continuación.`,
      premiumFeature: 'Esta es una función premium. Actualiza a premium para desbloquearla',
      warningCreatePremiumExercise: 'Puedes crear pero no puedes publicar una evaluación con este tipo de ejercicios.',
      warningPublishTestWithPremiumExercise: 'No puedes publicar una evaluación que contenga ejercicios premium. Elimina esos ejercicios o actualiza a premium con una de las opciones a continuación.',
      warningPublishTestWithExceededSections: (limit) => `No puedes publicar una evaluación que contenga más de ${limit} ${limit === 1 ? 'sección' : 'secciones'}. Elimina secciones o actualiza a premium con una de las opciones a continuación.`,
      warningExportTestMaxUsages: (limit) => `Solo puedes exportar hasta ${limit} ${limit === 1 ? 'versión' : 'versiones'}. Actualiza a premium para desbloquear más.`,
      schools: 'Escuelas',
    },
    navigation: {
      title: 'Navegación',
      section: 'Sección',
    },
    curricula: {
      pt_PT: 'Portugués',
      other: 'Otro',
      curriculum: 'Plan de estudios',
    },
  },
  ptBR: {
    ok: 'Ok',
    add: 'Adicionar',
    create: 'Criar',
    publish: 'Publicar',
    published: 'Publicado',
    submit: 'Enviar',
    hide: 'Esconder',
    preview: 'Pré-visualizar',
    edit: 'Editar',
    cancel: 'Cancelar',
    erase: 'Apagar',
    'delete': 'Excluir',
    remove: 'Remover',
    duplicate: 'Duplicar',
    discard: 'Descartar',
    clear: 'Limpar',
    saveChanges: 'Salvar alterações',
    save: 'Salvar',
    copy: 'Copiar',
    oops: 'Algo deu errado. Por favor, tente novamente',
    waitSeconds: 'Este processo pode levar alguns segundos',
    waitMinutes: 'Este processo pode levar alguns minutos',
    waitTime: 'Este processo pode levar algum tempo',
    date: 'Data',
    previous: 'Anterior',
    next: 'Próximo',
    'export': 'Exportar',
    en: 'Inglês',
    pt: 'Português',
    esES: 'Espanhol',
    ptBR: 'Português (Brasil)',
    unauthorizedMessage: 'Parece que você ainda não está qualificado para estar nesta área... Talvez no próximo ano!',
    notFoundMessage: 'Oh não! Parece que você se perdeu no nosso site... Talvez não seja tão intuitivo.',
    errorMessage: 'Algo deu errado... Estaremos trabalhando para corrigir o mais breve possível.',
    noPermissionSpace: 'Você não tem permissão para acessar este espaço.',
    home: 'Início',
    account: 'Conta',
    signout: 'Sair',
    finished: 'Concluído',
    example: 'Exemplo',
    'private': 'Privado',
    confirm: 'Confirmar',
    total: 'Total',
    optional: 'opcional',
    workInProgress: 'Estamos trabalhando para melhorar este recurso',
    enable: 'Ativar',
    disable: 'Desativar',
    noOptionsAvailable: 'Nenhuma opção disponível',
    close: 'Fechar',
    'continue': 'Continuar',
    'in': 'em',
    goBack: 'Voltar',
    name: 'Nome',
    email: 'E-mail',
    shortcuts: 'Atalhos',
    leave: 'Sair',
    send: 'Enviar',
    comingSoon: 'Em breve!',
    comment: 'Comentar',
    help: 'Ajuda',
    feedback: 'Feedback',
    copied: 'Copiado',
    noEmail: 'Sem e-mail',
    selectAll: 'Selecionar tudo',
    general: 'Geral',
    discardWarning: 'Se você continuar, todas as adições serão perdidas.',
    notFound: 'Não encontrado',
    copyOf: 'Cópia de',
    otherOptions: 'Outras opções',
    reschedule: 'Reagendar',
    results: 'Resultados',
    'new': 'Novo',
    filterBy: 'Filtrar por',
    copies: 'Cópias',
    orderBy: 'Ordenar por',
    oldest: 'Mais antigo',
    newest: 'Mais recente',
    relevancy: 'Relevância',
    showMore: 'Mostrar mais',
    showLess: 'Mostrar menos',
    settings: 'Configurações',
    scrollToSides: 'Rolar para os lados',
    download: 'Baixar',
    fileTooLarge: 'O arquivo não pode ser maior que 10Mb',
    fileTooLarge1Gb: 'O arquivo não pode ser maior que 1Gb',
    drag: 'Arrastar',
    dropHere: 'Solte aqui',
    changeLang: 'Mudar idioma',
    logout: 'Sair',
    video: 'Vídeo',
    audio: 'Áudio',
    tutorial: 'Tutoriais',
    continueWith: 'Continuar com',
    or: 'Ou',
    notYou: 'Não é sua conta?',
    guest: 'Convidado',
    select: 'Selecionar',
    internetWentWrong: 'Algo deu errado. Por favor, verifique sua conexão com a internet e tente novamente',
    openFormatting: 'Abrir formatação',
    openMedia: 'Abrir mídia',
    appNotAvailable: 'O site não está mais disponível pelo navegador. Por favor, use o aplicativo para acessar os exames.',
    generateDocument: 'Gerar documento',
    refresh: 'Atualizar',
    seeMore: 'Ver mais',
    deleteDisabled: 'Você não pode excluir o conteúdo de outros usuários',
    andXMore: (x) => `E mais ${x}...`,
    words: 'Palavras',
    contentSettings: {
      'public': 'Público',
      'private': 'Privado',
    },
    common: {
      edit: 'Editar',
      cancel: 'Cancelar',
      discard: 'Descartar',
      finish: 'Concluir',
      goBack: 'Voltar',
      somethingWentWrong: 'Algo deu errado. Por favor, tente novamente',
      internetWentWrong: 'Algo deu errado. Por favor, verifique sua conexão com a internet e tente novamente',
      createdBy: 'Criado por',
      publish: 'Publicar',
      published: 'Publicado',
      name: 'Nome',
      fontSize: {
        small: 'Pequeno',
        normal: 'Normal',
        large: 'Grande',
        huge: 'Enorme',
      },
    },
    time: {
      day: 'dia',
      days: 'dias',
      hour: 'hora',
      hours: 'horas',
      minute: 'minuto',
      minutes: 'minutos',
      second: 'segundo',
      seconds: 'segundos',
    },
    appKeywords: {
      classes: 'Aulas',
      tests: 'Testes',
      test: 'Teste',
      threads: 'Tópicos',
      admin: 'Administrador',
      lesson: 'Lição',
      subject: 'Matéria',
      'class': 'Turma',
      exercises: 'Exercícios',
      exercise: 'Exercício',
      correction: 'Correção',
      answer: 'Resposta',
      grade: 'Nota',
      group: 'Grupo',
      groups: 'Grupos',
      personalGroup: 'Meu conteúdo',
      allGroups: 'Todos os grupos',
      personalSpace: 'Espaço pessoal',
      students: 'Alunos',
      premium: 'Premium',
      premiumFreeTrial: 'Premium (teste gratuito)',
      premiumInfo: 'Período experimental por tempo indeterminado. No momento, você pode usar a plataforma e todas as suas funcionalidades gratuitamente. Mais tarde, introduziremos diferentes planos pagos para professores, mas sempre haverá um plano gratuito, apesar de algumas limitações em termos de funcionalidades.',
      rubrics: 'Rubricas',
      rubric: 'Rubrica',
      criterion: 'Critério',
      criteria: 'Critérios',
      classic: 'Clássico',
      noClassification: 'Sem classificação',
      worksheet: 'Folha de exercícios',
      assessment: 'Avaliação',
      assessments: 'Avaliações',
      spaces: 'Espaços',
      space: 'Espaço',
      statistics: 'Estatísticas',
      teachers: 'Professores',
      events: 'Eventos',
      exams: 'Exames',
      examGroupsMin: 'Grupos de exames',
    },
    tests: {
      title: 'Testes',
      teacher: {
        subtitle: 'Todos os testes criados para as turmas que você leciona',
        exercise: 'item',
        exercises: 'itens',
        resolution: 'resolução',
        resolutions: 'resoluções',
        publish: 'Publicar',
        grades: 'Notas',
        stateUnpublished: 'Não publicado',
        stateUpcoming: 'Agendado',
        stateOngoing: 'Em andamento',
        stateComplete: 'Concluído',
        noTests: 'Nenhum teste foi criado ainda',
      },
      student: {
        subtitle: 'Todos os testes feitos pelos seus professores',
        noTests: 'Atualmente, não há testes disponíveis para você',
        opensIn: 'Abre em',
        openingClosesAt: 'Abertura fecha em',
        testEndsAt: 'O teste termina em',
        testNotOpen: 'O teste ainda não foi aberto',
        testWindowClosed: 'A janela de abertura do teste foi fechada. Não é mais possível abrir o teste',
        testComplete: 'Você já completou o teste. Todas as suas respostas foram salvas',
        testCompleteTime: 'O teste já terminou',
        testPasswordModal: 'Senha do teste',
        testWrongPassword: 'A senha está errada',
      },
    },
    test: {
      title: 'Teste',
      duplicateTest: 'Duplicar teste',
      question: 'Item',
      informationBlock: 'Bloco de informações',
      information: 'Informação',
      'true': 'Verdadeiro',
      'false': 'Falso',
      textPlaceholder: 'Escreva sua resposta aqui',
      justificationLabel: 'Justificativa',
      justificationPlaceholder: 'Escreva sua justificativa aqui',
      dragImageHint: 'Arraste e solte imagens aqui, ou clique para selecionar imagens',
      dropImageHint: 'Solte para adicionar',
      addQuestion: 'Adicionar conteúdo',
      selectExercises: 'Selecionar exercícios',
      addExercises: 'Adicionar exercícios',
      selectedExercises: 'exercícios selecionados',
      successAddExercises: 'Os exercícios foram adicionados com sucesso',
      successAddExercise: 'O exercício foi adicionado com sucesso',
      successAddInformationBlock: 'O bloco de informações foi adicionado com sucesso',
      successDuplicateTest: 'A avaliação foi duplicada com sucesso',
      errorAddExists: 'Não é possível adicionar exercícios que já foram adicionados ao teste',
      selectExercisesFromOtherSpace: 'Você só pode usar exercícios criados por você se selecionar outros espaços!',
      selectRubricsFromOtherSpace: 'Você só pode usar rubricas criadas por você se selecionar outros espaços!',
      createNewExercise: 'Criar novo exercício',
      addInformationBlock: 'Adicionar bloco de informações',
      selectExerciseFromDb: 'Selecionar exercício do banco de dados',
      thisTestIsInvalid: 'Este teste é inválido',
      youAlreadyCompleted: 'Você já completou este teste antes',
      deleteTest: 'Excluir avaliação?',
      deleteTestInfo: 'Tem certeza de que deseja excluir esta avaliação?',
      deleteTestInfo2: 'Esta ação não é reversível.',
      successDeleteTest: 'A avaliação foi excluída com sucesso',
      deleteErrorDoesNotExist: 'A exclusão não pôde ser realizada porque a avaliação não existe',
      timeRemaining: 'Tempo restante',
      finishTest: 'Finalizar',
      finishHintConfirm: 'Tem certeza de que deseja finalizar agora?',
      finishHintIncomplete: 'Ainda há itens a serem respondidos. Tem certeza de que deseja finalizar agora?',
      finishTestSuccess: 'Você terminou. Bom trabalho!',
      finishTestEnded: 'Você já terminou antes',
      finishErrorNetwork: 'Ocorreu um erro ao finalizar. Verifique sua conexão com a internet e tente novamente',
      answerError: (num) => `Ocorreu um erro ao salvar a resposta do item ${num}. Por favor, tente novamente.`,
      answerErrorNetwork: (num) => `Ocorreu um erro ao salvar a resposta do item ${num}. Verifique sua conexão com a internet e tente novamente.`,
      answered: 'Respondido',
      attemptWindow: 'Janela de abertura',
      duration: 'Duração',
      stateScheduled: 'Agendado',
      stateOngoing: 'Em andamento',
      stateFinished: 'Finalizado',
      moveUp: 'Mover para cima',
      moveDown: 'Mover para baixo',
      errorMovePublished: 'A ordem não pôde ser alterada porque o teste está publicado. Despublique se desejar fazer alterações',
      hintMoveNotPossible: 'Essa movimentação não é possível',
      removeFromTest: 'Remover do teste',
      removeExerciseFromTest: 'Remover exercício da avaliação',
      removeInformationBlockFromTest: 'Remover bloco de informações da avaliação',
      removeSectionFromTest: 'Remover seção da avaliação',
      hintConfirmRemove: 'Tem certeza de que deseja remover este exercício da avaliação?',
      hintConfirmRemoveInformationBlock: 'Tem certeza de que deseja remover este bloco de informações da avaliação?',
      hintConfirmRemoveSection: 'Tem certeza de que deseja remover esta seção da avaliação?',
      errorRemovePublished: 'O exercício não pôde ser removido porque o teste está publicado. Despublique se desejar fazer alterações',
      link: 'Link',
      copied: 'Copiado',
      password: 'Senha',
      compacted: 'Compacto',
      presentationFull: 'Completa',
      presentationIncremental: 'Incremental',
      navigationLinear: 'Linear',
      navigationNonLinear: 'Não Linear',
      presentationFullTip: 'Todos os itens visíveis ao mesmo tempo',
      presentationIncrementalTip: 'Apenas um item visível por vez',
      navigationLinearTip: 'Não sendo possível voltar para atualizar uma resposta',
      navigationNonLinearTip: 'Sendo possível voltar para atualizar uma resposta',
      header: {
        exportPhysical: 'Versão física',
      },
      testForm: {
        createTest: 'Criar avaliação',
        editTest: 'Editar teste',
        testName: 'Nome da avaliação',
        errorNameRequired: 'É obrigatório definir um nome',
        errorSubjectRequired: 'É obrigatório escolher uma disciplina',
        errorGroupRequired: 'É obrigatório escolher um grupo',
        errorNoExercises: 'É obrigatório adicionar pelo menos um exercício',
        successCreateTest: 'O teste foi criado com sucesso',
        successEditTest: 'O teste foi atualizado com sucesso',
        classificationTypeLabel: 'Tipo de classificação',
        whoCanSee: 'Quem pode ver este teste?',
        privacyTypeLabel: 'Tipo de privacidade',
        classicClassificationTypeTip: 'Os exercícios são avaliados e uma nota final é atribuída',
        rubricClassificationTypeTip: 'O nível dos alunos é avaliado através dos critérios definidos',
        noClassificationTypeTip: 'Os exercícios não são avaliados e não há nota final',
        testTypeLabel: 'Tipo de avaliação',
        testTypeTip: 'Avaliação com tempo limitado e agendada para uma data específica',
        worksheetTypeTip: 'Avaliação que fica disponível até a data de entrega',
        'public': 'Público',
        publicTip: 'Visível na página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visível apenas para você ou para os grupos com os quais você compartilha o exercício',
      },
      publishModal: {
        testFormat: 'Formato da avaliação',
        navigationType: 'Tipo de navegação',
        errorTypeRequired: 'É obrigatório escolher um tipo de teste',
        testShuffleLabel: 'Embaralhar a ordem dos exercícios',
        shuffleNoneLabel: 'Não embaralhar nada',
        shuffleBaseLabel: 'Embaralhar seções e exercícios fora das seções, mantendo a ordem dos exercícios dentro das seções',
        shuffleSectionsLabel: 'Manter a ordem das seções e exercícios fora das seções, embaralhar exercícios dentro das seções',
        shuffleAllLabel: 'Embaralhar todas as seções, exercícios fora das seções e exercícios dentro das seções',
        publishTest: 'Publicar avaliação',
        unpublishTest: 'Despublicar avaliação',
        rescheduleTest: 'Reagendar avaliação',
        editTest: 'Editar avaliação',
        editPublication: 'Editar publicação',
        successUnpublishTest: 'O teste foi despublicado com sucesso',
        beforePublishing: 'Antes de publicar...',
        labelStartsAt: 'Data e hora de início',
        labelEndsAt: 'Data e hora de término',
        labelAttemptWindow: 'Janela de abertura (em minutos)',
        labelDuration: 'Duração da resolução (em minutos)',
        labelPassword: 'Senha',
        labelExitPin: 'PIN de saída',
        hintExitPin: 'Este campo define um PIN de saída para os alunos que utilizam o aplicativo de prova',
        labelTargets: 'Turmas e alunos',
        hintAttemptWindow: 'Este campo define quantos minutos os alunos têm para iniciar o teste',
        hintTargets: 'Este campo define quais turmas e alunos podem abrir o teste',
        errorStartsAtRequired: 'É obrigatório definir uma data de início',
        errorStartsAtPast: 'A data de início deve ser no futuro',
        errorEndsAtRequired: 'É obrigatório definir uma data de término',
        errorEndsAtBeforeStartsAt: 'A data de término deve ser posterior à data de início',
        errorAttemptWindowRequired: 'É obrigatório definir uma janela de abertura',
        errorAttemptWindowBoundaries: 'Este valor deve estar entre 1 e 4320 (3 dias)',
        errorDurationRequired: 'É obrigatório definir uma duração',
        errorDurationBoundaries: 'Este valor deve estar entre 1 e 1440 (1 dia)',
        errorEditDuration: (duration) => `A duração deve ser maior que ${duration} minutos`,
        successPublishTest: 'A avaliação foi publicada com sucesso',
        successRescheduleTest: 'O teste foi reagendado com sucesso',
        successEditPublication: 'A publicação foi atualizada com sucesso',
        publishErrorDoesNotExist: 'O teste não pôde ser publicado porque não existe',
        publishErrorNoLesson: 'O teste não pôde ser publicado porque deve pertencer a uma turma',
        publishErrorNoExercises: 'O teste não pôde ser publicado porque não contém exercícios',
        publishErrorAlreadyPublished: 'O teste não pôde ser publicado porque já está publicado',
        republishErrorNotPublished: 'O teste não pôde ser reagendado porque não está publicado',
        publishErrorAlreadyStarted: 'Não foi possível atualizar as informações de publicação do teste porque ele já começou ou começa em menos de 1 minuto',
        unpublishErrorDoesNotExist: 'O teste não pôde ser despublicado porque não existe',
        unpublishErrorAlreadyUnpublished: 'O teste não pôde ser despublicado porque já está despublicado',
        publishPreHint: 'Antes de ser possível publicar, esta avaliação deve:',
        hintHaveExercises: 'Ter pelo menos 1 exercício',
        errorTargetsRequired: 'É obrigatório escolher pelo menos uma turma ou um aluno',
        hintInformationBlocksShuffle: 'Todos os blocos de informação permanecerão no mesmo lugar, apenas os exercícios serão embaralhados. Se você estiver usando um bloco de informação para dar contexto aos exercícios seguintes, considere desmarcar esta opção.',
        hintHasRubric: 'Ter uma rubrica selecionada',
        hintAreWeightsCorrect: 'Ter o total dos pesos por exercício igual a 100%',
        hintSectionsHaveExercises: 'Ter pelo menos 1 exercício em cada seção',
        showRubricLabel: 'Mostrar a rubrica aos alunos durante o teste',
        opensIn: 'Abre em:',
        endsIn: 'Termina em:',
        scheduledFor: 'Agendado para',
        openedOn: 'Aberto em',
        endedOn: 'Terminado em',
        endsOn: 'Termina em',
        immediateFeedbackLabel: 'Feedback imediato: o aluno pode ver o resultado e as soluções imediatamente após o fim do teste',
        modelAnswerLabel: 'Resposta modelo: o aluno pode ver a resposta modelo após o fim do teste',
        linearNavigation: 'Navegação linear: O aluno não pode voltar aos itens anteriores',
        nonLinearNavigation: 'Navegação não linear: O aluno pode voltar aos itens anteriores',
        createAttempts: 'Criar tentativas',
        successCreateAttempts: 'As tentativas foram criadas com sucesso',
      },
      grades: {
        noAttempt: 'Sem submissão',
        point: 'Ponto',
        points: 'Pontos',
        defineGrade: 'Definir nota',
        assignGrade: 'Atribuir nota',
        maxValueExceeded: 'O valor da nota não pode exceder o valor máximo definido',
        notEmpty: 'O valor da nota não pode estar vazio',
        noStudents: 'Atualmente, não há alunos atribuídos a esta avaliação.',
        maxValueLabel: 'Máximo:',
        negativeValueError: 'Os pontos atribuídos a um exercício não podem ser negativos',
        testOf: 'Teste de',
        generateGrades: 'Gerar notas',
        successUpdatePoints: 'Os pontos do exercício foram atualizados com sucesso!',
        successUpdatePointsWarn: 'Os pontos do exercício foram atualizados com sucesso! Não se esqueça de gerar as notas novamente',
        pointsTooHigh: 'Os pontos atribuídos ao exercício são muito altos!',
        studentName: 'Nome do aluno',
        noAnswersYet: 'Nenhum aluno tentou a avaliação até agora para gerar notas',
        submission: 'Submissão',
        successUpdateGrade: 'A nota foi atualizada com sucesso!',
        submissionLink: 'Link da submissão',
        publishCorrections: 'Publicar correções',
        correctionsPublished: 'Correções publicadas',
        exportResolutions: 'Exportar resoluções dos alunos',
        addComment: 'Adicionar comentário',
        successUpdateFeedback: 'O comentário foi atualizado com sucesso!',
        unavailable: 'Isso ficará disponível quando o teste terminar',
        unavailableStudent: 'Isso ficará disponível quando o aluno terminar o teste',
        publishCorrectionsInformation: 'Um e-mail será enviado a cada aluno notificando que sua avaliação foi corrigida. O e-mail conterá um link onde poderão ver a tentativa e o feedback.',
        successUpdateCriteriaGrade: 'A nota do critério foi atualizada com sucesso',
        criteriaGradeNotEmptyError: 'A nota do critério não pode estar vazia',
        criteriaGradeNegativeValueError: 'A nota do critério não pode ter valor negativo',
        criteriaGradeTooHighError: 'A nota do critério não pode ser superior a 5',
        exportAttemptsCSV: 'Exportar resoluções CSV',
        exportGrades: 'Exportar notas Excel',
        deleteAttemptUnavailable: 'Este aluno ainda não iniciou uma tentativa.',
        deleteAttemptConfirm: (name) => `Tem certeza de que deseja excluir a tentativa de ${name}?`,
        deleteAttemptConfirmSubtitle: 'Esta ação não é reversível.',
        successDeleteAttempt: 'A tentativa foi excluída com sucesso.',
        studentSearch: 'Buscar alunos',
        noStudentsFound: 'Nenhum aluno encontrado',
        showModelAnswer: 'Resposta modelo: o aluno pode ver a resposta modelo ao revisar a avaliação',
        notFinished: 'Não finalizado',
        attemptDates: 'Iniciado em - Finalizado em',
        annotate: 'Nota',
        saveAnnotation: 'Salvar notas',
      },
      stats: {
        classAverage: 'Média',
        classMedian: 'Mediana',
        averageInfo: 'O valor da média é calculado somando todos os resultados do teste e dividindo pelo número de testes',
        medianInfo: 'A mediana é o valor intermediário obtido quando todos os resultados do teste são ordenados do menor para o maior',
        positive: 'Positivo',
        negative: 'Negativo',
        highest: 'Maior nota',
        lowest: 'Menor nota',
        question: 'Questão',
        sortOrder: 'Ordenar: ',
        lowToHigh: 'Crescente',
        highToLow: 'Decrescente',
        avrgGrade: 'Nota média',
        avrgNormalized: 'Nota média normalizada',
        avrgNormalizedInfo: 'Ordenar questões pela nota média normalizada em ordem crescente ou decrescente',
        avrgQuestionInfo: 'A nota média obtida nesta questão',
        premiumStats: 'Obtenha uma assinatura premium para ver estatísticas mais avançadas',
        bestQuestion: 'Questão com as melhores respostas',
        worstQuestion: 'Questão com as piores respostas',
        bestQuestionShort: 'Melhor questão',
        worstQuestionShort: 'Pior questão',
        noAnswer: 'Sem resposta',
        distributionChart: 'Número de resultados',
        choices: 'Múltipla escolha',
        ascSort: 'Clique para ordenar de forma crescente',
        descSort: 'Clique para ordenar de forma decrescente',
        cancelSort: 'Clique para cancelar a ordenação',
        fastestTime: 'Tempo mais rápido',
        slowestTime: 'Tempo mais lento',
        averageTime: 'Tempo médio',
        inProgress: 'Em andamento...',
        studentName: 'Nome do aluno',
        studentGrade: 'Nota',
        studentRank: 'Classificação',
        previewAnswer: 'Pré-visualizar exercício',
        studentCount: 'Número de alunos',
        gradeCount: 'Número de notas',
        gradeCountTip: 'Nos intervalos representados, como de 0% a 10%, o limite inferior (0%) está incluído na barra e o limite superior não está, exceto no último intervalo, de 90% a 100%, que inclui ambos.',
      },
      physicalVersion: {
        physicalVersion: 'Versão Física',
        exportTest: 'Exportar avaliação',
        generalSettings: 'Configurações gerais',
        versionsLabel: 'Número de versões',
        versionsPlaceholder: 'Insira o número de versões',
        localeLabel: 'Idioma',
        shuffleExercisesLabel: 'Embaralhar ordem dos exercícios',
        shuffleChoicesLabel: 'Embaralhar ordem das opções nas questões de múltipla escolha',
        noExercises: 'O teste precisa de pelo menos um exercício para ser exportado',
        notEnoughExercises: 'Não há exercícios suficientes para atender às versões solicitadas.',
        headerSettings: 'Configurações de cabeçalho',
        studentInput: 'Preenchimento pelo aluno',
        testName: 'Nome da avaliação',
        testNamePlaceholder: 'Insira aqui o nome da avaliação',
        testNameEmptyError: 'O nome da avaliação não pode estar vazio',
        displayVersion: 'Exibir versão da avaliação',
        schoolName: 'Nome da escola',
        schoolNamePlaceholder: 'Insira aqui o nome da escola',
        schoolNameEmptyError: 'O nome da escola não pode estar vazio',
        schoolLogo: 'Logo da escola',
        schoolLogoPlaceholder: 'Arraste ou clique aqui para inserir uma imagem',
        schoolLogoEmptyError: 'Você deve inserir uma imagem para o logo da escola',
        subject: 'Disciplina',
        subjectPlaceholder: 'Insira aqui o nome da disciplina',
        subjectEmptyError: 'O nome da disciplina não pode estar vazio',
        testDuration: 'Duração da avaliação (em minutos)',
        testDurationPlaceholder: 'Insira aqui a duração da avaliação',
        testDurationEmptyError: 'A duração do teste não pode estar vazia',
        schoolYear: 'Ano escolar',
        schoolYearPlaceholder: 'Insira aqui o ano escolar',
        schoolYearEmptyError: 'O ano escolar não pode estar vazio',
        'class': 'Turma',
        classPlaceholder: 'Insira aqui a turma',
        classEmptyError: 'A turma não pode estar vazia',
        date: 'Data da avaliação',
        datePlaceholder: 'Insira aqui a data da avaliação',
        dateEmptyError: 'A data da avaliação não pode estar vazia',
        exercisesSettings: 'Configurações dos exercícios',
        includeZone: 'Incluir zona de respostas',
        askForName: 'Nome do aluno',
        askForCode: 'Código do aluno',
        askForGrade: 'Nota do aluno',
        extraFields: 'Campos extras',
        extraFieldButton: 'Adicionar novo campo',
        extraFieldLabel: 'Nome do campo:',
        extraFieldLabelPlaceholder: 'Insira aqui o nome do campo',
        customFieldEmptyLabelError: 'Você deve inserir um nome para o campo',
        extraFieldContent: 'Conteúdo do campo:',
        extraFieldContentPlaceholder: 'Insira aqui o conteúdo do campo',
        customFieldEmptyContentError: 'Você deve inserir o conteúdo do campo',
        numberOfLines: 'Número de linhas de resposta',
        numberOfLinesJustify: 'Número de linhas de justificativa',
        showLines: 'Mostrar linhas',
        includeRubric: 'Incluir rubrica nas versões exportadas',
        noRubric: 'Você ainda não selecionou uma rubrica',
        sectionWithoutExercises: 'Esta seção não tem exercícios',
      },
      attemptsExport: {
        includeSchoolLogo: 'Incluir logo da escola',
        schoolLogoPlaceholder: 'Arraste ou clique aqui para inserir uma imagem',
        schoolLogoEmptyError: 'Você deve inserir uma imagem para o logo da escola',
        includeGrade: 'Incluir nota',
        includeRubric: 'Incluir rubrica',
        waitWhileGenerateDocuments: 'Aguarde enquanto geramos os documentos',
        clickBelowToDownloadDocuments: 'Clique abaixo para baixar os documentos',
        exportAttemptsPDF: 'Exportar tentativas em PDF',
        generatingPDFs: 'Gerando os PDFs',
        zippingPDFs: 'Compactando os PDFs',
        generateSuccessDescription: 'Os exames foram exportados com sucesso para PDF! Clique no botão abaixo para baixar.',
        includeModelAnswer: 'Incluir resposta modelo',
        exportAttemptsNotAvailable: 'Não há tentativas para exportar',
        downloadAttempts: 'Baixar tentativas',
        downloadValidatedExplanation: 'Apenas tentativas validadas serão exportadas',
      },
      send: {
        sendTest: 'Enviar avaliação',
        keepCopy: 'Manter original e enviar uma cópia',
        successSendTest: 'A avaliação foi enviada com sucesso',
        errorSendTestGroup: 'Você deve escolher um grupo para enviar a avaliação.',
        errorSendTestSchool: 'Você deve escolher um espaço para enviar a avaliação.',
        errorSendTestToMySpace: 'Você não pode enviar avaliações de outros usuários para o seu espaço pessoal.',
      },
      settingsModal: {
        name: 'Nome',
        successEditSettings: 'As configurações do teste foram atualizadas com sucesso',
      },
      rubric: {
        addRubric: 'Adicionar rubrica',
        selectRubricFromBank: 'Selecionar rubrica do banco',
        successSetRubric: 'A rubrica foi adicionada com sucesso',
        changeRubric: 'Alterar rubrica',
        noRubric: 'Ainda não há uma rubrica associada a este teste',
        totalPonderations: 'Ponderações totais para os critérios da rubrica',
        notEmpty: 'O peso dos critérios do exercício não pode estar vazio',
        negativeValueError: 'O peso dos critérios do exercício não pode ter um valor negativo',
        weightTooHigh: 'O peso dos critérios do exercício não pode ultrapassar 100%',
        successUpdateCriteriaWeight: 'O peso dos critérios do exercício foi atualizado com sucesso',
        errorTotal: 'O total dos pesos para um exercício deve ser igual a 100%',
        createRubric: 'Criar nova rubrica',
        editRubric: 'Editar rubrica',
        successEditRubric: 'A rubrica foi atualizada com sucesso',
      },
      exercises: {
        addSection: 'Adicionar seção',
        sectionNameLabel: 'Nome da seção',
        sectionDescriptionLabel: 'Descrição da seção',
        sectionNameRequiredError: 'O nome da seção é obrigatório',
        addSectionSuccessful: 'A seção foi adicionada com sucesso ao teste',
        editSectionSuccessful: 'A seção foi atualizada com sucesso',
        sectionPresentation: 'Apresentação da seção',
        sectionNavigation: 'Navegação da seção',
        sectionTimerLabel: 'Temporizador da seção em minutos (opcional)',
        sectionTimerWarning: 'O temporizador da seção só funciona em testes incrementais',
        pauseItem: 'Item de pausa',
        addPauseItem: 'Adicionar item de pausa',
        addPauseItemStepHeader: 'Mensagem de pausa',
        addPauseItemPlaceholder: 'Escreva aqui a mensagem de pausa',
        successAddPauseItem: 'O item de pausa foi criado com sucesso',
        editPublishedExerciseWarning: 'Uma vez que a avaliação está publicada, certos detalhes não podem ser editados e ao salvar as alterações, o exercício será automaticamente reavaliado e quaisquer notas anteriores serão substituídas.',
        editPointsSuccess: 'Os pontos do exercício foram atualizados com sucesso',
        editPointsSuccessAfterPublish: 'Os pontos do exercício foram atualizados com sucesso. As notas podem levar algum tempo para serem atualizadas',
        editWeightsSuccess: 'Os pesos dos critérios do exercício foram atualizados com sucesso',
        editWeightsSuccessAfterPublish: 'Os pesos dos critérios do exercício foram atualizados com sucesso. As notas podem levar algum tempo para serem atualizadas',
      },
    },
    exerciseForm: {
      createExerciseOf: 'Criar exercício de',
      editExerciseOf: 'Editar exercício de',
      editExercise: 'Editar exercício',
      selectExerciseType: 'Selecionar tipo de exercício',
      typeChoices: 'Múltipla escolha',
      typeTrueFalse: 'Verdadeiro / Falso',
      typeText: 'Texto',
      typeImage: 'Envio de imagens',
      typeFilling: 'Preencher as lacunas',
      typeConnecting: 'Conectar',
      typeOrdering: 'Ordenar',
      typeSmartChoices: 'Múltipla escolha inteligente',
      typeCaption: 'Legendas',
      typeSegmentation: 'Segmentação',
      typeTable: 'Tabela',
      newChoices: 'Novo exercício de múltipla escolha',
      newTrueFalse: 'Novo exercício verdadeiro / falso',
      newText: 'Novo exercício de texto',
      newImage: 'Novo exercício de envio de imagens',
      newSmartChoices: 'Novo exercício de múltipla escolha inteligente',
      editChoices: 'Editando exercício de múltipla escolha',
      editTrueFalse: 'Editando exercício verdadeiro / falso',
      editText: 'Editando exercício de texto',
      editImage: 'Editando exercício de envio de imagens',
      editSmartChoices: 'Editando exercício de múltipla escolha inteligente',
      discardTypeTitle: 'Descartar exercício',
      discardTypeContent: 'Se você continuar, todas as adições serão perdidas',
      saveWarningContent: 'Ao salvar, o exercício será automaticamente reavaliado e qualquer nota anterior será substituída.',
      statement: 'Enunciado',
      statementPlaceholder: 'Digite aqui o enunciado',
      statementPlaceholderInformation: 'Digite aqui a informação',
      noEmptyStatement: 'O enunciado não pode estar vazio',
      noEmptyFormula: 'Você não pode inserir uma fórmula vazia',
      noInvalidFormula: 'A fórmula parece ser inválida. Se houver espaços iniciais ou finais, remova-os. Por favor, corrija antes de continuar',
      trueFalse: 'Verdadeiro / falso',
      noEmptyTruth: 'É necessário selecionar um valor de verdade',
      choices: 'Opções',
      choicesTip: 'Adicione as diferentes opções e escolha a opção correta clicando no botão de marca de seleção ao lado da opção.',
      oneCorrectOption: 'Como você publicou a avaliação com o exercício tendo apenas uma resposta correta, você deve manter apenas uma opção correta.',
      multipleCorrectOptions: 'Como você publicou a avaliação com o exercício tendo mais de uma resposta correta, você deve manter pelo menos duas opções corretas.',
      itemstoOrder: 'Itens para ordenar',
      itemsToOrderTip: 'Os itens devem ser ordenados na ordem que você considera correta. Os itens serão randomizados quando apresentados aos alunos.',
      option: 'Opção',
      addOption: 'Adicionar nova opção',
      removeOption: 'Remover opção',
      noEmptyOption: 'Não pode haver opções vazias',
      noDuplicateOptions: 'Não pode haver opções duplicadas',
      noAnswerChoiceDefined: 'É necessário definir a opção correta',
      editMultipleOptionFail: 'É necessário definir pelo menos duas opções corretas',
      item: 'Item',
      addItem: 'Adicionar novo item',
      removeItem: 'Remover item',
      noEmptyItem: 'Não pode haver itens vazios',
      noDuplicateItems: 'Não pode haver itens duplicados',
      noAnswerGapDefined: 'É necessário definir pelo menos uma lacuna',
      noExtraWordDefined: 'É necessário definir pelo menos uma opção extra para cada lacuna',
      previewAndSave: 'Visualizar e salvar',
      saveExercise: 'Salvar exercício',
      successExerciseCreated: 'Exercício criado com sucesso',
      successExerciseEdited: 'Alterações salvas com sucesso',
      successExerciseEditedAfterPublish: 'Alterações salvas com sucesso. As notas podem levar algum tempo para atualizar',
      advancedMode: 'Modo LaTeX',
      formulaHeader: 'Adicionar fórmula',
      formulaLabel: 'Digite aqui sua fórmula',
      formulaReference: 'Referência de fórmula suportada',
      formulaSectionGeneral: 'Geral',
      formulaSectionMath: 'Matemática',
      videoHeader: 'Adicionar vídeo',
      youtube: 'Vídeo do Youtube',
      youtubeLink: 'Link do Youtube',
      invalidUrl: 'Só é possível inserir links de vídeos do Youtube',
      noVideoChosen: 'Você não escolheu nenhum link',
      myVideo: 'Meu vídeo',
      addVideoLabel: 'Clique para adicionar um vídeo',
      justificationLabel: 'Justificativa',
      justificationText: 'Adicionar espaço para justificativa',
      askForImage: 'Imagem',
      askForImageError: 'Tipo de arquivo incorreto! Por favor, insira apenas arquivos de imagem',
      caption: 'Legenda',
      createCaptions: 'Criar legendas',
      editCaptions: 'Editar legendas',
      createCaptionsDescription: 'Clique na imagem para adicionar uma legenda. Você pode pressionar e segurar uma legenda para arrastá-la.',
      editCaptionsDescription: 'Clique em uma legenda para editar o texto.',
      captionWarning: 'Aviso: Este exercício tem uma experiência melhor ao ser respondido em um computador.',
      shortAnswer: 'Resposta',
      shortAnswerText: 'Transformar em exercício de resposta curta',
      characterLimit: 'Limite de caracteres',
      characterLimitError: 'Especifique o número de caracteres',
      extraText: 'Texto extra',
      extraTextPlaceholder: 'Digite aqui o texto extra',
      extraTextStartOpen: 'Iniciar totalmente expandido',
      identifiers: 'Identificadores',
      useMathSymbols: 'Usar símbolos matemáticos (Aluno)',
      wordCountLabel: 'Mostrar contagem de palavras (Aluno)',
      exerciseSettings: {
        label: 'Configurações do Exercício',
        title: 'Título do Exercício',
        whoCanSee: 'Quem pode ver este exercício?',
        areas: 'Quais disciplinas são relevantes para este exercício?',
        years: 'Para quais séries?',
        errorTitleRequired: 'É necessário escolher um título',
        errorSubjectRequired: 'É necessário escolher uma disciplina',
        errorYearRequired: 'É necessário escolher uma série',
        'public': 'Público',
        publicTip: 'Visível na página "Explorar"',
        'private': 'Privado',
        privateTip: 'Visível apenas para você ou para os grupos com os quais você compartilha o exercício',
      },
      gap: {
        choosingGaps: 'Escolhendo as palavras a serem ocultadas',
        choosingGapsSub: 'Selecione as palavras que você deseja ocultar dos alunos',
        noGaps: 'Ainda não há palavras na declaração',
        choosingGapExtraOption: 'Opções do exercício (lado do aluno)',
        dragDropOption: 'Mostrar a lista de palavras ocultas (arrastando)',
        dropdownOption: 'Mostrar a lista de palavras ocultas (dropdown)',
        writeOption: 'Não mostrar a lista de palavras ocultas (escrever)',
        example: 'Exemplo',
        write: 'Escreva...',
        correctionAuto: 'Correção automática pela plataforma',
        correctionManual: 'Correção feita pelo professor',
        askForExtraGapsButton: 'Salvar palavras adicionais',
        addExtraGaps: 'Adicionar palavras adicionais',
        editExtraGaps: 'Editar palavras adicionais',
        addExtraGapsSub: 'Escreva palavras adicionais para adicionar dificuldade a este exercício',
        editExtraGapsSub: 'Edite as palavras adicionais para adicionar dificuldade a este exercício',
        addGapOptions: 'Adicionar opções para o espaço: ',
        editGapOptions: 'Editar opções para o espaço: ',
        word: 'Palavra',
        orderGaps: 'Ordenar palavras',
        noExtraGaps: 'Não há palavras adicionais para editar',
      },
      choice: {
        shuffleChoices: 'Embaralhar opções múltiplas',
      },
      connecting: {
        shuffleConnectors: 'Embaralhar conectores',
        connectors: 'Conectores',
        connector: 'Conector',
        connections: 'Conexões',
        addConnector: 'Adicionar conector',
        noEmptyConnector: 'Não pode haver conectores vazios',
        noConnections: 'É necessário ter pelo menos uma conexão',
        noConnectors: 'É necessário ter pelo menos dois conectores',
        noConnectorsLeft: 'É necessário ter pelo menos um conector na coluna da esquerda',
        noConnectorsRight: 'É necessário ter pelo menos um conector na coluna da direita',
      },
      segmentation: {
        createConnectorsAndConnections: 'Criar conectores e conexões',
        createConnectorsAndConnectionsDescription: 'Clique na imagem para adicionar um conector. Você pode criar uma conexão clicando nos dois conectores que deseja conectar.',
        showOnlyLine: 'Mostrar apenas linha',
      },
      table: {
        tableGrading: 'Opções de avaliação',
        gradeByRow: 'Por linha',
        gradeByTable: 'Por tabela',
        gradeByRowTip: 'O exercício será avaliado por linha, o que significa que o aluno deve responder corretamente em cada coluna da linha, seja selecionando-a ou não; caso contrário, a linha será considerada incorreta.',
        gradeByTableTip: 'O exercício será avaliado por tabela, o que significa que cada célula individual deve ser respondida corretamente; caso contrário, o exercício será considerado incorreto.',
        noEmptyHeaderCell: 'Não podem haver células de cabeçalho principais vazias',
        headerPlaceholder: 'Cabeçalho',
        rowGradingExplanation: 'Avaliado por linha',
        tableGradingExplanation: 'Avaliado por tabela',
      },
      audio: {
        audioHeader: 'Adicionar áudio',
        addAudioLabel: 'Clique para adicionar um áudio',
        askForAudioError: 'Tipo de arquivo incorreto! Por favor, insira apenas arquivos de áudio',
        noAudioChosen: 'Você não escolheu um arquivo de áudio',
        canPauseLabel: 'O aluno pode pausar a execução do áudio',
        canPause: 'Pode pausar',
        cannotPause: 'Não pode pausar',
        limitRepetitionsLabel: 'Limitar a quantidade de vezes que o aluno pode repetir o áudio',
        maxRepetitionsLabel: 'Repetições máximas',
        repetitions: 'repetições',
        retryMessage: 'Ocorreu um erro. Tente reproduzir o áudio novamente',
      },
      video: {
        audioHeader: 'Adicionar vídeo',
        addAudioLabel: 'Clique para adicionar um vídeo',
        askForAudioError: 'Tipo de arquivo incorreto! Por favor, insira apenas arquivos de vídeo',
        noAudioChosen: 'Você não escolheu um arquivo de vídeo',
        canPauseLabel: 'O aluno pode pausar a execução do vídeo',
        canPause: 'Pode pausar',
        cannotPause: 'Não pode pausar',
        limitRepetitionsLabel: 'Limitar a quantidade de vezes que o aluno pode repetir o vídeo',
        maxRepetitionsLabel: 'Repetições máximas',
        repetitions: 'repetições',
      },
      modelAnswer: 'Resposta Modelo',
      modelAnswerPlaceholder: 'Insira aqui a resposta modelo',
    },
    exercises: {
      title: 'Banco de Exercícios',
      subtitle: 'Todos os exercícios criados',
      createNewExercise: 'Criar exercício',
      createNewExerciseSubtitle: 'Aqui vamos criar um novo exercício',
      cancelCreation: 'Cancelar criação',
      cancelEdit: 'Cancelar edição',
      creatingNewExerciseOn: 'Criando um novo exercício em',
      editingExercise: 'Editando um exercício em',
      deleteExercise: 'Excluir exercício',
      deleteExercises: 'Excluir exercícios',
      deleteExerciseInfo: 'Tem certeza de que deseja excluir este exercício?',
      deleteExercisesInfo: 'Tem certeza de que deseja excluir estes exercícios?',
      deleteExerciseInfo2: 'Esta ação não é reversível',
      successDeleteExercise: 'O exercício foi excluído com sucesso',
      successDeleteExercises: 'Os exercícios foram excluídos com sucesso',
      successDuplicateExercise: 'O exercício foi duplicado com sucesso',
      deleteErrorDoesNotExist: 'A exclusão não pôde ser realizada porque o exercício já não existe',
      previewExercise: 'Visualizar exercício',
      previewErrorDoesNotExist: 'A visualização não pôde ser carregada porque o exercício não existe mais',
      noExercises: 'Nenhum exercício foi criado ainda.',
      noExercisesForFilters: 'Não há exercícios para os filtros selecionados.',
      gapList: 'Lista de palavras/expressões:',
      gapListUnused: 'Lista de palavras/expressões não utilizadas:',
      labels: {
        addLabel: 'Adicionar etiqueta',
        createLabel: 'Criar etiqueta',
        repeatedLabelError: 'Já existe uma etiqueta com esse nome',
        labelName: 'Nome da etiqueta',
        errorNoColorSelected: 'É necessário definir uma cor',
        errorNameRequired: 'É necessário definir um nome',
        labels: 'Etiquetas',
        editLabels: 'Editar etiquetas',
        successEditLabel: 'A etiqueta foi editada com sucesso',
        successDeleteLabel: 'A etiqueta foi excluída com sucesso',
      },
      filters: 'Filtros',
      teacherFilter: 'Professores',
      typeFilter: 'Tipos de exercício',
      order: 'Ordenar',
      orderNewest: 'Mais recentes',
      orderOldest: 'Mais antigos',
      orderLastModified: 'Última modificação',
      send: {
        sendExercise: 'Enviar exercício',
        sendExercises: 'Enviar exercícios',
        keepCopy: 'Manter original e enviar uma cópia',
        sendLabels: 'Enviar etiquetas para o outro grupo',
        successSendExercise: 'O exercício foi enviado com sucesso',
        successSendExercises: 'Os exercícios foram enviados com sucesso',
        errorSendExerciseGroup: 'Você deve escolher um grupo para enviar o exercício.',
        errorSendExercisesGroup: 'Você deve escolher um grupo para enviar os exercícios.',
        errorSendExercisesSchool: 'Você deve escolher um espaço para enviar os exercícios.',
        errorSendExercisesToMySpace: 'Você não pode enviar exercícios de outros usuários para seu espaço pessoal.',
      },
      modelAnswer: 'Resposta modelo',
    },
    attempt: {
      nextExercise: 'Próximo exercício',
      nextItem: 'Próximo',
      previousExercise: 'Exercício anterior',
      previousItem: 'Anterior',
      finishedAttempt: 'Finalizado. Você pode fechar a aba.',
      finishedAttemptIave: 'Teste finalizado. Aguarde as instruções do professor.',
      characterLimitReached: 'Limite máximo de caracteres atingido',
      timesUp: 'Tempo da seção esgotado',
      unsavedAnswers: 'Ocorreu um erro ao salvar sua(s) resposta(s). Verifique sua conexão com a internet e tente salvar novamente',
      unsavedAnswersFinish: 'Você tem respostas não salvas. Tem certeza de que deseja finalizar?',
      answersSaved: 'As respostas foram salvas com sucesso',
      errorSaveAnswers: 'Ocorreu um erro ao salvar as respostas',
      exitPinModal: {
        pinLabel: 'Por favor, insira o PIN de saída fornecido pelo supervisor',
        pinPlaceholder: 'PIN',
        modalHeader: 'PIN de saída do exame',
        wrongPin: 'O PIN não está correto',
        emptyPin: 'Você deve inserir um PIN para prosseguir',
      },
      oops: 'Ocorreu um erro ao salvar sua(s) resposta(s). Verifique sua conexão com a internet e tente novamente',
      continueWithoutSaving: 'Continuar sem salvar',
      saveAndContinue: 'Salvar e continuar',
      status: 'Status',
      statusValid: 'Válido',
      statusInvalid: 'Inválido',
      statusPending: 'Pendente',
      statusUpdated: 'Atualizado com sucesso',
    },
    groups: {
      title: 'Grupos',
      teachers: 'professores',
      teacher: 'professor',
      createNewGroup: 'Criar grupo',
      groupName: 'Nome do grupo',
      successCreateGroup: 'O grupo foi criado com sucesso',
      hintTargets: 'Este campo define quais professores serão adicionados ao grupo',
      errorNameRequired: 'É necessário definir um nome',
      noGroups: 'Ainda não tem um grupo? Crie um e comece a colaborar com outros professores!',
      successEditGroup: 'O grupo foi editado com sucesso',
      userNotAllowed: 'O usuário já excedeu o limite de grupos que pode ingressar',
    },
    group: {
      title: 'Group',
      leaveGroup: 'Leave group',
      leaveGroupInfo: 'Are you sure you want to leave this group?',
      leaveGroupInfo2: 'This action is not reversible and you will lose access to all the group\'s content.',
      successLeaveGroup: 'You successfully left the group',
      removeTeacher: 'Remove teacher?',
      removeTeacherInfo: 'Are you sure you want to remove this teacher?',
      successRemoveTeacher: 'The teacher was removed successfully',
      successAddTeacher: 'The teachers were added successfully',
      addTeacher: 'Add teachers',
      hintTargets: 'Insert the e-mails of the teachers that you want to add to the group',
      hintTargetsCreatable: 'Insert the e-mails of the teachers that you want to add to the group. You can add e-mails of teachers that are not in Intuitivo in order to invite them.',
      groupSettings: 'Group settings',
      deleteGroup: 'Delete group',
      deleteGroups: 'Delete groups',
      deleteGroupInfo: 'Are you sure you want to delete the group? You won\'t be able to recover the contents afterwards. This action is not reversible.',
      deleteGroupsInfo: 'Are you sure you want to delete these groups? You won\'t be able to recover the contents afterwards. This action is not reversible.',
      successDeleteGroup: 'The groups was successfully deleted',
      removeSelected: 'Remove selected',
    },
    publication: {
      password: 'Senha do teste',
      testWindowClosedInfo: 'A janela de abertura do teste foi fechada.',
      studentName: 'Nome do aluno',
      errorNameRequired: 'É necessário inserir seu nome.',
      errorEmail: 'O e-mail fornecido está incorreto.',
      passwordRequired: 'É necessário fornecer uma senha.',
      emailHint: 'O e-mail inserido será usado para enviar sua nota e feedback assim que o teste for publicado.',
      continueAsGuest: 'Continuar como convidado',
      continueAs: (name) => `Continuar como ${name}`,
      notYou: 'Não é você?',
      enterAsNewStudent: 'Entrar como um novo aluno',
    },
    rubrics: {
      title: 'Rubricas',
      noRubrics: 'Nenhuma rubrica foi criada ainda',
      previewRubric: 'Visualizar rubrica',
      performanceLevels: 'Níveis de desempenho',
      createRubric: 'Criar rubrica',
      editRubric: 'Editar rubrica',
      deleteRubric: 'Excluir rubrica',
      deleteRubrics: 'Excluir rubricas',
      deleteRubricInformation: 'Você tem certeza de que deseja excluir esta rubrica? Esta ação não pode ser desfeita.',
      deleteRubricsInformation: 'Você tem certeza de que deseja excluir essas rubricas? Esta ação não pode ser desfeita.',
      successDeleteRubric: 'A rubrica foi excluída com sucesso',
      successDeleteRubrics: 'As rubricas foram excluídas com sucesso',
      successDuplicateRubric: 'A rubrica foi duplicada com sucesso',
      form: {
        rubricNameLabel: 'Nome da rubrica',
        rubricDescriptionLabel: 'Descrição da rubrica',
        criteriaNameLabel: 'Nome do critério',
        criteriaDescriptionLabel: 'Descrição do critério',
        performanceLevelDescriptionLabel: 'Descrição do nível de desempenho',
        addCriteria: 'Adicionar critério',
        discardRubric: 'Descartar rubrica',
        nameRequiredError: 'É necessário inserir um nome para a rubrica.',
        criteriaNameRequiredError: 'É necessário inserir um nome para o critério.',
        criteriaRequiredError: 'É necessário criar pelo menos um critério para esta rubrica.',
        createRubricSuccess: 'A rubrica foi criada com sucesso',
        editRubricSuccess: 'A rubrica foi atualizada com sucesso',
      },
      send: {
        sendRubric: 'Enviar rubrica',
        sendRubrics: 'Enviar rubricas',
        keepCopy: 'Manter original e enviar uma cópia',
        successSendRubric: 'A rubrica foi enviada com sucesso',
        successSendRubrics: 'As rubricas foram enviadas com sucesso',
        errorSendRubricGroup: 'Você deve escolher um grupo para enviar a rubrica.',
        errorSendRubricsGroup: 'Você deve escolher um grupo para enviar as rubricas.',
        errorSendRubricsSchool: 'Você deve escolher um espaço para enviar as rubricas.',
        errorSendRubricsToMySpace: 'Você não pode enviar rubricas de outros usuários para o seu espaço pessoal.',
      },
    },
    explore: {
      title: 'Explorar',
      header: 'Explore os conteúdos disponíveis',
      headerXs: 'Explorar',
      contentsListHeader: 'Conteúdos mais usados, de acordo com os filtros selecionados',
      contentsListHeaderLg: 'Conteúdos mais usados',
      contentsListHeaderXs: 'Conteúdos',
      noResultsHeader: 'Ops... Não conseguimos encontrar nenhum conteúdo correspondente à sua busca :(',
      noResultsDescription: 'Tente buscar um termo mais geral, alterar os filtros ou verificar se há algum erro nas palavras-chave da busca.',
      noResultsDescription2: 'Se essas dicas não funcionarem, convidamos você a criar novos conteúdos.',
      createNewExercise: 'Criar exercício',
      endMessage: 'Você viu todos os resultados correspondentes à sua busca.',
      scrollBackToTopMessage: 'Voltar ao topo da página',
      searchPlaceholder: 'Busque conteúdos no Intuitivo...',
      addFilter: 'Adicionar filtro',
      subjectAreas: 'Áreas de conhecimento',
      schoolYears: 'Anos escolares',
      numberOfUses: 'Usos',
      copyInformation: 'Para qual grupo você deseja copiar este exercício?',
      successCopyExercise: 'O exercício foi copiado com sucesso para o seu grupo',
      infoTip: 'Preencha suas preferências na página do seu perfil para uma experiência mais personalizada',
      searchAll: 'Todo o conteúdo',
    },
    profilePreferences: {
      setProfilePreferences: 'Antes de continuar, ajude-nos a melhorar sua experiência.',
      saveProfilePreferences: 'As preferências foram salvas com sucesso',
      warningProfilePreferences: 'Para alterar suas preferências, vá para a página "Conta"',
      subjects: 'Quais disciplinas você está ensinando?',
      years: 'Para quais séries?',
    },
    login: {
      loginWithMicrosoft: 'Entrar com Microsoft',
      poweredBy: 'Desenvolvido por',
    },
    tours: {
      tip: 'Guias',
      title: 'Precisa de ajuda?',
      startTour: 'Iniciar',
      subtitles: [
        'Nesta seção, você pode encontrar vários guias para ajudar a interagir com o Intuitivo.',
        'Para começar, basta selecionar um dos guias disponíveis abaixo.',
      ],
      back: 'Voltar',
      next: 'Próximo',
      complete: 'Concluir',
      introduction: {
        title: 'Introdução',
        subtitle: 'Aprenda o básico do Intuitivo',
        steps: {
          introduction: 'Bem-vindo ao Intuitivo. Preparamos um guia para ajudá-lo a começar. Para continuar, clique no botão "Próximo". Se você já está familiarizado com o Intuitivo, pode fechar esta mensagem.',
          testCard: 'Para ajudá-lo a começar com o Intuitivo, criamos uma avaliação simples para explorar juntos.',
          testCardPublishButton: 'Avaliações publicadas serão exibidas com este ícone verde. Se ainda não publicado, será cinza.',
          testCardLeft: 'Clique neste cartão para ver mais detalhes sobre esta avaliação.',
          tabExercises: 'Neste momento, você está na aba "Exercícios". Aqui você pode ver quais exercícios constituem a avaliação.',
          tabGrade: 'Para este guia, a avaliação já foi publicada e teve 2 envios. Selecione a aba "Correção" para ver os envios dos alunos.',
          statistics: 'Você pode ver as estatísticas gerais para esta avaliação.',
          attempts: 'Você também tem acesso a esta lista com os nomes e notas dos alunos que enviaram suas respostas para esta avaliação. Clicar em um nome mostrará as respostas do aluno.',
          attemptContainer: 'Toda esta seção é dedicada à tentativa individual do aluno selecionado.',
          attemptHeader: 'O cabeçalho de cada tentativa contém informações sobre o aluno, bem como a nota final e o link de envio.',
          multipleChoice: 'Você notará que o aluno respondeu corretamente a este exercício de "Escolha Múltipla".',
          multipleChoiceGrader: 'O Intuitivo corrige automaticamente alguns tipos de exercício, como é o caso deste exercício de "Escolha Múltipla". O aluno obteve a pontuação máxima; no entanto, você pode editar este valor livremente.',
          openQuestionGrader: 'Para "Exercícios de Texto", você terá que corrigir manualmente.',
          openQuestionFeedback: 'Você também pode fornecer feedback por questão.',
          publishGradesButton: 'Depois de corrigir a avaliação de todos os alunos, você pode publicar notas e correções. Alunos que forneceram um endereço de e-mail no início do teste receberão suas notas por e-mail.',
          tabStatistics: 'Na aba "Estatísticas", você pode ter uma visão detalhada do desempenho dos alunos.',
          assessmentSidebarOption: 'Vamos voltar à página "Avaliações".',
          testCardDropdown: 'Quando você terminar de explorar esta avaliação, pode excluí-la a qualquer momento clicando neste menu e escolhendo a opção "Excluir".',
          hub: 'Você pode consultar este guia novamente a qualquer momento. Recomendamos que você explore esta seção caso precise de ajuda com a criação de avaliações e exercícios.',
        },
      },
      assessments: {
        title: 'Avaliações',
        subtitle: 'Aprenda a criar testes e planilhas',
        steps: {
          sidebarGoToPage: 'Primeiro, vá para a página "Avaliações".',
          sidebar: 'Você pode acessar as avaliações na página "Avaliações".',
          createButton: 'Para criar uma avaliação, clique no botão "Criar Avaliação".',
          nameInput: 'Dê um nome à sua avaliação, algo como "Minha primeira avaliação".',
          typeInput: `Escolha um tipo de avaliação:
            <ul>
              <li><strong>Teste:</strong> Tem um limite de tempo e está agendado para uma data específica.</li>
              <li><strong>Planilha:</strong> Acessível até uma data de entrega.</li>
            </ul>
            Vamos deixar a opção padrão "Teste".`,
          classificationInput: `Escolha um tipo de classificação:
            <ul>
              <li><strong>Clássica:</strong> As submissões dos alunos são avaliadas.</li>
              <li><strong>Rubrica:</strong> Possui critérios de avaliação definidos (Você pode aprender mais sobre rubricas no tour "Rubricas").</li>
              <li><strong>Sem Classificação:</strong> Nenhuma nota é dada para as submissões dos alunos.</li>
            </ul>
            Vamos deixar a opção padrão "Clássica".`,
          groupInput: 'Você pode salvar esta avaliação em qualquer grupo do qual você faça parte. (Você pode aprender mais sobre grupos no tour "Grupos").',
          submitButton: 'Conclua a criação da avaliação enviando este formulário.',
          exercisesTab: 'Você está atualmente na aba "Exercícios". Aqui é onde você pode adicionar conteúdo à sua avaliação, como exercícios, seções e blocos de informação.',
          gradesTab: 'Quando sua avaliação for publicada e respondida pelos seus alunos, você poderá ver suas submissões na aba "Correção".',
          physicalTab: 'Na aba "Versão Física", você pode baixar sua avaliação como um documento PDF.',
          contentForm: 'Nesta seção você pode adicionar conteúdo à sua avaliação.',
          createNewExercise: 'Vamos fazer exatamente isso. Clique em "Criar um novo exercício".',
          exerciseType: 'Você pode escolher o tipo de exercício que deseja criar.',
          trueOrFalseExerciseType: 'Escolha a opção "Verdadeiro/Falso".',
          exerciseStatement: 'Escreva o enunciado do exercício, algo como "Os golfinhos são mamíferos?"',
          exerciseCorrectAnswer: 'Nesta seção, você pode escolher a resposta correta.',
          exerciseTrue: 'Neste caso, vamos escolher a opção "Verdadeiro".',
          exerciseSubmit: 'Tudo pronto. Este exercício também será adicionado à sua biblioteca de "Exercícios" (Você pode aprender mais sobre exercícios no guia "Exercícios"). Por enquanto, clique em "Confirmar".',
          exerciseGrade: 'Você pode editar a nota de exercícios individuais.',
          exerciseEdit: 'Você pode editar um exercício a qualquer momento clicando neste botão.',
          exerciseDelete: 'Um exercício também pode ser excluído de uma avaliação.',
          publishButton: 'Seu teste está pronto para ser publicado. Clique no botão "Publicar".',
          publishFormFormat: 'Você pode escolher se deseja mostrar todos os exercícios de uma vez ou ter apenas um exercício visível por vez.',
          publishFormStart: 'Escolha uma data de início para este teste.',
          publishFormTolerance: 'Escolha o número de minutos que um aluno pode atrasar para o teste.',
          publishFormDuration: 'Escolha quanto tempo seus alunos têm para completar este teste.',
          publishFormPassword: 'Você pode optar por proteger seu teste definindo uma senha que será solicitada quando seus alunos iniciarem o teste.',
          publishFormShuffle: 'Você pode optar por embaralhar as perguntas do teste, assim todos os seus alunos terão um teste diferente.',
          publishFormFeedback: 'Você também pode optar por permitir que seus alunos tenham acesso às respostas do teste após o envio.',
          publishFormModelAnswer: 'Se a opção de feedback imediato for escolhida, você pode optar por permitir que seus alunos tenham acesso às respostas modelo do exercício após o envio.',
          publishFormSubmit: 'Tudo pronto, você agora pode publicar seu teste clicando em "Publicar".',
          publishLink: 'Parabéns, seu teste foi publicado. Agora você só precisa compartilhar este link com seus alunos para que eles façam o teste.',
          publishCloseModal: 'Você pode agora fechar esta janela.',
          publishButtonFinal: 'Você pode visualizar a janela anterior novamente clicando neste botão.',
          hub: 'Este e outros guias estão sempre disponíveis na seção Guia.',
        },
      },
      explore: {
        title: 'Explorar',
        subtitle: 'Aprenda a usar e adaptar o conteúdo de outros professores.',
        steps: {
          sidebarExplore: 'Para iniciar este guia, vá para a página "Explorar".',
          sidebar: 'Você pode acessar as avaliações na página "Explorar".',
          exerciseList: 'Nesta página, você pode encontrar todos os exercícios e avaliações criados e tornados públicos por outras escolas e professores que usam o Intuitivo. Para contribuir, basta salvar seu próprio conteúdo como público durante a criação de exercícios ou avaliações.',
          header: 'Nesta seção, você tem acesso a uma ferramenta que permite buscar conteúdos públicos.',
          headerTextInput: 'Você pode pesquisar por palavras-chave livremente.',
          headerContentDropdown: 'Você também pode filtrar por tipo de conteúdo: Avaliação, Exercícios ou ambos.',
          headerFilters: 'Há também a opção de pesquisar por Ano Escolar ou Disciplina. Por padrão, suas preferências de perfil estão selecionadas (Você pode alterá-las nas configurações da sua conta).',
          exerciseCardClick: 'Clique no conteúdo a seguir para uma visão detalhada.',
          exerciseCardPreview: 'Aqui você pode visualizar o conteúdo com mais detalhes.',
          exerciseCardPreviewCopy: 'Para adicionar este conteúdo ao seu perfil, você simplesmente precisa clicar em "Copiar".',
          exerciseCardPreviewSelectGroup: 'Depois, você precisa escolher o grupo de destino para este conteúdo.',
          exerciseCardPreviewSubmit: 'E finalmente, clique em "Confirmar".',
          exerciseCardClosed: 'Agora você pode visualizar e editar livremente este conteúdo na página "Avaliações" ou "Exercícios", dependendo do tipo.',
          hub: 'Você pode voltar a este e outros guias a qualquer momento, clicando no hub "Guia".',
        },
      },
      groups: {
        title: 'Grupos',
        subtitle: 'Aprenda a criar e usar grupos para colaborar com seus colegas',
        steps: {
          sidebarGoToPage: 'Para iniciar este guia, vá para a página "Grupos".',
          sidebar: 'Você pode acessar os grupos na página "Grupos".',
          createNewGroup: 'Para criar um novo grupo, clique no botão "Criar Grupo".',
          formNameInput: 'Nomeie este grupo, por exemplo "Professores de Matemática - Ensino Fundamental".',
          formTeachersInput: 'Nesta seção, você pode adicionar seus colegas que também estão usando o Intuitivo através do endereço de e-mail. No entanto, para este guia, você pode deixar este campo em branco.',
          formConfirm: 'Finalize a criação do seu novo grupo clicando em "Confirmar".',
          groupCard: 'Cada grupo será apresentado como um cartão nomeado. Clique nele para uma visão detalhada.',
          teachersTable: 'Nesta tabela, você pode ver o nome e o endereço de e-mail de todos os membros do grupo.',
          addTeacherButton: 'Para adicionar novos membros, clique neste botão, que exibirá um formulário para adicionar o endereço de e-mail dos novos membros.',
          groupShortcuts: `
            <p>
              Neste cartão, você pode encontrar atalhos para as páginas "Avaliações", "Exercícios" e "Rubricas" deste grupo.
              Em cada uma dessas páginas, você encontrará um dropdown para navegar entre grupos, como ilustra a imagem a seguir:
            </p>
            <img style="width: 100%" src="${process.env.REACT_APP_S3_URL}/resources/groups-header-shortcut-en.png" style="width: 100%">
          `,
          leaveGroup: 'Para sair de um grupo, simplesmente clique neste menu e selecione a opção "Sair do Grupo".',
          hub: 'Você pode voltar a este e outros guias a qualquer momento, clicando no hub "Guia".',
        },
      },
      rubrics: {
        title: 'Rubricas',
        subtitle: 'Aprenda a criar e usar rubricas em suas avaliações',
        steps: {
          sidebarGoToPage: 'Para iniciar este guia, vá para a página "Rubricas".',
          sidebar: 'Você pode acessar as rubricas na página "Rubricas".',
          createNewButton: 'Para criar uma nova Rubrica, clique em "Criar rubrica".',
          nameInput: 'Nomeie sua rubrica, por exemplo "Critérios de Avaliação - História".',
          descriptionInput: 'Você pode escrever uma descrição para adicionar mais contexto.',
          criteriaCard: 'Nesta seção, você pode adicionar todos os critérios que achar necessários. Os níveis dos critérios variam de 1 a 5.',
          criteria0NameInput: 'Nomeie este critério como "Conteúdos".',
          criteria0ValueInput: `
            Em seguida, você pode adicionar descritores de desempenho a esta rubrica. Por exemplo:
            <ul>
              <li><strong>(5):</strong> Explica completamente o que foi pretendido;</li>
              <li><strong>(4):</strong> Explica completamente o que foi parcialmente pretendido;</li>
              <li><strong>(3):</strong> Explica incompletamente o que foi pretendido;</li>
              <li><strong>(2):</strong> Explica incompletamente o que foi parcialmente pretendido;</li>
              <li><strong>(1):</strong> Explica incompletamente apenas parte do que foi pretendido;</li>
            </ul>`,
          addCriteriaButton: 'Adicione um novo critério clicando neste botão.',
          criteria1NameInput: 'Nomeie este critério como "Comunicação".',
          criteria1ValueInput: 'Da mesma forma que o critério anterior, você pode adicionar descritores de desempenho que considerar necessários. No entanto, para o nosso caso, você pode deixá-los em branco.',
          criteria1RemoveButton: `
            Se você quiser excluir um critério, basta clicar no ícone
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path fill="currentColor" d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"></path>
            </svg>`,
          exerciseExample: `
            <p>
              Durante a correção de uma avaliação do tipo rubrica, cada questão será avaliada por critérios.
              Dessa forma, você pode atribuir pesos específicos a cada critério para cada exercício, como ilustra a imagem a seguir:
            </p>
            <img src="${process.env.REACT_APP_S3_URL}/resources/exercise-rubric-example-en.png" style="width: 100%">

            <p>Se necessário, os guias "Avaliações" e "Introdução" demonstram a criação, publicação e correção de avaliações.</p>
          `,
          groupDropdown: 'Assim como em outros exemplos, você pode escolher um grupo para salvar esta rubrica. O guia "Grupos" contém mais informações sobre este recurso.',
          saveButton: 'Finalize a criação desta rubrica clicando em "Salvar".',
          card: 'Uma rubrica criada será exibida desta forma.',
          cardActions: `
            Nesta seção do cartão da rubrica, você pode:
            <ul>
              <li>Visualizar;</li>
              <li>Editar;</li>
              <li>Enviar para outro grupo;</li>
              <li>Duplicar;</li>
              <li>Excluir;</li>
            </ul>
          `,
          hub: 'Você pode voltar a este e outros guias a qualquer momento, clicando no hub "Guia".',
        },
      },
      exercises: {
        title: 'Exercícios',
        subtitle: 'Aprenda a criar e usar exercícios',
        steps: {
          sidebarGoToPage: 'Primeiro, vá para a página "Exercícios".',
          sidebar: 'Você pode acessar os exercícios na página "Exercícios".',
          createExerciseButton: 'Para criar um exercício, clique no botão "Criar Exercício".',
          createExerciseType: 'Existem vários tipos de exercícios para escolher. Recomendamos que você dedique um tempo para explorar todos esses tipos diferentes. Se precisar de orientação, você sempre pode consultar nossa página de <a href="https://intuitivo.pt/en/support/tutorials" target="_blank">Tutoriais</a>.',
          createExerciseTypeText: 'Para os fins deste guia, crie um exercício simples do tipo "Texto".',
          editorHeaderTypeDropdown: 'A primeira etapa para a criação do exercício está completa. No entanto, se você precisar mudar o tipo de exercício, pode fazê-lo aqui.',
          editorHeaderGroupDropdown: 'Da mesma forma, você pode escolher em qual grupo este exercício será salvo. Para mais informações sobre grupos, consulte o guia "Grupos" disponível na seção de guias.',
          editorStatement: `
            Escreva a declaração do exercício. Você pode adicionar vários conteúdos multimídia, como imagens, vídeos e áudios. Você também tem acesso a um editor de fórmulas matemáticas.
            <br/>
            Para este exercício de exemplo, você pode escrever algo como <i>"Qual era a capital do reino anglo-saxão de Wessex?"</i>.
          `,
          editorShortAnswer: 'Dependendo do tipo de exercício, você terá diferentes opções de personalização disponíveis. Para exercícios de texto, você pode limitar o número de caracteres da resposta e permitir o acesso ao editor de fórmulas matemáticas.',
          editorExtraText: 'Em cada tipo de exercício, você também pode adicionar texto extra ao exercício, que será exibido abaixo do campo de resposta do aluno.',
          editorModelAnswer: 'Dependendo do tipo de exercício, você também pode adicionar uma resposta modelo ao exercício, que não será exibida para o aluno durante a avaliação, mas pode ser opcionalmente apresentada após o término da avaliação.',
          editorPreview: 'Você sempre terá uma pré-visualização disponível. Esta será a visão que seus alunos terão durante uma avaliação.',
          editorSettingsFormTitle: `
            Para finalizar a criação do exercício, você terá que nomeá-lo.
            <br/>
            Que tal algo como <i>"A capital de Wessex"</i>?
          `,
          editorSettingsFormVisibility: `
            Em seguida, você pode escolher se deseja manter seu exercício público ou privado:
            <ul>
              <li><strong>Público:</strong> o exercício estará disponível na página <i>"Explorar"</i> e pode ser copiado e usado por outros professores.</li>
              <li><strong>Privado:</strong> o exercício estará disponível apenas para você e membros do grupo.</li>
            </ul>
            Para mais informações sobre as páginas <i>"Explorar"</i> e <i>"Grupos"</i>, há guias que explicam esses recursos.
          `,
          editorSettingsFormVisibilityPrivate: 'Vamos manter o exercício privado. Selecione a opção <i>"Privado"</i>.',
          editorLabelsButton: 'Se necessário, você pode criar e adicionar etiquetas para catalogar seus exercícios. Esse esforço ajudará você a filtrar e pesquisar exercícios.',
          editorHeaderSaveExercise: 'Finalmente, complete a criação do exercício clicando no botão "Salvar".',
          exerciseCard: 'Um exercício será exibido assim.',
          exerciseCardActions: `
            Nesta seção, você pode:
            <ul>
              <li>Visualizar o exercício;</li>
              <li>Editar;</li>
              <li>Enviar para outro grupo;</li>
              <li>Duplicar;</li>
              <li>Excluir;</li>
            </ul>
          `,
          exerciseFilters: `
            Você também tem a possibilidade de filtrar os exercícios por:
            <ul>
              <li>Professor autor;</li>
              <li>Tipo;</li>
              <li>Etiquetas;</li>
            </ul>
            E ordenar por:
            <ul>
              <li>Mais recentes;</li>
              <li>Mais antigos;</li>
              <li>Última modificação;</li>
            </ul>
          `,
          hub: 'Você pode voltar a este e outros guias a qualquer momento, clicando no hub "Guia".',
        },
      },
    },
    plans: {
      premium: 'Premium',
      free: 'Básico',
      exceededTests: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'} gratuitas criadas por você em todos os grupos dos quais você participa. Exclua avaliações ou faça um upgrade para o premium com uma das opções abaixo.`,
      exceededTestsTip: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'avaliação' : 'avaliações'} gratuitas criadas por você em todos os grupos dos quais você participa. Exclua avaliações ou faça um upgrade para o premium.`,
      upgrade: 'ATUALIZAR',
      getPremium: 'Obter Premium',
      unlimitedAccessMessage: 'Desbloqueie acesso ilimitado ao Intuitivo individualmente.',
      recommendSchool: 'Recomendar Escola',
      recommend: 'RECOMENDAR',
      dedicatedSpace: 'Desbloqueie um espaço dedicado para sua escola com acesso ilimitado ao Intuitivo.',
      exceededExercises: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'exercício' : 'exercícios'} gratuitos criados por você em todos os grupos dos quais você participa. Exclua exercícios ou faça um upgrade para o premium com uma das opções abaixo.`,
      exceededExercisesTip: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'exercício' : 'exercícios'} gratuitos criados por você em todos os grupos dos quais você participa. Exclua exercícios ou faça um upgrade para o premium.`,
      exceededRubrics: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'} gratuitas criadas por você em todos os grupos dos quais você participa. Exclua rubricas ou faça um upgrade para o premium com uma das opções abaixo.`,
      exceededRubricsTip: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'rubrica' : 'rubricas'} gratuitas criadas por você em todos os grupos dos quais você participa. Exclua rubricas ou faça um upgrade para o premium.`,
      exceededGroups: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'grupo' : 'grupos'}. Saia dos grupos ou faça um upgrade para o premium com uma das opções abaixo.`,
      exceededSections: (limit) => `Você excedeu o limite de ${limit} ${limit === 1 ? 'seção' : 'seções'} por teste. Exclua seções ou faça um upgrade para o premium com uma das opções abaixo.`,
      premiumFeature: 'Este é um recurso premium. Faça um upgrade para o premium para desbloqueá-lo.',
      warningCreatePremiumExercise: 'Você pode criar, mas não pode publicar uma avaliação com este tipo de exercícios.',
      warningPublishTestWithPremiumExercise: 'Você não pode publicar uma avaliação que contenha exercícios premium. Exclua esses exercícios ou faça um upgrade para o premium com uma das opções abaixo.',
      warningPublishTestWithExceededSections: (limit) => `Você não pode publicar uma avaliação que contenha mais de ${limit} ${limit === 1 ? 'seção' : 'seções'}. Exclua seções ou faça um upgrade para o premium com uma das opções abaixo.`,
      warningExportTestMaxUsages: (limit) => `Você pode exportar no máximo ${limit} ${limit === 1 ? 'versão' : 'versões'}. Faça um upgrade para o premium para desbloquear mais.`,
      schools: 'Escolas',
    },
    navigation: {
      title: 'Navegação',
      section: 'Seção',
    },
    curricula: {
      pt_PT: 'Português',
      other: 'Outro',
      curriculum: 'Currículo',
    },
  },
});

export default lang;
