import { createUseStyles } from 'react-jss';

export default createUseStyles(() => ({
  footerWrapper: {
    display: 'flex',
    justifyContent: 'end',
    gap: '10px',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  annotateIcon: {
    marginRight: '5px',
  },
  premiumPill: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  colorButton: {
    cursor: 'pointer',
    height: '24px',
    width: '24px',
    borderRadius: '50%',
    minWidth: '0',
    padding: '0',
    '&.active': {
      height: '34px',
      width: '34px',
    },
  },
  colorsWrapper: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
}));
