import { combineReducers } from 'redux';

import attemptReducer from './attemptReducer';
import exploreReducer from './exploreReducer';
import pageReducer from './pageReducer';
import studentAttemptReducer from './studentAttemptReducer';
import testReducer from './testReducer';
import testsPageReducer from './testsPageReducer';
import userReducer from './userReducer';

const appReducer = combineReducers({
  page: pageReducer,
  user: userReducer,
  test: testReducer,
  attempt: attemptReducer,
  explore: exploreReducer,
  studentAttempt: studentAttemptReducer,
  testsPage: testsPageReducer,
});

export default appReducer;
