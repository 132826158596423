import React, { useMemo, useState } from 'react';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import { LINEAR, NON_LINEAR } from 'constants/navigationTypes';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Button from 'components/common/Button';
import Modal from 'components/common/Modal';

import useStyles from './styles';

const AttemptActions = ({ nextItem, previousItem, finishTest, savingAnswer, isLast, finishLoading, navigation, isFirst, finishable, canMovePrevious, canMoveNext, writing, flushPendingAnswers, pendingAnswers }) => {
  const classes = useStyles();
  const asyncAnswersToggle = useFeature(toggles.asyncAnswers);
  const toast = useToast();

  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showConfirmNext, setShowConfirmNext] = useState(false);
  const [showConfirmPrevious, setShowConfirmPrevious] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [flushLoading, setFlushLoading] = useState(false);
  const pendingAnswersWithError = useMemo(() => [...pendingAnswers.values()].some((answer) => answer.errors > 0), [pendingAnswers]);
  const isLoading = savingAnswer || finishLoading || flushLoading;

  const onClickNext = async () => {
    let valid = true;
    if (asyncAnswersToggle) {
      setFlushLoading(true);
      const flushResults = await flushPendingAnswers(true);
      valid = flushResults.every((successful) => successful);
      setFlushLoading(false);
    }

    if (navigation === LINEAR || !valid) {
      setShowConfirmNext(true);
    } else if (navigation === NON_LINEAR) {
      nextItem();
    }
  };

  const onClickPrevious = async () => {
    let valid = true;
    if (asyncAnswersToggle) {
      setFlushLoading(true);
      const flushResults = await flushPendingAnswers(true);
      valid = flushResults.every((successful) => successful);
      setFlushLoading(false);
    }

    if (valid) {
      previousItem();
    } else {
      setShowConfirmPrevious(true);
    }
  };

  const onClickFinish = async () => {
    if (asyncAnswersToggle) {
      setFlushLoading(true);
      await flushPendingAnswers(true);
      setFlushLoading(false);
    }

    setShowCompleteModal(true);
  };

  const onClickConfirm = () => {
    if (showConfirmNext) {
      nextItem();
    } else if (showConfirmPrevious) {
      previousItem();
    }
  };

  const onClickCancel = () => {
    setShowConfirmNext(false);
    setShowConfirmPrevious(false);
  };

  const saveAnswers = async () => {
    setSaveLoading(true);
    const flushResults = await flushPendingAnswers(true);
    const valid = flushResults.every((successful) => successful);

    if (valid) {
      toast.success(lang.attempt.answersSaved);
      nextItem();
    } else {
      toast.error(lang.attempt.errorSaveAnswers);
    }

    setShowConfirmNext(false);
    setShowConfirmPrevious(false);
    setSaveLoading(false);
  };

  const modalActions = [
    {
      name: lang.save,
      color: 'gray',
      loading: saveLoading,
      onClick: saveAnswers,
      hide: !pendingAnswersWithError,
    },
    {
      name: lang.common.finish,
      color: 'red',
      loading: finishLoading,
      onClick: finishTest,
    },
    {
      name: lang.cancel,
      color: 'black',
      onClick: () => setShowCompleteModal(false),
    },
  ];

  return (
    <div className={classes.testNextComplete}>
      <Modal
        open={showCompleteModal}
        close={() => setShowCompleteModal(false)}
        header={lang.test.finishTest}
        actions={modalActions}
        center
        transition
      >
        {pendingAnswersWithError ? lang.attempt.unsavedAnswersFinish : lang.test.finishHintConfirm}
      </Modal>
      <Render when={!showConfirmNext && !showConfirmPrevious && navigation === NON_LINEAR && !isFirst && canMovePrevious}>
        <Button
          onClick={onClickPrevious}
          disabled={isLoading || writing}
          loading={isLoading}
          sibling={!isLast || finishable}
        >
          {lang.attempt.previousItem}
        </Button>
      </Render>
      <Render when={!showConfirmNext && !showConfirmPrevious && navigation !== null && !isLast && canMoveNext}>
        <Button
          onClick={onClickNext}
          disabled={isLoading || writing}
          loading={isLoading}
          sibling={navigation === NON_LINEAR && !isFirst}
        >
          {lang.attempt.nextItem}
        </Button>
      </Render>
      <Render when={isLast && finishable && canMoveNext}>
        <Button
          red
          onClick={onClickFinish}
          disabled={isLoading}
          loading={isLoading}
          sibling={navigation === NON_LINEAR}
        >
          {lang.test.finishTest}
        </Button>
      </Render>
      <div className={classes.confirmContainer}>
        <Render when={pendingAnswersWithError && (showConfirmNext || showConfirmPrevious)}>
          {lang.attempt.unsavedAnswers}
        </Render>
        <Render when={showConfirmNext || showConfirmPrevious}>
          {
            !pendingAnswersWithError ?
              <div className={classes.confirmButtons}>
                <Button
                  black
                  onClick={onClickCancel}
                  disabled={savingAnswer}
                  sibling
                >
                  {lang.cancel}
                </Button>
                <Button
                  onClick={onClickConfirm}
                  disabled={isLoading}
                  loading={isLoading}
                  sibling
                >
                  {lang.confirm}
                </Button>
              </div>
              :
              <div className={classes.confirmButtons}>
                <Button
                  onClick={onClickConfirm}
                  disabled={isLoading}
                  loading={isLoading}
                  sibling
                  red
                >
                  {lang.attempt.continueWithoutSaving}
                </Button>
                <Button
                  onClick={saveAnswers}
                  disabled={saveLoading}
                  loading={saveLoading}
                  sibling
                >
                  {lang.attempt.saveAndContinue}
                </Button>
              </div>
          }
        </Render>
      </div>
    </div >
  );
};

AttemptActions.propTypes = {
  nextItem: PropTypes.func,
  previousItem: PropTypes.func,
  finishTest: PropTypes.func.isRequired,
  savingAnswer: PropTypes.bool,
  isLast: PropTypes.bool,
  finishLoading: PropTypes.bool,
  navigation: PropTypes.string,
  isFirst: PropTypes.bool,
  finishable: PropTypes.bool,
  canMovePrevious: PropTypes.bool,
  canMoveNext: PropTypes.bool,
  writing: PropTypes.bool,
  flushPendingAnswers: PropTypes.func,
  pendingAnswers: PropTypes.instanceOf(Map),
};

export default AttemptActions;
