import React, { useCallback, useEffect, useState } from 'react';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spacer } from '@intuitivo/outline';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { selectStudentAttemptSection, setAttemptSectionItems, setNavigationCurrentItem, updateSectionEnded } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import { ERROR } from 'constants/responseCodes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';
import { serverToLocalMillis } from 'utils/datetime';

import AnswerableExercise from '../AnswerableExercise';
import AttemptActions from '../AttemptActions';
import MinifiedCountdownRenderer from 'components/common/countdown/MinifiedCountdownRenderer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import Loading from 'components/common/Loading';

import useStyles from './styles';

const AnswerableSection = ({ sectionId, savingAnswer, setSavingAnswer, setWriting, writing, setPendingAnswer, flushPendingAnswers, pendingAnswers, clearPendingAnswers }) => {
  const classes = useStyles();
  const { name, description, answerId, exercises, section, exerciseCounter, answerSectionId, navigation, presentation, endsAt, currentItem } = useSelector(selectStudentAttemptSection(sectionId));
  const loggedIn = useSelector(selectUserLoggedIn);
  const clockOffset = useSelector(state => state.page.clockOffset);
  const [getAttemptSectionItemsRequest] = useApi(api.getAttemptSectionItems, !loggedIn);
  const [attemptNextSectionItemRequest] = useApi(api.attemptNextSectionItem, !loggedIn);
  const [attemptPreviousSectionItemRequest] = useApi(api.attemptPreviousSectionItem, !loggedIn);
  const { attemptId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const iaveToggle = useFeature(toggles.iave);

  const finalSectionTime = endsAt || section?.endsAt ? serverToLocalMillis(new Date(endsAt ?? section?.endsAt).getTime(), clockOffset) : null;
  const now = (new Date()).getTime();
  const [sectionEnded, setSectionEnded] = useState(!!finalSectionTime && finalSectionTime < now);

  const fetchAttemptSectionItems = useCallback(() => {
    getAttemptSectionItemsRequest([attemptId, answerSectionId], null, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0 && !sectionEnded) {
          dispatch(setAttemptSectionItems(sectionId, data.items));
          setLoading(false);
          return;
        }

        if (data.status === 0 && sectionEnded) {
          setLoading(false);
          return;
        }

        if (data.status !== ERROR) {
          toast.error(lang.oops);
          setLoading(false);
        }
      }, 200);
    });
  }, [answerSectionId, attemptId, dispatch, toast, getAttemptSectionItemsRequest, sectionId, sectionEnded]);

  useEffect(() => {
    setLoading(true);
    if (sectionId) {
      fetchAttemptSectionItems();
    } else {
      dispatch(setAttemptSectionItems([]));
    }
  }, [fetchAttemptSectionItems, dispatch, sectionId, currentItem]);

  const nextItem = async () => {
    const answerIds = exercises ? exercises.map((exercise) => exercise.answerId) : [{ exerciseCounter, answerId }];
    clearPendingAnswers(answerIds);

    setLoading(true);
    attemptNextSectionItemRequest([attemptId, answerSectionId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setNavigationCurrentItem(currentItem + 1, answerSectionId));
        fetchAttemptSectionItems();
        return;
      }

      setLoading(false);
      if (data.status === 2 || data.status === 3) {
        toast.warning(lang.test.finishTestEnded);
        history.push('/');
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
        setLoading(false);
      }
    });
  };

  const previousItem = async () => {
    const answerIds = exercises ? exercises.map((exercise) => exercise.answerId) : [{ exerciseCounter, answerId }];
    clearPendingAnswers(answerIds);

    setLoading(true);
    attemptPreviousSectionItemRequest([attemptId, answerSectionId], null, ({ data }) => {
      if (data.status === 0) {
        dispatch(setNavigationCurrentItem(currentItem - 1, answerSectionId));
        fetchAttemptSectionItems();
        return;
      }

      setLoading(false);
      if (data.status === 2 || data.status === 3) {
        toast.warning(lang.test.finishTestEnded);
        history.push('/');
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
      }
    });
  };

  const getExercises = () => {
    if (sectionEnded) {
      return (
        <div className={classes.lockedSection}>
          <FontAwesomeIcon
            icon={faLock}
            className={classes.icon}
          />
          <div>
            {lang.attempt.timesUp}
          </div>
        </div>
      );
    }

    if ((name && !exercises) || (section && !answerId)) {
      return null;
    }

    let exerciseNum = 0;
    const exercisesToShow = exercises ?? [{ exerciseCounter, answerId }];
    return exercisesToShow.map((exercise) => {
      if (exercise.type !== INFORMATION && exercise.type !== PAUSE) {
        exerciseNum++;
      }

      return (
        <div
          key={exercise.answerId}
        >
          <AnswerableExercise
            num={exercise.exerciseCounter || exerciseNum}
            answerId={exercise.answerId}
            sectionId={sectionId}
            setSavingAnswer={setSavingAnswer}
            setWriting={setWriting}
            setPendingAnswer={setPendingAnswer}
          />
          {presentation === 'incremental' &&
          <>
            <Spacer px={16} />
            <AttemptActions
              nextItem={nextItem}
              previousItem={previousItem}
              savingAnswer={savingAnswer}
              navigation={navigation}
              isLast={exercise.isLast}
              isFirst={exercise.order === 0}
              canMovePrevious
              canMoveNext
              writing={writing}
              flushPendingAnswers={(force) => flushPendingAnswers(force, exercisesToShow.map((exercise) => exercise.answerId))}
              pendingAnswers={pendingAnswers}
            />
          </>
          }
        </div>
      );
    });
  };

  const _setSectionEnded = () => {
    setSectionEnded(true);
    dispatch(updateSectionEnded(sectionId));
  };

  return (
    <EntityBody
      header={name}
      className={cx(classes.section, { answerable: true })}
      id={sectionId}
    >
      {!iaveToggle &&
        <EntityHeader>
          <EntityTitle
            name={name ?? section.name}
          />
          {finalSectionTime &&
            <Countdown
              date={finalSectionTime}
              renderer={MinifiedCountdownRenderer}
              onComplete={_setSectionEnded}
            />
          }
        </EntityHeader>
      }
      {iaveToggle && finalSectionTime &&
        <EntityHeader>
          <Countdown
            date={finalSectionTime}
            renderer={MinifiedCountdownRenderer}
            onComplete={_setSectionEnded}
          />
        </EntityHeader>
      }
      {!quillIsEmpty(description ?? section?.description) &&
        <EntitySubHeader>
          <EntityExpandableText
            text={description ?? section?.description}
            full={exerciseCounter === 1}
          />
        </EntitySubHeader>
      }
      <EntityContent className={classes.exercisesWrapper}>
        <Loading active={loading} />
        {!loading &&
          getExercises()
        }
      </EntityContent>
    </EntityBody>
  );
};

AnswerableSection.propTypes = {
  sectionId: PropTypes.string,
  savingAnswer: PropTypes.bool,
  setSavingAnswer: PropTypes.func,
  setWriting: PropTypes.func,
  writing: PropTypes.bool,
  setPendingAnswer: PropTypes.func,
  flushPendingAnswers: PropTypes.func,
  pendingAnswers: PropTypes.instanceOf(Map),
  clearPendingAnswers: PropTypes.func,
};

export default AnswerableSection;
