import React, { useState, useEffect, useCallback } from 'react';
import { faMap } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { pageError } from 'actions/pageActions';
import { selectAttempt, setStudentAttempt } from 'actions/studentAttemptActions';
import { selectUserLoggedIn } from 'actions/userActions';
import api from 'api';
import { ATTEMPT } from 'constants/localStorageItems';
import { ERROR } from 'constants/responseCodes';
import useAnswerQueue from 'hooks/attempts/useAnswerQueue';
import useApi from 'hooks/common/useApi';
import usePageLogic from 'hooks/common/usePageLogic';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { getErrorCode } from 'utils';

import AskForPinModal from 'components/attempt/AskForPinModal';
import AttemptBaseItems from 'components/attempt/AttemptBaseItems';
import AttemptNavigation from 'components/attempt/AttemptNavigation';
import AttemptReview from 'components/attempt/AttemptReview';
import FinishedAttempt from 'components/attempt/FinishedAttempt';
import Header from 'components/attempt/Header';

import useStyles from './styles';

const Attempt = () => {
  const classes = useStyles();
  const toast = useToast();
  const { pageLoading, reload, loaded } = usePageLogic(lang.tests.title, true, false);
  const { attemptId } = useParams();
  const loggedIn = useSelector(selectUserLoggedIn);
  const [getAttemptRequest] = useApi(api.getAttempt, !loggedIn);
  const [completeAttemptRequest] = useApi(api.completeAttempt, !loggedIn);
  const history = useHistory();
  const iaveToggle = useFeature(toggles.iave);
  const dispatch = useDispatch();
  const attempt = useSelector(selectAttempt);

  const [finishLoading, setFinishLoading] = useState(false);
  const [isFinished, setIsFinished] = useState(null);
  const [openPinModal, setOpenPinModal] = useState(false);
  const [openNavigation, setOpenNavigation] = useState(false);
  const { setPendingAnswer, flushPendingAnswers, pendingAnswers, clearPendingAnswers } = useAnswerQueue(attemptId);

  const fetchAttempt = useCallback(() => {
    reload();
    getAttemptRequest([attemptId], null, ({ data }) => {
      if (data.status === 0) {
        if (data.attempt.userId && !loggedIn) {
          history.push('/');
          return;
        }

        dispatch(setStudentAttempt(data.attempt));

        if (data.attempt.hasEnded) {
          setIsFinished(iaveToggle ? lang.attempt.finishedAttemptIave : lang.attempt.finishedAttempt);
        }

        loaded();

      } else if (data.status === 1) {
        toast.error(lang.test.thisTestIsInvalid);
        setIsFinished(lang.test.thisTestIsInvalid);
        loaded();

      } else if (data.status === -2) {
        dispatch(pageError(getErrorCode(data.status)));
        loaded();

      } else if (data.status !== ERROR) {
        toast.error(lang.oops);
        loaded();

      }
    });
  }, [getAttemptRequest, toast, reload, loaded, attemptId, loggedIn, history, iaveToggle, dispatch]);

  const activateExamMode = async () => {
    await window.electron.activateExamMode();
  };

  const exitExamMode = async () => {
    await window.electron.exitExamMode();
  };

  useEffect(() => {
    if (pageLoading) {
      fetchAttempt();
    }
  }, [pageLoading, fetchAttempt]);

  if (window.electron) {
    window.electron.onOpenModal((event) => {
      if (attempt && !attempt.hasEnded) {
        setOpenPinModal(true);
      } else {
        event.sender.send('closeApp');
      }
    });
  }

  const getPINModal = useCallback(() => {
    return (
      <AskForPinModal
        open={openPinModal}
        close={() => setOpenPinModal(false)}
        attemptId={attempt.id}
      />
    );
  }, [openPinModal, attempt]);

  useEffect(() => {
    if (window.electron && attempt && !attempt.hasEnded) {
      activateExamMode();
    }
  }, [attempt]);

  const finishTest = async (isHeadless) => {
    if (window.electron) {
      exitExamMode();
    }
    setFinishLoading(true);

    completeAttemptRequest([attemptId], null, ({ data }) => {
      setFinishLoading(false);

      if (isHeadless === true) {
        toast.success(lang.test.finishTestSuccess);
        setIsFinished(iaveToggle ? lang.attempt.finishedAttemptIave : lang.attempt.finishedAttempt);
        localStorage.removeItem(ATTEMPT);
        fetchAttempt();
        return;
      }

      if (data.status === 0) {
        toast.success(lang.test.finishTestSuccess);
        setIsFinished(iaveToggle ? lang.attempt.finishedAttemptIave : lang.attempt.finishedAttempt);
        localStorage.removeItem(ATTEMPT);
        fetchAttempt();
        return;
      }

      if (data.status === 2 || data.status === 3) {
        setIsFinished(iaveToggle ? lang.attempt.finishedAttemptIave : lang.attempt.finishedAttempt);
        fetchAttempt();
        return;
      }

      if (data.status !== ERROR) {
        toast.error(lang.oops);
      }
    });
  };

  const timesUp = () => {
    document.activeElement.blur();
    finishTest(true);
  };

  const getTest = () => {
    if (!attempt) {
      return null;
    }

    if ((attempt.hasEnded && attempt.immediateFeedback) || attempt.gradesPublished) {
      return (
        <AttemptReview
          attemptId={attempt.id}
          attempt={attempt}
          classificationType={attempt.classificationType}
        />
      );
    }

    return (
      <AttemptBaseItems
        attemptId={attemptId}
        finishTest={finishTest}
        finishLoading={finishLoading}
        navigation={attempt.navigation}
        attemptType={attempt.testType}
        setPendingAnswer={setPendingAnswer}
        flushPendingAnswers={flushPendingAnswers}
        pendingAnswers={pendingAnswers}
        clearPendingAnswers={clearPendingAnswers}
      />
    );
  };

  if (!attempt) {
    return null;
  }

  if (attempt.hasEnded && !attempt.gradesPublished && !attempt.immediateFeedback) {
    return (
      <FinishedAttempt sentence={isFinished} />
    );
  }

  window.addEventListener('contextmenu', event => {
    event.preventDefault();
  });

  const inExam = (!attempt.hasEnded || !attempt.immediateFeedback) && !attempt.gradesPublished;

  return (
    <div>
      <Header
        attempt={attempt}
        timesUp={timesUp}
        setOpenNavigation={setOpenNavigation}
        fullWidth
      />
      {inExam && (
        <>
          <div className={cx(classes.navigation, { open: openNavigation })}>
            <AttemptNavigation
              flushPendingAnswers={flushPendingAnswers}
              clearPendingAnswers={clearPendingAnswers}
              open={openNavigation}
              setOpen={setOpenNavigation}
            />
          </div>
          <div className={classes.navigationButtonContainer}>
            <div
              className={cx(classes.navigationButton, { open: openNavigation })}
              onClick={() => setOpenNavigation(open => !open)}
            >
              <FontAwesomeIcon
                icon={openNavigation ? faChevronLeft : faMap}
                className={classes.navigationIcon}
              />
            </div>
          </div>
        </>
      )}
      <div className={cx(classes.assessmentContent, { inReview: !inExam })}>
        <div
          className={cx(classes.overlay, { open: openNavigation })}
          onClick={() => setOpenNavigation(false)}
        />
        {getTest()}
      </div>
      {getPINModal()}
    </div>
  );
};

export default Attempt;
