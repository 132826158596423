import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Spacer } from '@intuitivo/outline';
import { Button, Icon, useToast } from '@intuitivo-pt/outline-ui';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 } from 'uuid';

import { selectAttemptExercise, updateCriteriaGrade, updateFeedback, updateGrade } from 'actions/attemptActions';
import { selectUserIsAdmin } from 'actions/userActions';
import api from 'api';
import { highlightColors, overlapHighlightColors } from 'constants/annotationColors';
import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import { PREMIUM } from 'constants/plans';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import CommentBox from '../CommentBox';
import { useAttemptContext } from '../CorrectionTab/context';
import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import PlansPill from 'components/common/plans/PlansPill';
import AnnotationBlot from 'components/common/rich-text/RichText/AnnotationBlot';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ExerciseJustification from 'components/exercises/exercise/exercise-answer/ExerciseJustification';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';
import ExerciseGrader from 'components/exercises/exercise/exercise-header/ExerciseGrader';
import ExerciseSavingSpinner from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner';
import { IDLE, SAVING, SUCCESS } from 'components/exercises/exercise/exercise-header/ExerciseSavingSpinner/states';
import ExerciseCriteriaGrader from 'components/exercises/exercise/exercise-sub-header/ExerciseCriteriaGrader';
import ExerciseFeedback from 'components/exercises/exercise/ExerciseFeedback';
import ExerciseModelAnswer from 'components/exercises/exercise/ExerciseModelAnswer';
import ExerciseStatement from 'components/exercises/exercise/ExerciseStatement';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';

import useStyles from './styles';

const GradeableExercise = ({ num, classificationType, disabled, sectionId, exerciseId, dataTour, setCanBeFetch }) => {
  const toast = useToast();
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    id,
    statement,
    type,
    valueText,
    quillAnswer,
    choices,
    valueChoices,
    valueTrueFalse,
    valueImages,
    gaps,
    valueGaps,
    valueOrderItems,
    valueMultipleChoices,
    orderingCorrect,
    correctTrueFalse,
    hasJustification,
    image,
    criteriaGrades,
    grade,
    maxGrade,
    feedback,
    isShortAnswer,
    option,
    isCompact,
    extraText,
    extraTextStartExpanded,
    connectors,
    connections,
    valueConnections,
    modelAnswer,
    gradingOption,
    exerciseCells,
  } = useSelector(selectAttemptExercise(exerciseId, sectionId));
  const [editAnswerGradeRequest] = useApi(api.editAnswerGrade);
  const [editAnswerCriteriaGradeRequest] = useApi(api.editAnswerCriteriaGrade);
  const [editAnswerFeedbackRequest] = useApi(api.editAnswerFeedback);
  const [editAnswerRequest] = useApi(api.editAnswer);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const textAnnotationToggle = useFeature(toggles.textAnnotation);
  const isAdmin = useSelector(selectUserIsAdmin);
  const { commenting, setCommenting } = useAttemptContext();
  const user = useSelector(state => state.user.data);

  const timeoutRef = useRef();
  const [savingState, setSavingState] = useState(IDLE);
  const [dropAnswers, setDropAnswers] = useState(valueGaps || []);
  const [newFeedback, setNewFeedback] = useState(feedback);
  const [newQuillAnswer, setNewQuillAnswer] = useState(quillAnswer);
  const [annotate, setAnnotate] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [newComment, setNewComment] = useState(null);
  const [annotations, setAnnotations] = useState([]);
  const [annotatedExerciseId, setAnnotatedExerciseId] = useState(null);

  const hasMultipleCorrectChoices = choices?.filter(choice => choice.isCorrect).length > 1;

  const [highlightColor, setHighlightColor] = useState(highlightColors[highlightColors.length - 1]);
  const [overlapHighlightColor, setOverlapHighlightColor] = useState(overlapHighlightColors[overlapHighlightColors.length - 1]);

  useEffect(() => {
    setDropAnswers(valueGaps);
  }, [valueGaps]);

  const saveGrade = (grade) => {
    setSavingState(SAVING);

    editAnswerGradeRequest([exerciseId], { grade }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateGrade(exerciseId, grade, sectionId));
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const saveCriteriaGrade = (answerCriteriaGradeId, grade) => {
    setSavingState(SAVING);

    editAnswerCriteriaGradeRequest([answerCriteriaGradeId], { grade }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setTimeout(() => {
            setSavingState(IDLE);
          }, 300);
          dispatch(updateCriteriaGrade(exerciseId, answerCriteriaGradeId, grade, sectionId));
          return;
        } else {
          toast.error(lang.oops);
        }

        setSavingState(IDLE);
      }, 300);
    });
  };

  const saveComment = (commentText) => {
    const newAnnotation = {
      id: selectedAnnotation,
      comment: commentText,
    };
    setAnnotations([...annotations, newAnnotation]);
    setCommenting(false);
  };

  const saveFeedback = (feedback) => {
    setSavingState(SAVING);
    dispatch(updateFeedback(exerciseId, feedback, sectionId));

    editAnswerFeedbackRequest([exerciseId], { feedback }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
        } else {
          toast.error(lang.oops);
        }

      }, 300);
    });
  };

  const onChange = (feedback) => {
    setCanBeFetch(false);
    clearTimeout(timeoutRef.current);
    setNewFeedback(feedback);

    timeoutRef.current = setTimeout(() => {
      saveFeedback(feedback);
      setCanBeFetch(true);
    }, 2000);
  };

  const onChangeAnnotation = useCallback(() => {
    setSavingState(SAVING);

    editAnswerRequest([id], { quillAnswer: newQuillAnswer, annotations }, ({ data }) => {
      setTimeout(() => {
        if (data.status === 0) {
          setSavingState(SUCCESS);
          setAnnotate(!annotate);
          AnnotationBlot.setAllInactive();
        } else {
          toast.error(lang.oops);
        }

      }, 300);
    });

  }, [editAnswerRequest, id, newQuillAnswer, annotations, annotate, toast]);

  const onBlur = (feedback) => {
    clearTimeout(timeoutRef.current);

    saveFeedback(feedback);
    setCanBeFetch(true);
  };

  const processNode = (node) => {
    let item;
    const drop = dropAnswers.find(el => el.position === node.attribs['data-position']);
    if (!drop) {
      return null;
    }
    const gapAnswer = dropAnswers.find(answer => answer.position === drop.position);

    if (gapAnswer) {
      if (type === 'filling') {
        item = gaps.find(gap => gap.id === gapAnswer.gapId);
      }
    } else {
      item = null;
    }

    return (
      <ProcessNodeOptions
        id={exerciseId}
        drop={drop}
        item={option === 'write' ? gapAnswer : item}
        gaps={gaps}
        option={option}
        correction={!isAdmin}
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  const getAnswer = () => {
    if (valueChoices) {
      return valueChoices;
    } else if ((valueText || newQuillAnswer) && type !== 'choices' && type !== 'true-false') {
      return isShortAnswer ? valueText : newQuillAnswer;
    } else if (valueTrueFalse !== null) {
      return valueTrueFalse;
    } else if (valueImages) {
      return valueImages.map(type => (type.value));
    } else if (valueGaps) {
      return valueGaps;
    } else if (valueMultipleChoices) {
      return valueMultipleChoices;
    } else if (valueConnections) {
      return valueConnections;
    }
  };

  const handleCommentButtonClick = () => {
    if (selectedAnnotation) {
      setCommenting(true);
      setAnnotatedExerciseId(exerciseId);
    }
  };

  const handleCommentChange = (value) => {
    setNewComment(value);
  };

  const handleSaveComment = () => {
    saveComment(newComment);
    setCommenting(false);
    setNewComment(null);
    setAnnotatedExerciseId(null);
  };

  const handleAnnotateClick = useCallback(() => {
    if (annotate) {
      onChangeAnnotation();
    } else {
      setAnnotate(!annotate);
      setAnnotatedExerciseId(exerciseId);
    }
  }, [annotate, exerciseId, onChangeAnnotation]);

  const onSelectionChange = useCallback((range, editor) => {
    if (!range) {
      return;
    }
    AnnotationBlot.setAllInactive();

    if (range && range.length !== 0 && annotate) {
      const textAnnotationId = v4();
      const contents = editor.getContents(range.index, range.length);
      let opsProcessed = 0;

      contents.ops.forEach(op => {
        const length = (typeof op.insert === 'string') ? op.insert.length : 1;

        if (op.attributes && op.attributes.annotation) {
          const oldId = op.attributes.annotation.id;

          const combinedId = `${oldId}|${textAnnotationId}`;
          editor.formatText(range.index + opsProcessed, length, 'annotation', false);

          editor.formatText(range.index + opsProcessed, length, 'annotation', {
            id: combinedId,
            backgroundColor: overlapHighlightColor,
          });

          AnnotationBlot.setIsActive(combinedId, true, overlapHighlightColor);
        } else {
          editor.formatText(range.index + opsProcessed, length, 'annotation', {
            id: textAnnotationId,
            backgroundColor: highlightColor,
          });

          AnnotationBlot.setIsActive(textAnnotationId, true, overlapHighlightColor);
        }

        opsProcessed += length;
      });

      setSelectedAnnotation(textAnnotationId);
    }
  }, [annotate, highlightColor, overlapHighlightColor]);

  const handleColorClick = useCallback((color, overlapColor) => {
    setHighlightColor(color);
    setOverlapHighlightColor(overlapColor);
  }, []);

  const commentBox = (
    <div className={classes.commentBoxWrapper}>
      {commenting && annotatedExerciseId === exerciseId && (
        <CommentBox
          annotate={annotate}
          comment={newComment}
          isSectionExercise={!!sectionId}
          handleCommentChange={handleCommentChange}
          handleSaveComment={handleSaveComment}
        />
      )}
    </div>
  );

  const footer = (
    <div className={classes.footerWrapper}>
      {annotate && selectedAnnotation && !commenting && !annotations.find(annotation => annotation.id === selectedAnnotation && annotation.comment) && (
        <div className={classes.buttonContainer}>
          {user.plan?.name !== PREMIUM && user.spaceIdPreference === 'p' && (
            <div className={classes.premiumPill}>
              <PlansPill tip={lang.plans.premiumFeature} />
            </div>
          )}
          <Button
            onClick={() => handleCommentButtonClick(exerciseId)}
            styleType="outlined"
            disabled={user.plan?.name !== PREMIUM && user.spaceIdPreference === 'p'}
          >
            <Icon icon="comment" className={classes.annotateIcon} />
            {lang.test.grades.comment}
          </Button>
        </div>
      )}
      <div className={classes.colorsWrapper}>
        {
          annotate && highlightColors.map((color, index) => (
            <Button
              key={index}
              className={cx(classes.colorButton, { active: highlightColor === color })}
              onClick={() => handleColorClick(color, overlapHighlightColors[index])}
              style={{ backgroundColor: color, border: '1px solid #00000026' }}
            />
          ))
        }
      </div>
      <Button
        className={classes.annotateButton}
        onClick={() => handleAnnotateClick(exerciseId)}
        loading={savingState === SAVING}
        disabled={commenting}
      >
        {!annotate && (
          <Icon icon='highlighter' className={classes.annotateIcon} />
        )}
        {!annotate ? lang.test.grades.annotate : lang.test.grades.saveAnnotation }
      </Button>
    </div>
  );

  return (
    <EntityBody dataTour={`${dataTour}`}>
      <EntityHeader>
        <EntityTitle num={num} type={type} />
        {!isAdmin && (
          <EntityActionsContainer>
            <ExerciseSavingSpinner state={savingState} />
            {![INFORMATION, PAUSE].includes(type) && classificationType !== 'none' && (
              <>
                {!criteriaGrades ? (
                  <ExerciseGrader
                    dataTour={`${dataTour}-grader`}
                    grade={grade}
                    maxGrade={maxGrade}
                    saveGrade={saveGrade}
                    disabled={disabled}
                  />
                ) : (
                  classificationType === 'rubric' && (
                    <ExerciseGrade grade={grade} maxGrade={maxGrade} />
                  )
                )}
              </>
            )}
          </EntityActionsContainer>
        )}
      </EntityHeader>
      {classificationType === 'rubric' && criteriaGrades && !isAdmin && (
        <EntitySubHeader>
          <ExerciseCriteriaGrader
            criteriaGrades={criteriaGrades}
            saveCriteriaGrade={saveCriteriaGrade}
            updateGrade={(newGrade, answerCriteriaGradeId) =>
              dispatch(updateCriteriaGrade(exerciseId, answerCriteriaGradeId, newGrade, sectionId))
            }
            maxGrade={maxGrade && parseFloat(maxGrade)}
            disabled={disabled}
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        <ExerciseStatement statement={statement} instructions={instructions} />
        {![INFORMATION, PAUSE].includes(type) && (
          <>
            <ExerciseAnswer
              exerciseId={exerciseId}
              type={type}
              choices={choices}
              answer={getAnswer()}
              correctTrueFalse={correctTrueFalse}
              image={image}
              gaps={gaps}
              dropAnswers={dropAnswers}
              orderItems={valueOrderItems}
              orderingCorrect={orderingCorrect}
              hasMultipleCorrectChoices={hasMultipleCorrectChoices}
              isCompact={isCompact}
              isShortAnswer={isShortAnswer}
              option={option}
              connectors={connectors}
              connections={connections}
              exerciseCells={exerciseCells}
              gradingOption={gradingOption}
              footer={textAnnotationToggle && footer}
              commentBox={commentBox}
              onAnswer={setNewQuillAnswer}
              onSelectionChange={onSelectionChange}
              correction
              hasWordCount
            />
            {hasJustification && (
              <ExerciseJustification
                exerciseId={exerciseId}
                justification={hasJustification && (valueText || newQuillAnswer)}
                footer={textAnnotationToggle && footer}
                commentBox={commentBox}
                onAnswer={setNewQuillAnswer}
                onSelectionChange={onSelectionChange}
                hasWordCount
              />
            )}
            {!quillIsEmpty(extraText) && (
              <>
                <Spacer px={10} />
                <EntityExpandableText text={extraText} full={extraTextStartExpanded} />
              </>
            )}
            {!isAdmin && (
              <>
                {modelAnswerToggle && !quillIsEmpty(modelAnswer) && (
                  <>
                    <Spacer px={10} />
                    <ExerciseModelAnswer modelAnswer={modelAnswer} />
                  </>
                )}
                <ExerciseFeedback
                  dataTour={`${dataTour}-feedback`}
                  feedback={newFeedback}
                  onChange={onChange}
                  onBlur={onBlur}
                  defining
                />
              </>
            )}
          </>
        )}
      </EntityContent>
    </EntityBody>
  );
};

GradeableExercise.propTypes = {
  num: PropTypes.number.isRequired,
  exercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    statement: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    gaps: PropTypes.arrayOf(PropTypes.object),
    valueOrderItems: PropTypes.arrayOf(PropTypes.object),
    exerciseCells: PropTypes.arrayOf(PropTypes.object),
    orderingCorrect: PropTypes.bool,
    correctTrueFalse: PropTypes.bool,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
    grade: PropTypes.string,
    maxGrade: PropTypes.string,
    feedback: PropTypes.object,
    hasJustification: PropTypes.bool,
    justification: PropTypes.string,
    image: PropTypes.string,
    criteriaGrades: PropTypes.array,
    gradingOption: PropTypes.string,
  }),
  classificationType: PropTypes.string,
  disabled: PropTypes.bool,
  sectionId: PropTypes.string,
  exerciseId: PropTypes.string,
  dataTour: PropTypes.string,
  setCanBeFetch: PropTypes.func,
};

export default GradeableExercise;
