import React from 'react';
import { Spacer } from '@intuitivo/outline';
import PropTypes from 'prop-types';

import { INFORMATION, PAUSE } from 'constants/exerciseTypes';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';
import { quillIsEmpty } from 'utils';

import EntityActionsContainer from 'components/common/entity/EntityActionsContainer';
import EntityBody from 'components/common/entity/EntityBody';
import EntityContent from 'components/common/entity/EntityContent';
import EntityExpandableText from 'components/common/entity/EntityExpandableText';
import EntityHeader from 'components/common/entity/EntityHeader';
import EntitySubHeader from 'components/common/entity/EntitySubHeader';
import EntityTitle from 'components/common/entity/EntityTitle';
import ExerciseAnswer from 'components/exercises/exercise/exercise-answer/ExerciseAnswer';
import ExerciseJustification from 'components/exercises/exercise/exercise-answer/ExerciseJustification';
import ExerciseGrade from 'components/exercises/exercise/exercise-header/ExerciseGrade';
import ExerciseCriteriaGrader from 'components/exercises/exercise/exercise-sub-header/ExerciseCriteriaGrader';
import ExerciseFeedback from 'components/exercises/exercise/ExerciseFeedback';
import ExerciseModelAnswer from 'components/exercises/exercise/ExerciseModelAnswer';
import ExerciseStatement from 'components/exercises/exercise/ExerciseStatement';
import ProcessNodeOptions from 'components/exercises/exercise-form/ProcessNodeOptions';

const ReviewableExercise = ({ num, exercise, classificationType }) => {
  const modelAnswerToggle = useFeature(toggles.modelAnswer);
  const hasMultipleCorrectChoices = exercise.choices?.filter(choice => choice.isCorrect).length > 1;

  const processNode = (node) => {
    let item;
    const drop = exercise.answer.find(el => el.position === node.attribs['data-position']);
    if (!drop) {
      return null;
    }
    const gapAnswer = exercise.answer.find(answer => answer.position === drop.position);

    if (gapAnswer) {
      if (exercise.type === 'filling') {
        item = exercise.gaps.find(gap => gap.id === gapAnswer.gapId);
      }
    } else {
      item = null;
    }

    return (
      <ProcessNodeOptions
        id={exercise.id}
        drop={drop}
        item={item}
        gaps={exercise.gaps}
        option={exercise.option}
        correction
      />
    );
  };

  const instructions = [
    {
      shouldProcessNode: (node) => {
        return node.attribs && node.attribs['data-drop'];
      },
      processNode: processNode,
    },
  ];

  return (
    <EntityBody>
      <EntityHeader>
        <EntityTitle
          num={num}
          type={exercise.type}
        />
        <EntityActionsContainer>
          {![INFORMATION, PAUSE].includes(exercise.type) && classificationType !== 'none' && (
            <ExerciseGrade
              grade={exercise.grade}
              maxGrade={exercise.maxGrade}
            />
          )}
        </EntityActionsContainer>
      </EntityHeader>
      {classificationType === 'rubric' && (
        <EntitySubHeader>
          <ExerciseCriteriaGrader
            criteriaGrades={exercise.criteriaGrades}
            readOnly
          />
        </EntitySubHeader>
      )}
      <EntityContent>
        <ExerciseStatement
          statement={exercise.statement}
          instructions={instructions}
        />
        <ExerciseAnswer
          exerciseId={exercise.id}
          type={exercise.type}
          choices={exercise.choices}
          answer={exercise.answer}
          correctTrueFalse={exercise.correctTrueFalse}
          image={exercise.image}
          gaps={exercise.gaps}
          dropAnswers={exercise.answer}
          orderItems={exercise.valueOrderItems}
          orderingCorrect={exercise.orderingCorrect}
          option={exercise.option}
          correction
          hasMultipleCorrectChoices={hasMultipleCorrectChoices}
          isCompact={exercise.isCompact}
          isShortAnswer={exercise.isShortAnswer}
          connectors={exercise.connectors}
          connections={exercise.connections}
          gradingOption={exercise.gradingOption}
          exerciseCells={exercise.exerciseCells}
          hasWordCount={exercise.hasWordCount}
        />
        {exercise.hasJustification && (
          <ExerciseJustification
            justification={exercise.justification}
            hasWordCount={exercise.hasWordCount}
          />
        )}
        {!quillIsEmpty(exercise.extraText) && (
          <>
            <Spacer px={10} />
            <EntityExpandableText
              text={exercise.extraText}
              full={exercise.extraTextStartExpanded}
            />
          </>
        )}
        {modelAnswerToggle && !quillIsEmpty(exercise.modelAnswer) && (
          <>
            <Spacer px={10} />
            <ExerciseModelAnswer
              modelAnswer={exercise.modelAnswer}
            />
          </>
        )}
        <ExerciseFeedback
          feedback={exercise.feedback}
        />
      </EntityContent>
    </EntityBody>
  );
};

ReviewableExercise.propTypes = {
  num: PropTypes.number.isRequired,
  exercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    statement: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    choices: PropTypes.arrayOf(PropTypes.object),
    image: PropTypes.string,
    gaps: PropTypes.array,
    answer: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool,
      PropTypes.array,
    ]),
    correctTrueFalse: PropTypes.bool,
    grade: PropTypes.string,
    maxGrade: PropTypes.string,
    feedback: PropTypes.object,
    hasJustification: PropTypes.bool,
    justification: PropTypes.string,
    criteriaGrades: PropTypes.array,
    valueOrderItems: PropTypes.arrayOf(PropTypes.object),
    orderingCorrect: PropTypes.bool,
    isCompact: PropTypes.bool,
    isShortAnswer: PropTypes.bool,
    option: PropTypes.string,
    extraText: PropTypes.object,
    extraTextStartExpanded: PropTypes.string,
    connectors: PropTypes.array,
    connections: PropTypes.array,
    modelAnswer: PropTypes.bool,
    gradingOption: PropTypes.string,
    exerciseCells: PropTypes.array,
    hasWordCount: PropTypes.bool,
  }),
  classificationType: PropTypes.string,
};

export default ReviewableExercise;
