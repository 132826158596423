export const SET_COMMENTING = 'SET_COMMENTING';

export const setCommenting = (commenting) => ({
  type: SET_COMMENTING,
  commenting,
});

export const attemptReducer = (attempt, action) => {
  switch (action.type) {
    case SET_COMMENTING:
      return {
        ...attempt,
        commenting: action.commenting,
      };
    default:
      return attempt;
  }
};

export const INITIAL_STATE = {
  commenting: false,
};
