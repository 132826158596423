const updateItemJustification = (studentAttempt, answerId, justification, sectionId) => {
  const newStudentAttempt = { ...studentAttempt };

  let item;
  if (sectionId) {
    const section = newStudentAttempt.attemptItems
      .find((item) => item.id === sectionId && item.itemType === 'section');

    if (!section) {
      return;
    }

    item = section.exercises
      .find((exercise) => exercise.answerId === answerId);
  } else {
    item = newStudentAttempt.attemptItems
      .find((item) => item.answerId === answerId && item.itemType === 'exercise');
  }

  item.justification = justification;

  return newStudentAttempt;
};

export default updateItemJustification;
