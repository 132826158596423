import Quill from 'quill';

const Inline = Quill.import('blots/inline');
class AnnotationBlot extends Inline {
  static create(value) {
    const node = super.create(value);

    node.style.backgroundColor = value.backgroundColor;

    node.style.cursor = 'pointer';
    node.setAttribute('data-comment', value.comment || '');
    node.setAttribute('data-id', value.id || 'comment-blot');
    node.setAttribute('data-isActive', 'false');

    return node;
  }

  static formats(node) {
    return {
      id: node.getAttribute('data-id'),
      comment: node.getAttribute('data-comment'),
      backgroundColor: node.style.backgroundColor,
    };
  }

  static setIsActive(textAnnotationId, isActive, color) {
    const activeAnnotation = document.querySelector(`[data-id="${textAnnotationId}"]`);

    if (!activeAnnotation) {
      return;
    }

    activeAnnotation.setAttribute('data-isActive', isActive ? 'true' : 'false');
    if (isActive) {
      activeAnnotation.style.textDecoration = `underline ${color} 3px`;
    } else {
      activeAnnotation.style.textDecoration = 'none';
    }
  }

  static setAllInactive() {
    const annotations = document.querySelectorAll('.ql-annotation');

    annotations.forEach(annotation => {
      const annotationId = annotation.getAttribute('data-id');
      this.setIsActive(annotationId, false);
    });
  }
}

AnnotationBlot.blotName = 'annotation';
AnnotationBlot.tagName = 'span';
AnnotationBlot.className = 'ql-annotation';

export default AnnotationBlot;
