import React from 'react';
import { Button } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import lang from 'lang';

import RichText from 'components/common/rich-text/RichText';

import useStyles from './styles';

const CommentBox = ({ comment, handleCommentChange, handleSaveComment, isSectionExercise }) => {
  const classes = useStyles();

  const footer = (
    <Button
      sibling
      onClick={handleSaveComment}
    >
      {lang.test.grades.saveComment}
    </Button>
  );

  return (
    <div className={`${isSectionExercise ? classes.commentBoxSection : classes.commentBox}`}>
      <RichText
        className={classes.commentArea}
        value={comment}
        placeholder={lang.test.grades.commentBoxPlaceholder}
        onChange={(_content, _delta, _source, editor) => handleCommentChange(editor.getContents())}
        footer={footer}
        enableMathSymbols
        noHeader
      />
    </div>
  );
};

CommentBox.propTypes = {
  comment: PropTypes.string.isRequired,
  handleCommentChange: PropTypes.func.isRequired,
  handleSaveComment: PropTypes.func.isRequired,
  isSectionExercise: PropTypes.bool,
};

export default CommentBox;
