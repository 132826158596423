import React, { Fragment, useCallback } from 'react';
import { Spacer, Toggle } from '@intuitivo/outline';
import { Render, useToast } from '@intuitivo-pt/outline-ui';
import PropTypes from 'prop-types';

import api from 'api';
import { MAX_SIZE_10MB } from 'constants/fileMaxSizes';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import Input from 'components/common/Input';

import useStyles from './styles';

const ExportAttemptsForm = ({ getValue, setValue, getErrors, classificationType }) => {
  const classes = useStyles();
  const toast = useToast();
  const [uploadFileRequest] = useApi(api.uploadFile);
  const modelAnswerToggle = useFeature(toggles.modelAnswer);

  const onDrop = useCallback((files) => {
    const file = files[0];

    if (file.size > MAX_SIZE_10MB) {
      toast.warning(lang.fileTooLarge);
      return;
    }

    uploadFileRequest([], { file }, ({ data }) => {
      if (data.status === 0) {
        setValue('schoolLogo', { ...getValue('schoolLogo'), value: `${process.env.REACT_APP_S3_URL}/${data.key}` });
        return;
      }
      toast.error(lang.oops);
    });
  }, [uploadFileRequest, toast, setValue, getValue]);

  return (
    <Fragment>
      <div className={classes.inputWrapperLogo}>
        {lang.test.attemptsExport.includeSchoolLogo}
        <Toggle
          checked={getValue('schoolLogo').enabled}
          onChange={value => setValue('schoolLogo', { ...getValue('schoolLogo'), enabled: value })}
        />
      </div>
      <Render when={getValue('schoolLogo').enabled}>
        <Spacer px={5} />
        <Input
          className={classes.dropzone}
          type="file"
          accept={['image/*']}
          placeholder={lang.test.attemptsExport.schoolLogoPlaceholder}
          onDrop={onDrop}
          value={getValue('schoolLogo').value ? [getValue('schoolLogo').value] : []}
          errors={getErrors('schoolLogo')}
          invalid={getErrors('schoolLogo').length !== 0}
        />
      </Render>
      <Render when={classificationType !== 'none'}>
        <Spacer px={20} />
        <div className={classes.inputWrapperGrade}>
          {lang.test.attemptsExport.includeGrade}
          <Toggle
            checked={getValue('includeGrade')}
            onChange={(value) => setValue('includeGrade', value)}
          />
        </div>
      </Render>
      <Render when={classificationType === 'rubric'}>
        <Spacer px={20} />
        <div className={classes.inputWrapperGrade}>
          {lang.test.attemptsExport.includeRubric}
          <Toggle
            checked={getValue('includeRubric')}
            onChange={(value) => setValue('includeRubric', value)}
          />
        </div>
      </Render>
      <Render when={modelAnswerToggle}>
        <Spacer px={20} />
        <div className={classes.inputWrapperGrade}>
          {lang.test.attemptsExport.includeModelAnswer}
          <Toggle
            checked={getValue('includeModelAnswer')}
            onChange={(value) => setValue('includeModelAnswer', value)}
          />
        </div>
      </Render>
      <Spacer px={10} />
    </Fragment>
  );
};

ExportAttemptsForm.propTypes = {
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  getErrors: PropTypes.func,
  classificationType: PropTypes.string,
};

export default ExportAttemptsForm;
