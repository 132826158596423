import React from 'react';
import Quill from 'quill';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';

import store from 'store';
import theme from 'theme';

import EditableFormula from '../EditableFormula';

const Embed = Quill.import('blots/embed');

class FormulaBlot extends Embed {
  static create(value) {
    let node;

    if (typeof value === 'object') {
      node = super.create(value.value);
      node.setAttribute('value', value.value);
      node.setAttribute('data-mathSymbols', value.mathSymbols);
    } else {
      node = super.create(value);
      node.setAttribute('value', value);
    }

    createRoot(node)
      .render(
        <Provider store={store}>
          <ThemeProvider theme={{ ...theme.general, ...theme['light'] }}>
            <EditableFormula
              node={node}
              disabled={value.mathSymbols === undefined}
            />
          </ThemeProvider>
        </Provider>,
      );

    return node;
  }

  static value(node) {
    return node.getAttribute('value');
  }
}

FormulaBlot.blotName = 'formula';
FormulaBlot.tagName = 'span';
FormulaBlot.className = 'ql-formula';

export default FormulaBlot;
