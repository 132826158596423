import { createUseStyles } from 'react-jss';

export default createUseStyles((theme) => ({
  menu: {
    overflowY: 'auto',
    zIndex: '50',
    minWidth: '13em',
    backgroundColor: `${theme.inputBackground2} !important`,
    border: `1px solid ${theme.inputBorder2} !important`,
    color: `${theme.textColor} !important`,
    borderRadius: '10px',
    top: 'calc(100% + 9px)',
  },
  placeholder: {
    backgroundColor: theme.inputBackground,
    border: `2px solid ${theme.successColor}`,
    padding: '0.45em',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '3em',
    minHeight: '25px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  optionWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    '& p': {
      margin: '0px',
    },
  },
  check: {
    color: theme.successColor,
  },
  icon: {
    marginLeft: '10px',
    fontSize: '10px',
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: 'unset !important',
    border: `1px solid ${theme.inputBorder2} !important`,
    '&:hover': {
      backgroundColor: theme.shade1,
      cursor: 'pointer',
    },
  },
  iconPlus: {
    marginLeft: '0.5em',
    marginRight: '0.5em',
    width: '1em !important',
  },
  value: {
    padding: '0 !important',
  },
  noOptions: {
    color: theme.textColor2,
    fontSize: '14px',
    margin: '0 !important',
  },
  inputDropdown: {
    display: 'inline-block',
    minWidth: '10em',
    verticalAlign: 'middle',
    '&.isAdmin div[class$="-control"]': {
      border: `1px solid ${theme.inputBorder2}`,
    },
    '&.isCorrect div[class$="-control"]': {
      border: `2px solid ${theme.successColor}`,
    },
    '&.answerable div[class$="-control"]': {
      border: `1px solid ${theme.inputBorder2}`,
    },
    '& div[class$="-control"]': {
      border: `2px solid ${theme.errorColor}`,
    },
    '& div[class$="-control"] > :first-child': {
      height: '3em',
    },
    '&.correction div[class$="-placeholder"]': {
      position: 'relative',
    },
  },
  inputWrite: {
    verticalAlign: 'middle',
    display: 'inline-block',
    border: `1px solid ${theme.inputBorder2}`,
    minWidth: '10em',
    borderRadius: '18px',
    overflow: 'hidden',
    '&.isCorrect': {
      border: `2px solid ${theme.successColor}`,
    },
    '&:not(.isCorrect)': {
      border: `2px solid ${theme.errorColor}`,
    },
    '&.isAdmin': {
      border: `2px solid ${theme.inputBorder2}`,
    },
    '&.answerable': {
      border: `1px solid ${theme.inputBorder2}`,
    },
    '& > div > div > :first-child ': {
      width: '90%',
    },
  },
  identifier: {
    color: 'grey',
    marginLeft: '5px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
  },
  [`@media (max-width: ${theme.xs}px)`]: {
    menu: {
      width: 'calc(100% - 2px)',
    },
  },
}));
