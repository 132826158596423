export const highlightColors = [
  '#FF8E8E',
  '#FDF9A7',
  '#b6f2ca',
  '#A8E0FF',
];

export const overlapHighlightColors = [
  '#ff3333',
  '#fbf137',
  '#52e081',
  '#33b8ff',
];
