import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';

import { INITIAL_STATE, attemptReducer, setCommenting } from './reducer';

export const AttemptContext = createContext(null);

export const useAttemptContext = () => {
  return useContext(AttemptContext);
};

export const AttemptProvider = ({ children }) => {
  const [attempt, dispatch] = useReducer(attemptReducer, INITIAL_STATE);

  const setCommentingHandler = useCallback((commenting) => {
    dispatch(setCommenting(commenting));
  }, []);

  const context = useMemo(() => ({
    ...attempt,
    setCommenting: setCommentingHandler,
  }), [attempt, setCommentingHandler]);

  return (
    <AttemptContext.Provider value={context}>
      {children}
    </AttemptContext.Provider>
  );
};

AttemptProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
