/**
 * Action constants
 */
const SET_STUDENT_ATTEMPT = 'SET_STUDENT_ATTEMPT';
const SET_ATTEMPT_ITEMS = 'SET_ATTEMPT_ITEMS';
const SET_ATTEMPT_NAVIGATION_ITEMS = 'SET_ATTEMPT_NAVIGATION_ITEMS';
const SET_ATTEMPT_SECTION_ITEMS = 'SET_ATTEMPT_SECTION_ITEMS';
const SET_NAVIGATION_CURRENT_ITEM = 'SET_NAVIGATION_CURRENT_ITEM';
const UPDATE_ANSWER = 'UPDATE_ANSWER';
const UPDATE_JUSTIFICATION = 'UPDATE_JUSTIFICATION';
const UPDATE_ATTEMPT_SECTION_ENDED = 'UPDATE_ATTEMPT_SECTION_ENDED';

/**
 * Action creators
 */
const setStudentAttempt = (studentAttempt) => ({
  type: SET_STUDENT_ATTEMPT,
  studentAttempt: studentAttempt,
});

const setAttemptItems = (attemptItems) => ({
  type: SET_ATTEMPT_ITEMS,
  attemptItems: attemptItems,
});

const setAttemptNavigationItems = (navigationItems) => ({
  type: SET_ATTEMPT_NAVIGATION_ITEMS,
  navigationItems: navigationItems,
});

const setAttemptSectionItems = (sectionId, items) => ({
  type: SET_ATTEMPT_SECTION_ITEMS,
  sectionId: sectionId,
  items: items,
});

const setNavigationCurrentItem = (currentItem, sectionId) => ({
  type: SET_NAVIGATION_CURRENT_ITEM,
  currentItem: currentItem,
  sectionId: sectionId,
});

const updateAnswer = (answerId, answer, sectionId) => ({
  type: UPDATE_ANSWER,
  answerId: answerId,
  answer: answer,
  sectionId: sectionId,
});

const updateJustification = (answerId, justification, sectionId) => ({
  type: UPDATE_JUSTIFICATION,
  answerId: answerId,
  justification: justification,
  sectionId: sectionId,
});

const updateSectionEnded = (sectionId) => ({
  type: UPDATE_ATTEMPT_SECTION_ENDED,
  sectionId: sectionId,
});

/**
 * Selectors
 */
const selectAttempt = (state) => {
  return state.studentAttempt;
};

const selectAttemptItems = (state) => {
  return state.studentAttempt.attemptItems;
};

const selectAttemptNavigationItems = (state) => {
  return state.studentAttempt.navigationItems;
};

const selectCurrentItem = (state) => {
  return state.studentAttempt.currentItem;
};

const selectStudentAttemptSection = (id) => {
  return (state) => {
    return state.studentAttempt.attemptItems.find((item) => item.id === id && item.itemType === 'section');
  };
};

const selectAnswer = (answerId, sectionId) => {
  return (state) => {
    if (sectionId) {
      const section = state.studentAttempt.attemptItems
        .find((item) => item.id === sectionId && item.itemType === 'section');

      if (!section) {
        return;
      }

      return section.exercises
        .find((exercise) => exercise.answerId === answerId);
    }

    return state.studentAttempt.attemptItems
      .find((item) => item.answerId === answerId && item.itemType === 'exercise');
  };
};

const selectJustification = (answerId, sectionId) => {
  return (state) => {
    if (sectionId) {
      const section = state.studentAttempt.attemptItems
        .find((item) => item.id === sectionId && item.itemType === 'section');

      if (!section) {
        return;
      }

      return section.exercises
        .find((exercise) => exercise.answerId === answerId)?.justification;
    }

    return state.studentAttempt.attemptItems
      .find((item) => item.answerId === answerId && item.itemType === 'exercise')?.justification;
  };
};

const selectAttemptId = (state) => {
  return state.studentAttempt?.id;
};

export {
  SET_STUDENT_ATTEMPT,
  SET_ATTEMPT_ITEMS,
  SET_ATTEMPT_NAVIGATION_ITEMS,
  SET_ATTEMPT_SECTION_ITEMS,
  SET_NAVIGATION_CURRENT_ITEM,
  UPDATE_ANSWER,
  UPDATE_JUSTIFICATION,
  UPDATE_ATTEMPT_SECTION_ENDED,
  setStudentAttempt,
  setAttemptItems,
  setAttemptNavigationItems,
  setAttemptSectionItems,
  setNavigationCurrentItem,
  updateAnswer,
  updateJustification,
  updateSectionEnded,
  selectAttempt,
  selectAttemptItems,
  selectAttemptNavigationItems,
  selectCurrentItem,
  selectStudentAttemptSection,
  selectAnswer,
  selectJustification,
  selectAttemptId,
};
