import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { Col, Row } from '@intuitivo/outline';
import { BackTop } from 'antd';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectTestPublication } from 'actions/testActions';
import api from 'api';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import lang from 'lang';
import toggles from 'toggles';

import DonutChart from '../DonutChart';
import ExerciseCards from '../ExerciseCards';
import ExercisesTable from '../ExercisesTable';
import GradeDistributionChart from '../GradeDistributionChart';
import MultiLineChart from '../MultiLineChart';
import ScoreCard from '../ScoreCard';
import TestTimesRow from '../TestTimesRow';
import Card from 'components/common/Card';
import UpgradePremium from 'components/common/plans/UpgradePremium';

import Stats from './assets/stats.png';
import useStyles from './styles';

const StatisticsTab = () => {
  const classes = useStyles();
  const { testId } = useParams();
  const [students, setStudents] = useState([]);
  const [total, setTotal] = useState(0);
  const [getTestStudentsRequest] = useApi(api.getTestStudents);
  const publication = useSelector(selectTestPublication);
  const advancedStatsToggle = useFeature(toggles.advancedStatistics);
  const [getPublicationStatisticsRequest] = useApi(api.getPublicationStatistics);
  const [questionsWithOrderedGrades, setQuestionsWithOrderedGrades] = useState([]);
  const [basicStats, setBasicStats] = useState([]);
  const [times, setTimes] = useState([]);
  const [finalScores, setFinalScores] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    if (!publication) {
      return;
    }

    getPublicationStatisticsRequest([publication.id, false], null, ({ data }) => {
      if (data.status === 0) {
        setQuestionsWithOrderedGrades(data.questionsWithOrderedGrades);
        setBasicStats(data.basicStats);
        setTimes(data.testTimes);
        setFinalScores(data.finalScores);
      }
    });
  }, [getPublicationStatisticsRequest, publication]);

  const fetchStudents = useCallback(() => {
    getTestStudentsRequest([testId], null, ({ data }) => {
      if (data.status === 0) {
        setStudents(data.students);
        setTotal(parseFloat(data.total));
      }
    });
  }, [getTestStudentsRequest, testId]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  if (!students.length > 0) {
    return (
      <div className={classes.noStudents}>
        {lang.test.grades.noStudents}
      </div>
    );
  }

  return (
    <>
      <Row>
        <Card
          className={classes.testStatisticsCard}
          header={lang.appKeywords.statistics}
        >
          <div className={classes.testStatistics}>
            <ScoreCard stats={basicStats} total={total} />
            <Col xl={3} className={classes.donutChart}>
              <DonutChart positiveGrades={basicStats.positiveGrades} negativeGrades={basicStats.negativeGrades} />
            </Col>
          </div>
          {advancedStatsToggle &&
          <>
            <TestTimesRow testTimes={times} />
            <MultiLineChart questions={questionsWithOrderedGrades} />
            <GradeDistributionChart scores={finalScores} total={total} question={false} />
            <ExercisesTable questions={questionsWithOrderedGrades} tableRef={tableRef} />
          </>
          }
        </Card>
      </Row>
      {advancedStatsToggle ? (
        <Fragment>
          <ExerciseCards students={students} total={total} questions={questionsWithOrderedGrades} tableRef={tableRef} />
          <BackTop className={classes.topButton} />
        </Fragment>
      ) : (
        <div className={classes.advancedStatsBlock}>
          <div className={classes.advancedStatsBlockText}>
            <UpgradePremium
              message={lang.test.stats.premiumStats}
            />
          </div>
          <img src={Stats} className={classes.advancedStatsBlockImage} alt="Advanced stats example" />
        </div>
      )}
    </>
  );
};

export default StatisticsTab;
