import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { Parser, ProcessNodeDefinitions } from 'html-to-react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { updateZoom } from 'actions/pageActions';
import api from 'api';
import { LOCAL } from 'constants/environments';
import useApi from 'hooks/common/useApi';
import useFeature from 'hooks/useFeature';
import toggles from 'toggles';
import { renderQuill } from 'utils';

import AudioPlayer from '../AudioPlayer';
import VideoPlayer from '../VideoPlayer';

import useStyles from './styles';

const QuillRenderer = ({ value, htmlFor, className, style, instructions, _ref }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const soundControlToggle = useFeature(toggles.soundControl);
  const { attemptId } = useParams();
  const [updateMediaRepetitionsRequest] = useApi(api.increaseMediaRepetitions);
  const [getAttemptMediaRequest] = useApi(api.getAttemptMedia);

  const html = useMemo(() => {
    if (value) {
      return renderQuill(value, { encodeHtml: true });
    }

    return null;
  }, [value]);

  useEffect(() => {
    dispatch(updateZoom());
  }, [dispatch, html]);

  const processNodeDefinitions = new ProcessNodeDefinitions(React);

  const processAudioNode = (node) => {
    let finalSrc = node.attribs.src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return (
      <AudioPlayer
        src={finalSrc}
        canPause={node.attribs['data-canpause'] === 'true'}
        limitRepetitions={node.attribs['data-limitrepetitions'] === 'true'}
        maxRepetitions={node.attribs['data-maxrepetitions']}
        attemptId={attemptId}
        updateMediaRepetitionsRequest={updateMediaRepetitionsRequest}
        getAttemptMediaRequest={getAttemptMediaRequest}
      />
    );
  };

  const processVideoNode = (node) => {
    let finalSrc = node.attribs.src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return (
      <VideoPlayer
        src={finalSrc}
        canPause={node.attribs['data-canpause'] === 'true'}
        limitRepetitions={node.attribs['data-limitrepetitions'] === 'true'}
        maxRepetitions={node.attribs['data-maxrepetitions']}
        attemptId={attemptId}
        updateMediaRepetitionsRequest={updateMediaRepetitionsRequest}
        getAttemptMediaRequest={getAttemptMediaRequest}
      />
    );
  };

  const processImageNode = (node) => {
    let finalSrc = node.attribs.src;
    if (process.env.REACT_APP_NODE_ENV === LOCAL) {
      const hostname = window.location.hostname;
      finalSrc = finalSrc.replace('localhost', hostname);
    }

    return (
      <p className={node.attribs.class}>
        <img
          src={finalSrc}
          className="ql-image"
          alt=""
        />
      </p>
    );
  };

  const processingInstructions = [
    ...(instructions || []),
    {
      shouldProcessNode: (node) => {
        return node.name === 'audio' && soundControlToggle;
      },
      processNode: processAudioNode,
    },
    {
      shouldProcessNode: (node) => {
        return node.name === 'video';
      },
      processNode: processVideoNode,
    },
    {
      shouldProcessNode: (node) => {
        return node.name === 'img';
      },
      processNode: processImageNode,
    },
    {
      shouldProcessNode: () => {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const content = new Parser().parseWithInstructions(
    html,
    () => true,
    processingInstructions,
  );

  if (htmlFor) {
    return (
      <label
        htmlFor={htmlFor}
        className={cx('ql-editor', classes.contentContainer, className)}
        style={style}
        ref={_ref}
      >
        {content}
      </label>
    );
  }

  return (
    <div
      className={cx('ql-editor', classes.contentContainer, className)}
      style={style}
      ref={_ref}
    >
      {content}
    </div>
  );
};

QuillRenderer.propTypes = {
  value: PropTypes.object.isRequired,
  htmlFor: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  instructions: PropTypes.array,
  _ref: PropTypes.object,
};

export default QuillRenderer;
